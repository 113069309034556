import React from 'react';
import './loincresulthead.css'
import Select from 'react-select'
import NavButton from "../next"


class LoincResultHead extends React.Component {



  onPrev(){
    let data={
      value:Number(this.props.resultPageNumber)-1
    }
    this.props.onPageChange(data)
  }

  onNext(){
    let data={
      value:Number(this.props.resultPageNumber)+1
    }
    this.props.onPageChange(data)

  }

  render(){

  const pageTextMax = this.props.resultPageNumber*100> this.props.total ? this.props.total : this.props.resultPageNumber*100
  const pageTextMin = (this.props.resultPageNumber*100)-99
  const pageText = pageTextMin+"-"+pageTextMax

    return(
      <div>
      {this.props.total >0 && this.props.resultPageData!==undefined &&
        <div className="resultHead">

          <span className="resultHeadText"><b>Résultats : </b>{pageText} (sur {this.props.total})</span>
          <div className="blocSelectHead">

            <span className="blocPageHead">Pages : </span>
            <NavButton isDefine={this.props.resultPageNumber>1}  onClick={()=>this.onPrev()} text="<" styles="btn_page_prev"/>

            <Select  className="selectHead"
              value = {this.props.resultPageData.find(option => option.value === this.props.resultPageNumber)}
              onChange={this.props.onPageChange}
              options={this.props.resultPageData}
            />
            <NavButton isDefine={this.props.resultPageNumber*100< this.props.total}  onClick={()=>this.onNext()} text=">" styles="btn_page_next"/>


          </div>
        </div>
      }

      {this.props.total===0 &&
        <div className="resultHead">
          <h1 className="title_client_search result_center">Aucun résultat</h1>
        </div>
      }

      </div>
    )
  }
}


export default LoincResultHead;
