import React from "react";
import './commentaire.css';
import { GrEdit,GrAddCircle, GrTrash,GrCycle } from 'react-icons/gr';


class Commentaire extends React.Component  {
  constructor(props){
    super(props);
    this.edit = this.edit.bind(this)
    this.trash = this.trash.bind(this)
    this.modif = this.modif.bind(this)

  }

  edit(){
    this.props.onClick(this.props.who,this.props.data,this.props.text)
  }

  
  trash(){
   this.props.deleteComm(this.props.who)
  }

  modif(){
    this.props.onClick(this.props.who,this.props.data,this.props.text,true)
  }


  render(){
      return (
        <div className="container_comm_uniq">
        <p className="para_comm">{this.props.text}
          {
            this.props.data!==null && this.props.data!=='' &&
             <span className="characters"> {this.props.data}</span>
          }


        </p>
        <p className="para_icone">
          { this.props.data!==null && this.props.data!=='' &&  this.props.who !== "commEx" && this.props.who !== "reco" && 
            <div className = "icone_multi_container">
              <span className="characters"> {this.props.isEditable===true && <GrEdit  className="icone_comm" onClick={this.edit}/>}</span>
              {this.props.deleteComm !== undefined && 
                <GrTrash  className="icone_comm icon_multi" onClick={this.trash}/>
              } 

            </div>
          }

        {this.props.data!==null && this.props.data!=='' && this.props.isEditable && (this.props.who === "commEx" || this.props.who === "reco") && 
          <div className = "icone_multi_container">
            <GrEdit  className="icone_comm icon_multi" onClick={this.modif}/>
            <GrCycle  className="icone_comm icon_multi" onClick={this.edit}/>
            <GrTrash  className="icone_comm icon_multi" onClick={this.trash}/>
          </div>
          }
          {
            (this.props.data===null || this.props.data==='') &&
             <span className="characters"> {this.props.isEditable===true && (this.props.who !=="commRe")   && <GrAddCircle className="icone_comm" onClick={this.edit}/>}</span>
          }

        </p>
        </div>

    )
  }
};

export default Commentaire;
