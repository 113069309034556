import React from 'react'
import CheckAndTxt from '../../CheckAndTxt/CheckAndTxt.js'



class CheckList extends React.Component{


  constructor(props){
    super(props);
    this.state = {
      checkbox:[]
    }
    this.onCheck = this.onCheck.bind(this)
  }


  onCheck(e,position){
    const tmp = {...this.state.checkbox}
    tmp[position] = e.target.checked
    this.setState({checkbox:tmp},()=>this.checkAll())
  }

  checkAll(){
    const {checkbox} = this.state

    if (this.props.type ==="resultats"){
      if (checkbox[0] && checkbox[1] && checkbox[2] && checkbox[3] && checkbox[4] ) {
        this.props.done(true)
      } else {
        this.props.done(false)
      }
    } else {
      if (checkbox[0] && checkbox[1] && checkbox[2] && checkbox[3]) {
        this.props.done(true)
      } else {
        this.props.done(false)
      }

    }
  }

 getToCheck(type){

   return type === "resultats" ? [
     "Colonne 'Onaos_Libellé milieu SGL' renseignée ( = pré-traitement)",
     "Colonne 'Code chapitre SGL' (avec libellé) renseignée ?",
     "Colonne 'Code demande' renseignée ?",
     "Colonne 'Onaos_Libellé demande' renseignée ?",
     "Colonne 'Onaos_Tube à prélever' renseignée ?",
   ]: [
     "Colonne 'Onaos_Milieu SGL' renseignée ?",
     "Colonne 'Onaos_Libellé unité SGL' renseignée ?",
     "Colonne 'Code chapitre SGL' (avec libellé) renseignée ?",
     "Colonne 'Onaos_Libellé analyse' renseignée ?"
   ]
 }

  render(){
    const toCkeck = this.getToCheck(this.props.type)

    return(
      <div className = "container_checklist">
        { toCkeck.map( (v,k) =>(
          <CheckAndTxt
            label =  {v}
            state = {this.state.checkbox[k]}
            position = {k}
            onCheck = {this.onCheck}
            disabled = {false}
          />
        )
        )}
        {this.props.type==="resultats" &&
          <CheckAndTxt
            label = "Transcodage expert du dictionnaire 'Demande' terminé ?"
            state = {this.props.data.demandeprogression === 100}
            position = {-1}
            disabled = {true}
            onCheck = {this.onCheck}
          />

        }



      </div>
    )
  }


}



export default CheckList
