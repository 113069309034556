import React from 'react'
import Interligne from "../../interligne/interligne.js"
import NavButton from "../../next.js"
import "./dashaccount.css"
import Dictionnaires from "../dictionnaires/dictionnaires.js"
import CheckAndTxt from '../../CheckAndTxt/CheckAndTxt.js'



class DashAccount extends React.Component  {

  constructor(props){
    super(props);
    this.state =  {
      showFile:false,
      sendEmail:false,
      blockMail:false,
    }
    this.onValidTable = this.onValidTable.bind(this)
    this.onCheck = this.onCheck.bind(this)
    this.bockCheck = this.bockCheck.bind(this)
  }

  onValidTable(data){
    if (this.props.confirmFile){
      this.showFile(false)
    }
    this.props.modifChap(data,this.props.data.name,'chapitre')

  }


  showFile(state){
    this.setState({
      showFile:state
    })
  }

  onCheck(){
    this.setState({sendEmail:!this.state.sendEmail})
  }

  bockCheck(){
    this.setState({blockMail:!this.state.blockMail})
  }


  checkData(){
    return this.props.data.contact.email!=="" && !this.props.end && this.props.data.custom_fields!==undefined && 
      this.props.data.custom_fields["Nom de la structure"]!== undefined && this.props.data.address_street !== "" && this.props.data.address_zip_code !== "" && this.props.data.categories[0] !== undefined &&
      this.props.data.address_city !== "" && this.props.data.address_country !== "" ? true : false
  }

  render(){
    const nom = this.props.data.contact["Nom"] === "" ? "Merci de créer un contact pour ce laboratoire dans Axonaut !" : this.props.data.contact["Nom"]
    const mail = this.props.data.contact["Email"] === "" ? "Veuillez renseigner un email de contact dans Axonaut !" :this.props.data.contact["Email"]
    const dateExport =  this.props.data.custom_fields !== undefined &&  this.props.data.custom_fields["Date de réception des dictionnaires"] !== undefined ? this.props.data.custom_fields["Date de réception des dictionnaires"] : ""

    return (
      <React.Fragment>
      {this.state.showFile &&
        <Dictionnaires
          show = {this.state.showFile}
          data = {this.props.fileInfo}
          onQuit = {()=>this.showFile(false)}
          onValid = {this.onValidTable}
          confirmFile = {this.props.confirmFile}
        />
      }

      <div className="dash_container_account">
        <div className="dash_account">
          <Interligne styles = "center"  label = "Client" value = {this.props.data.name} />
          <Interligne styles = "alignement"  label = "" value = "Infos labo" />
          <Interligne styles = "alignement"  label = "Nom de la structure" value = {this.props.data.custom_fields!==undefined && this.props.data.custom_fields["Nom de la structure"]!== undefined ? this.props.data.custom_fields["Nom de la structure"] : "Merci de renseigner dans Axonaut le champ \"Nom de la structure\""} />
          <Interligne styles = "alignement"  label = "Secteur" value = {this.props.data.categories!==undefined && this.props.data.categories!==null && this.props.data.categories[0] !== undefined && this.props.data.categories[0]["name"]!==undefined ? this.props.data.categories[0]["name"] : "" } />
          <Interligne styles = "alignement"  label = "Adresse" value = {this.props.data.address_street} />
          <Interligne styles = "alignement"  label = "Code postal" value = {this.props.data.address_zip_code} />
          <Interligne styles = "alignement"  label = "Ville" value = {this.props.data.address_city} />
          <Interligne styles = "alignement"  label = "Pays" value = {this.props.data.address_country} />
          <hr></hr>
          <Interligne styles = "alignement"  label = "" value = "Contact labo" />
          <Interligne styles = "alignement"  label = "Nom" value = {nom} />
          <Interligne styles = "alignement"  label = "Prénom" value = {this.props.data.contact["Prénom"]} />
          <Interligne styles = "alignement"  label = "Email" value = {mail} />
          <Interligne styles = "alignement"  label = "Téléphone" value = {this.props.data.contact["Téléphone"]} />
          <hr></hr>
          <Interligne styles = "alignement"  label = "Réception des dictionnaires le " value = {dateExport} />
          <hr></hr>

          { this.checkData() &&  
            <div className="dash_account_input">
              <Interligne styles = "alignement"  label = "" value = "Dictionnaire chapitres" />
               <input type="file" onChange={this.props.onFileChange} />
               <NavButton isDefine={true} onClick={()=>this.props.onFileUpload(this.props.data.name)} text="Envoyer"/>
               <NavButton isDefine={this.props.showFile && !this.props.accountDone}  onClick={()=>this.showFile(true)} text="Vérifier le fichier" />
           </div>
          }


          
          <CheckAndTxt 
            label = "Blocker l'envoi de mail aux experts ?"
            state = {this.state.blockMail}
            position = {-1}
            disabled = {false}
            onCheck = {this.bockCheck}
          />

          {  this.props.accountDone && !this.props.end &&
            <div className="dash_validation">
             { /* <CheckAndTxt
                label = "Envoyer l'email d'inscription ?"
                state = {this.state.sendEmail}
                position = {-1}
                disabled = {false}
                onCheck = {this.onCheck}
              /> */}
              <NavButton isDefine={true} onClick={()=>this.props.accountSend(this.state.sendEmail, this.state.blockMail)}  text="Terminer l'inscription" />
            </div>
          }


          {this.props.end &&
            <Interligne styles = "center"  label = "" value = "Compte labo créé" />
          }

        </div>
      </div>
      </React.Fragment>
    )
  }
}


export default DashAccount
