import React from "react";
import Select from 'react-select'
import './clientmap.css';
import NavButton from "../next";
import ClientEntry from "../cliententry/cliententry.js"



class ClientMap extends React.Component {
  constructor(props){
    super(props);
    this.navClient = this.navClient.bind(this)
  }

  navClient = selectedOption => {
    this.props.selectChange(selectedOption)
  }

  onClick(name){
    this.props.onClick(name)
  }

  getBackground(){
    let result= {
      backgroundColor : '#ffffff'
    }
    if (this.props.etat==="audit" && this.props.value["difficulté"]!==""  && this.props.value["difficulté"]!==undefined){
      switch (this.props.value["difficulté"]) {
        case "1":
          result= {
           border: '6px solid #28a745'
         }
         break
       case "2":
         result= {
          border: '6px solid #ffe507'
        }
        break
        case "3":
          result= {
           border: '6px solid #fd7e14'
         }
         break
       case "4":
         result= {
          border: '6px solid #dc3545'
        }
        break
        case "5":
          result= {
           border: '6px solid #333333'
         }
         break

         default:
         break
      }
    }
    return result
  }


  getStyle(value){
    let result=""
    if (this.props.value["transcodable"]===value) {
      result=" auditRed"
    }
    return result
  }

  showNTBtnClient(){
    return (this.props.value["transcodable"]==="Non transcodable") ? false : true
  }

  getStyleDNT(){
    let result=""
    if (this.props.donottranscode) {
      result=" auditRed"
    }
    return result
  }

  setColor(tag){
    if (tag === "passé") return "#FF8A05"
    if (tag === "traité") return "green"
  }

  render(){
    const colorStyles = {
        option: (styles, { data }) => {
            return {
                ...styles,
                fontWeight : data.consensus !== "" || data.technidata !== "" ? 'bold' : '',
                color : this.setColor(data.tag)
            };
        },
    };

    const customStyles = {
      container: provided => ({
        ...provided,
        width: 300,
      })
    };

    return(
      <div class="adjut_items_client">
        <div class="title_container_client" style = {{marginBottom : this.props.state.allchapitres !== undefined  ? "15px" : "30px"}}>
          <NavButton styles="navbutton relatifNav" isDefine={true}  onClick={()=>this.onClick("prev")} text="<" />
          <h1 className="title_client center"> Entrée locale</h1>
          <NavButton styles="navbutton relatifNav" isDefine={true}  onClick={()=>this.onClick("next")} text=">" />
        </div>
        {this.props.state.allchapitres !== undefined && this.props.state.allchapitres.length>0 &&
          <div style = {{
            marginBottom: '15px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}>
           
            <Select className="center" 
                options = {this.props.state.allchapitres}
                value = {this.props.state.allchapitres.find(option => option.value === this.props.state.chapterValue)}
                styles={customStyles}
                onChange={this.props.chapterOnChange}

            />
          </div>
        }
        <div className="container_client" style={this.getBackground()}>

        {this.props.state.showmodal===false  && this.props.state.showSearch===false  &&
          <Select className="center"
            styles={colorStyles}
            value = {this.props.state.allcode.find(option => option.value === this.props.state.id)}
            isDisabled={this.props.state.showmodal}
            onChange={this.navClient}
            options={this.props.state.allcode}
            ref = {this.props.reference}

          />
        }


        <ClientEntry
          data = {this.props.value["Entrée complète client"]}
          testaudit={this.props.value}
          choixclient={this.props.value["Choix client"]!==undefined && this.props.value["Choix client"] }
          etat = {this.props.etat}
          basestatut={this.props.basestatut}
          typebase = {this.props.typebase}
          stateBaseData = {this.props.value["state"]}
          />



        {this.props.etat==="validation expert" &&
          <div className="expertbtn">
            <NavButton isDefine={true}  onClick={()=>this.onClick("untranscodable")} text="Non transcodable" styles={this.getStyle("Non transcodable")} />
            <NavButton isDefine={true}  onClick={()=>this.onClick("outloinc")} text="Absent du LOINC" styles={this.getStyle("Absent du LOINC")} />
          </div>
        }

        {this.props.etat==="validation client" &&
          <div className="expertbtn">
            <NavButton isDefine={this.showNTBtnClient()}  onClick={()=>this.onClick("donottranscode")} text="Ne pas transcoder" styles={this.getStyleDNT()} />
          </div>
        }

      </div>
      </div>
    )


  }
}

export default ClientMap;
