import React from 'react'
import './dashpretraitement.css'
import NavButton from "../../next.js"

import ModifCol from "./ModifCol.js"
import JoinCol from "./JoinCol.js"
import TraitementAuto from "./TraitementAuto.js"
import CheckDico from "./CheckDico.js"
import TranscodageAuto from "./TranscodageAuto.js"
import DataVisu from "./DataVisu.js"
import { AddCodes } from './AddCodes'
import { getAPI } from '../../../api/api'


class DashPreTraitement extends React.Component  {

  constructor(props){
    super(props);
    this.state =  {
      showItems : "",
      showLeftMenu:true,
    }
  }

  btnClick(msg){
  
    this.props.resetDashPret()
    if (msg === "Compte-rendu") this.props.finalInformation()
    if (msg === "join") this.props.findJointureType()
    this.setState({showItems:msg})
  }

  componentDidUpdate(prevProps,prevState){
    window.scrollTo(0, 0);
  }

  collapse(){
    this.setState({showLeftMenu:!this.state.showLeftMenu})
  }

  render(){
    const fullSizeClass = this.state.showLeftMenu ? "main_client_pretrait" : "main_client_pretrait main_client_pretrait_full"
    const menuPosition = this.state.showLeftMenu ? "dash_client_axtions_pretrait_container" : "dash_client_axtions_pretrait_container hide_dash_menu"
    const textMenu = this.state.showLeftMenu ? "dashboard_icone_close" : "dashboard_icone_open"
    return (
      <React.Fragment>

        <div className = "dash_container_client_pretrait">
          <div className='menu_dash_pretrait'>
              <div className={textMenu} onClick = {()=>this.collapse()}>

              </div>
            {/*<p className='alignement' onClick = {()=>this.collapse()} >{textMenu}</p>*/}
          </div>
          <div className={menuPosition}>
              <div className="dash_client_actions_pretrait dash_btn_container">
                  <NavButton isDefine={true} onClick = {() => this.btnClick("modif")}  text="Modifier les en-têtes" styles = {this.state.showItems === "modif" ? "btn_color_search" : ""} />
                  <NavButton isDefine={true} onClick = {() => this.btnClick("semiauto")} text="Traitements semi-auto" styles = {this.state.showItems === "semiauto" ? "btn_color_search" : ""}/>
                  <NavButton isDefine={true} onClick = {() => this.btnClick("join")} text="Jointures" styles = {this.state.showItems === "join" ? "btn_color_search" : ""}/>
                  <NavButton isDefine={true} onClick = {() => this.btnClick("showexport")} text="Vérifier les fichiers" styles = {this.state.showItems === "showexport" ? "btn_color_search" : ""}/>
                  <NavButton isDefine={true} onClick = {() => this.btnClick("transcodage")} text="Transcodage" styles = {this.state.showItems === "transcodage" ? "btn_color_search" : ""}/>
                  <NavButton isDefine={true} onClick = {() => this.btnClick("debug")} text="Debug" styles = {this.state.showItems === "debug" ? "btn_color_search" : ""}/>
                  <NavButton isDefine={true} onClick = {() => this.btnClick("addcode")} text="Veille/Ajout codes" styles = {this.state.showItems === "addcode" ? "btn_color_search" : ""}/>

                  {/* <NavButton isDefine={true} onClick = {() => this.btnClick("Compte-rendu")} text="Compte-rendu" styles = {this.state.showItems === "Compte-rendu" ? "btn_color_search" : ""}/> */}

                  <div className="dash_navigation_pretrait">
                    <NavButton isDefine={true}  onClick={this.props.onBack} text="Retour" styles="back"/>
                    <NavButton isDefine={true}  onClick={this.props.onDisconnect} text="Quitter" />
                  </div>
              </div>

          </div>

          <div className={fullSizeClass}>
            {this.state.showItems === "modif" &&
              <ModifCol
                data = {this.props.data}
                readFile = {this.props.readFile}
                showFile = {this.props.showFile}
                alldata = {this.props.alldata}
                modifChap = {this.props.modifChap}
                fileRender = {this.props.alldata.fileRender}
                dataDoublon = {this.props.dataDoublon}
                resetDashPret = {this.props.resetDashPret}

              />
            }
            {this.state.showItems === "join" &&
              <JoinCol
                data = {this.props.data}
                readFile = {this.props.readFile}
                showFile = {this.props.showFile}
                fileInfoJointure = {this.props.fileInfoJointure}
                doJoin = {this.props.doJoin}
                fileRender = {this.props.alldata.fileRender}
                resetDashPret = {this.props.resetDashPret}
                jointureTypeData = {this.props.jointureTypeData}


              />
            }

            {this.state.showItems === "semiauto" &&
              <TraitementAuto
                data = {this.props.data}
                preTraitement = {this.props.preTraitement}
                doPreTraitement = {this.props.doPreTraitement}
                onSelectExpert = {this.props.onSelectExpert}
                deleteMedium = {this.props.deleteMedium}
                addMedium = {this.props.addMedium}
                onchecked = {this.props.onchecked}
                isChecked = {this.props.isChecked}
                resetDashPret = {this.props.resetDashPret}
                allChecked = {this.props.allChecked}

              />
            }
            {this.state.showItems === "showexport" &&
            <CheckDico
              data = {this.props.data}
              readFinal = {this.props.readFinal}
              showFile = {this.props.showFile}
              render = {this.state.fileRender}
              alldata = {this.props.alldata}
              fileRender = {this.props.alldata.fileRender}
              doConfirmFile = {this.props.doConfirmFile}
              resetDashPret = {this.props.resetDashPret}


            />
            }

            {(this.state.showItems === "transcodage" || this.state.showItems === "debug" ) &&
              <TranscodageAuto
                data = {this.props.data}
                checkfilefortranscoding = {this.props.checkfilefortranscoding}
                filetotranscode = {this.props.filetotranscode}
                alldata = {this.props.alldata}
                doTranscode = {this.props.doTranscode}
                mode = {this.state.showItems}
                resetDashMsg = {this.props.resetDashMsg}
                resetDashPret = {this.props.resetDashPret}
              />
            }

            {(this.state.showItems === "addcode" ) &&
              <AddCodes
                data = {this.props.data}
                tokenRefresh =  {this.props.tokenrefresh}
                client = {this.props.client}
              />
            }

            {this.state.showItems === "Compte-rendu" && this.props.finalinfo !== undefined &&
              <DataVisu 
                data = {this.props.finalinfo}
                fileRender = {this.props.alldata.fileRender}

              />
          }

          </div>
        </div>

      </React.Fragment>
      
    )
  }
}


export default DashPreTraitement
