import React from 'react'
import "./inputaccount.css"
import InputMultiple from "./inputmultiple.js"

class Account extends React.Component  {

  constructor(props){
      super(props);
      this.state = {
        show:false
      }

    }



  showFacturation(){
    if (!this.state.show) this.setState({show:true})
  }

  facturation(e){
    this.showFacturation()
    this.props.onChange(e,"Adresse","facturation")
  }

  getError(){
    return this.props.error ? "account_input_full input_error" : "account_input_full"
  }


  render(){
    const tag = this.props.tag!==undefined ? this.props.tag : null
    const numero = this.props.numero!==undefined ? this.props.numero : null
    return (
      <React.Fragment>
        {this.props.data[0]!=="job" && this.props.data[0]!=="Nom du laboratoire" &&
          <div className="account_input_container">
            {this.props.data[0]!=="complement" && this.props.data[0]!=="facturation" && this.props.data[0]!=="facturationcomplement" && this.props.data[0]!=="logiciel" &&
              <input
                className={this.getError()}
                type="text"
                defaultValue={this.props.data[1]}
                placeholder={this.props.data[0]}
                value={this.props.data[1]}
                onChange={(e)=>this.props.onChange(e,this.props.data[0],tag,numero)}
                disabled = {this.props.disabled !== undefined ? this.props.disabled : true}
              />
            }
            {(this.props.data[0]==="complement" || this.props.data[0]==="facturationcomplement" || this.props.data[0]==="logiciel") &&
              <InputMultiple
                data = {this.props.data}
                onChange = {this.props.onChange}
                inputData = {this.props.data}
                error = {this.props.error}
                allSIL = {this.props.allSIL}
                onCreateOption = {this.props.onCreateOption}
              />
            }
            {this.props.data[0]==="facturation" &&
                <input
                  className={this.getError()}
                  type="text"
                  defaultValue={this.props.data[1]["Adresse"]}
                  placeholder="Adresse de facturation (si différente)"
                  value={this.props.data[1]["Adresse"]}
                  onChange={(e)=>this.facturation(e)}

                />
           }

        </div>
      }
      </React.Fragment>
    )
  }
}


export default Account;
