import React from "react";
import './progress.css'
import ReactTooltip from 'react-tooltip';



class BootProgress extends React.Component  {




  getProgress(data){
    let result=[];
    result.nbrcarte=data["nbrcarte"];
    result.nbruntreated=data["nbruntreated"];
    result.nbrtreated=data["nbrtreated"];
    result.nbrpasse=data["nbrpasse"];


    result.CardnonT = Math.round(Number(100*result.nbruntreated/result.nbrcarte)*100)/100
    result.Cardpass = Math.round(Number(100*result.nbrpasse/result.nbrcarte)*100)/100
    result.CardT = Math.round(Number(100*result.nbrtreated/result.nbrcarte)*100)/100

    result.slabelNonT=result.CardnonT;
    result.slabelPass=result.Cardpass;
    result.slabelT=result.CardT;


  
    if (data["stateBase"]==="audit"){
      result.labelTL="Examens audités";
      result.labelPassL="Examens passés";
      result.labelNonTL="Examens à auditer";
    } else {
      result.labelTL="Examens transcodés";
      result.labelPassL="Examens passés";
      result.labelNonTL="Examens à transcoder";
    }


    result.labelT=result.labelTL+" "+result.nbrtreated+"/"+result.nbrcarte+" ("+result.CardT+"%)"
    result.labelPass=result.labelPassL+" "+result.nbrpasse+"/"+result.nbrcarte+" ("+result.Cardpass+"%)"
    result.labelNonT=result.labelNonTL+" "+result.nbruntreated+"/"+result.nbrcarte+" ("+result.CardnonT+"%)"


    return result;

  }





render(){
  const values = this.getProgress(this.props.data)
    return (
      <div className="progressContainer ">
        <div className="progressMaster">
          <div data-tip data-for="done" role="progressbar" className="progressBar done"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow={values.slabelT}
              style={{ width : values.slabelT +'%'}}
            >
            {values.slabelT>=20 && values.labelT}
            <ReactTooltip id="done" type="success" effect="float" >
              <span><h4>{values.labelT}</h4></span>
            </ReactTooltip>
          </div>

          <div data-tip data-for="notnow" className="progressBar warn" role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow={values.slabelPass}
              style={{ width : values.slabelPass +'%'}}
            >
            {values.slabelPass>=20 && values.labelPass}
            <ReactTooltip id="notnow" type="warning" effect="float" >
              <span><h4>{values.labelPass}</h4></span>
            </ReactTooltip>
          </div>
          <div data-tip data-for="undone" className="progressBar dang" role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow={values.slabelNonT }
              style={{ width : values.slabelNonT +'%'}}
            >
            {values.slabelNonT >=20 && values.labelNonT}
            <ReactTooltip id="undone" type="error" effect="float" >
              <span><h4>{values.labelNonT}</h4></span>
            </ReactTooltip>
          </div>
        </div>



      <div className="legend_progress_bar ">
        <div className="legend_contain ">
          <div class="foo green"></div>
          <p>{values.labelTL}</p>


        </div>
        <div className="legend_contain">
          <div class="foo yellow"></div>
          <p>{values.labelPassL}</p>
        </div>
        <div className="legend_contain">
          <div class="foo red"></div>
          <p>{values.labelNonTL}</p>
        </div>
      </div>
      </div>
    )
}


}

export default BootProgress;
