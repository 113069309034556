import React from 'react'
import './dashboard.css'
import NavButton from "../../next"
import DashClientListing from "../clientlisting/clientlisting.js"
import DashClientActions from "../clientactions/clientactions.js"
import DashPreTraitement from "../dashpretraitement/dashpretraitement.js"
import DashAccount from "../dashaccount/dashaccount.js"
import DashAccountGestion from "../dashaccountgestion/dashaccountgestion.js"
import { Roller } from 'react-awesome-spinners'


import {getAPI,putAPI,postAPI} from '../../../api/api.js';


class Dashboard extends React.Component  {
  constructor(props){
    super(props);

    this.state =  {
      tmpInfo:"",
      dashboardData:{
        dashPage:"0",
        dashClient:undefined,
        dashData:undefined
      },
      apiInfo:[],
      prev:{
        api: [],
        dash: []
      },
      tests:"",
      selectedFile: null,
      showFile:false,
      fileInfo:[],
      filePath:"",
      confirmFile:false,
      accountDone:false,
      end:false,
      clientinfos:{},
      errorSend:{},
      fileType:"",
      preTraitement:{
        confirmFile: false,
        show : false,
        data : [],
        expertmMdifs:[],
        attributs:[],
        render : false,
        expertChoice:[[]],
        empty :0
      },
      fileInfoJointure:{
        origin : [],
        jointure : [],
        path : "",
        showFileOrigine : false,
        showFileJointure : false,
        confirmFile : false,
        programme:""
      },
      fileRender:false,
      filetotranscode:[],
      message:"",
      showAxoRoller : false,
      messageDebug:"",
      dataDoublon:[],
      isChecked:[],
      allChecked:false,
      finalinfo:[],
      jointureType : [],
    }
    this.goTo = this.goTo.bind(this)
    this.modifBase = this.modifBase.bind(this)
    this.reset = this.reset.bind(this)
    this.sendMail = this.sendMail.bind(this)

    this.createNewbase = this.createNewbase.bind(this)
    this.auditClient = this.auditClient.bind(this)
    this.analyses = this.analyses.bind(this)
    this.onFileUpload = this.onFileUpload.bind(this)
    this.onFileChange = this.onFileChange.bind(this)
    this.modifChap = this.modifChap.bind(this)
    this.accountSend = this.accountSend.bind(this)
    this.gestionAction = this.gestionAction.bind(this)


    this.sendFile = this.sendFile.bind(this)
    this.deleteFile = this.deleteFile.bind(this)

    this.readFile = this.readFile.bind(this)
    this.doJoin = this.doJoin.bind(this)

    this.doPreTraitement = this.doPreTraitement.bind(this)
    this.onSelectExpert = this.onSelectExpert.bind(this)
    this.deleteMedium = this.deleteMedium.bind(this)
    this.addMedium = this.addMedium.bind(this)
    this.resetDashPret = this.resetDashPret.bind(this)
    this.readFinal = this.readFinal.bind(this)

    this.doConfirmFile = this.doConfirmFile.bind(this)

    this.checkfilefortranscoding = this.checkfilefortranscoding.bind(this)
    this.doTranscode = this.doTranscode.bind(this)
    this.resetDashMsg = this.resetDashMsg.bind(this)
    this.onBack = this.onBack.bind(this)
    this.formation = this.formation.bind(this)
    this.deleteformation = this.deleteformation.bind(this)
    this.doEverything = this.doEverything.bind(this)
    this.onchecked = this.onchecked.bind(this)

    this.finalInformation = this.finalInformation.bind(this)

    this.stopMailCheck = this.stopMailCheck.bind(this)
    this.findJointureType = this.findJointureType.bind(this)
    this.assignExpert = this.assignExpert.bind(this)

    this.modifTechnidata = this.modifTechnidata.bind(this)
    this.modifTechnidata2 = this.modifTechnidata2.bind(this)

    this.addDSI = this.addDSI.bind(this)

    this.doCheck = this.doCheck.bind(this)


  }


  addDSI(client, user) {
    const data = {
      client : client,
      user : user
    }
    putAPI("/adddsi", this.props.state.alldata.tokenrefresh, data)
  }

  findJointureType(){
    getAPI("/findjointure",this.props.state.alldata.tokenrefresh).then(data => {
      this.setState({jointureType:data})
    })
  }


  assignExpert(expertName) {
    const data = {
      expert : expertName,
      client : this.state.dashboardData.dashClient
    }
    putAPI("/assignexpert",this.props.state.alldata.tokenrefresh ,data)

  }


  gestionAction(tag,action,value=null){
    let data={}
    switch (action){
      case 'send':
      if (value===null) value = this.state.dashboardData.dashClient
      data={
        client:value,
        type:tag
      }
      putAPI("/resendmail",this.props.state.alldata.tokenrefresh ,data)
        break
      case 'delete':

        break
      case 'sendexpert':
      data={
        client:value,
        type:tag
      }
      putAPI("/resendmail",this.props.state.alldata.tokenrefresh ,data)
        break
      default:
        data={
          client:value === null ?  this.state.dashboardData.dashClient : value,
          type:tag
        }
        putAPI("/resendmail",this.props.state.alldata.tokenrefresh ,data)
        break
    }
  }

  finalInformation(){
    this.setState({fileRender:true})

    getAPI("/finalinformation/"+this.state.dashboardData.dashClient, this.props.state.alldata.tokenrefresh).then(data => {
      this.setState({
        finalinfo:data,
        fileRender:false
      })
    })
  }


  accountSend(sendEmail = false, blockMail = false){
    const data={
      info : this.state.apiInfo.info,
      file:this.state.filePath,
      sendemail:sendEmail,
      blockMail:blockMail

    }
    putAPI("/sendinvitation",this.props.state.alldata.tokenrefresh ,data).then(data => this.setState({end:true}) );
  }

  afterModif(data){
    if (this.state.confirmFile){
      this.setState({
        filePath:data.filepath,
        confirmFile:false,
        showFile:false,
        accountDone:true,
        dataDoublon:[]
      })
    } else {
      this.setState({
        fileInfo:data.file,
        filePath:data.filepath,
        dataDoublon : data.doublon !== undefined ? data.doublon : [],
        confirmFile:true,
        showFile:true,
      })
    }
  }




  modifChap(dataHead,laboName,type){

    this.setState({fileRender:true})
    laboName = laboName === null ? this.state.dashboardData.dashClient : laboName
    const data={
      path : this.state.filePath,
      labo : laboName,
      type : type,
      dataHead : dataHead,
      confirmFile: this.state.confirmFile,
      data : this.state.fileInfo,
      doublon : this.state.dataDoublon
    }
    putAPI("/modiffile",this.props.state.alldata.tokenrefresh ,data).then(data => {
      this.setState({fileRender:false})
      this.afterModif(data)
    });
  }

  onFileChange(event){
    this.setState({ selectedFile: event.target.files[0] });
  }

  onFileUpload(client){
    this.setState({showFile:false})
    const formData = new FormData();
    formData.append('type', 'chapitre');
    formData.append('name', client);
    formData.append('file', this.state.selectedFile);

    postAPI("/sendfile",this.props.state.alldata.tokenrefresh ,formData).then(data => {
      if (data.state==="done"){
        this.setState({
          fileInfo:data.file,
          filePath:data.filepath,
          accountDone:false,
          confirmFile:false,
          showFile:true
        })
      } else {
        this.setState({
          accountDone:false,
          showFile:false,
          confirmFile:false,

        })
      }

    } );
  }


  doJoin(idOrigine,idJoin,head,program){
    this.setState({fileRender:true})

    const data = {
      idOrigin : idOrigine,
      idJoin : idJoin,
      labo : this.state.dashboardData.dashClient ,
      dataHead : head,
      confirmFile: this.state.fileInfoJointure.confirmFile,
      type : program,
      data : this.state.fileInfoJointure.origin
    }

    putAPI("/joinfile",this.props.state.alldata.tokenrefresh ,data).then( data =>{
      let tmp = {...this.state.fileInfoJointure}
      if (!this.state.fileInfoJointure.confirmFile){
        tmp.origin = data.file
        tmp.confirmFile = true
        tmp.showFileOrigine = true
      } else {
        tmp.origin = []
        tmp.jointure = []
        tmp.confirmFile = false
        tmp.showFileOrigine = false
        this.resetDashPret()
      }

      this.setState({
        fileInfoJointure : tmp,
        fileRender:false

      })
    })

  }


  doPreTraitement(id,type,action = "read",typeBase){

    const render = {...this.state.preTraitement}
    render.render = true
    this.setState({
      preTraitement : render
    })
    const tmp = {...this.state.preTraitement}
    
    const confirm = action === "validation" ? true : false

    const data = {
      client:this.state.dashboardData.dashClient,
      id : id,
      type : type,
      confirmFile:confirm,
      action:action,
      typeBase:typeBase
    }
    putAPI("/autotreatment",this.props.state.alldata.tokenrefresh ,data).then(data => {
      if (action === "validation"){
        tmp.confirmFile = false
        tmp.show = false
        tmp.render = false
        tmp.data = []
      } else {
        tmp.data = data.file
        tmp.confirmFile = true
        tmp.show = true
        tmp.render = false
        tmp.attributs = data.attributs
        tmp.empty = data.empty
        tmp.type = type
      }
      this.setState({preTraitement : tmp})
    })
  }


  readFinal(id){
    this.setState({fileRender :true})
    const data = {
      id : id,
      client :  this.state.dashboardData.dashClient,
    }
    putAPI("/readfinalfile",this.props.state.alldata.tokenrefresh ,data).then(data => {
      this.setState({
        fileInfo:data.file,
        confirmFile:true,
        showFile:true,
        fileRender:false,
      })
    })
  }

  readFile(id,tag = null){
    this.setState({fileRender :true})

    const data = {
      id : id,
      client :  this.state.dashboardData.dashClient,
      line : 21,
      tag : tag
    }


    putAPI("/readfile",this.props.state.alldata.tokenrefresh ,data).then(data => {
      if (tag === null){
        this.setState({
          fileInfo:data.file,
          filePath:data.filepath,
          fileType:data.type,
          showFile:true,
          confirmFile:false,
          fileRender:false,
        })
      } else {
        const tmp = {...this.state.fileInfoJointure}
        tmp[tag] = data.file
        if (tag === "origin")  {
          tmp["path"] = data.filepath
          tmp["showFileOrigine"] = true

        } else {
          tmp["showFileJointure"] = true
        }
        tmp["confirmFile"] = false
        this.setState({
          fileInfoJointure:tmp,
          fileRender:false,
        })
      }
    })


  }

  dashInfos(data,action,dashInfos=null){
    switch (action) {
      case "clientdata":
        this.setState({
          apiInfo:data,
          dashboardData:dashInfos,
          end:false
        } )
        break
      default:
        break
    }
  }




  goTo(numPage,client=null,infos=null){
    let dashInfos={
      dashPage:numPage,
      dashClient:client,
      dashData:infos
    }
    switch (numPage){
      case "done":
      case "public":
      case "prive":
        dashInfos.dashPage = "0.1"
        getAPI("/clientdata/"+numPage,this.props.state.alldata.tokenrefresh).then(data => this.dashInfos(data,"clientdata",dashInfos))
        break;
    /*  case "public":
        dashInfos.dashPage = "0.2"
        getAPI("/clientdata/public",this.props.state.alldata.tokenrefresh).then(data => this.dashInfos(data,"clientdata",dashInfos))

        break;*/
      case "compte":
        dashInfos.dashPage = "0.3"
        this.setState({showAxoRoller:true})
        getAPI("/axionaute/all",this.props.state.alldata.tokenrefresh).then(data => {
          this.setState({showAxoRoller:false})
          this.dashInfos(data,"clientdata",dashInfos)
        })

        break;
      case "formation":
        dashInfos.dashPage = "0.4"
        this.setState({dashboardData:dashInfos})
                  
        break;

      case "baseclient":
        dashInfos.dashPage = "0.1.1"
        if (client===null){
          client = this.state.dashboardData.dashClient
          dashInfos.dashClient = this.state.dashboardData.dashClient
        }
        getAPI("/clientdata/"+client,this.props.state.alldata.tokenrefresh).then(data => this.dashInfos(data,"clientdata",dashInfos))
        break

      case "accountclient":
        dashInfos.dashPage = "0.3.1"
        getAPI("/axionaute/"+client,this.props.state.alldata.tokenrefresh).then(data => this.dashInfos(data,"clientdata",dashInfos))
        break

      case "accountgestion":
        dashInfos.dashPage = "0.1.1.1"
        client = this.state.dashboardData.dashClient
        dashInfos.dashClient = this.state.dashboardData.dashClient
        getAPI("/accountgestion/"+client,this.props.state.alldata.tokenrefresh).then(data => this.dashInfos(data,"clientdata",dashInfos))

        break
      case "pretraitement":
        dashInfos.dashPage = "0.1.1.2"
        client = this.state.dashboardData.dashClient
        dashInfos.dashClient = this.state.dashboardData.dashClient
        getAPI("/pretraitementdata/"+client,this.props.state.alldata.tokenrefresh).then(data => this.dashInfos(data,"clientdata",dashInfos))
        this.setState({
          showFile:false,
          fileInfo:[],
          filePath:"",
        })
        break
      case "base":
        getAPI("/dataexpert/"+infos,this.props.state.alldata.tokenrefresh).then(data => this.props.baseAccess(data,this.state.apiInfo,this.state.dashboardData, this.state.prev))
        break

      default:

        break
    }

//    this.setState({dashboardData:dashInfos})
  }


  componentDidMount() {
     window.dispatchEvent(new CustomEvent("navigationhandler"));
     if (this.props.oldApiInfo.info !== undefined && this.props.oldDashData.dashClient!== undefined ) {
       this.setState({
        apiInfo:this.props.oldApiInfo,
        dashboardData:this.props.oldDashData,
        end:false,
        prev:this.props.oldPrev
       })
     }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.dashboardData.dashPage !== this.state.dashboardData.dashPage) {
      const page =( prevState.dashboardData.dashPage!==undefined )? prevState.dashboardData.dashPage : "0"
      let stateprev = {...this.state.prev}
      stateprev['dash'][page]=prevState.dashboardData
      stateprev['api'][page]=prevState.apiInfo
      this.setState({
        'prev':stateprev
      })
    }
  }



  onBack(){
  //  if (this.state.dashboardData.dashPage!=0){

        const lvl = this.state.dashboardData.dashPage.split('.')
        let newpage = 0
      //  const newpage = this.state.dashboardData.dashPage-1

        for (let i = 0; i < lvl.length-2; i++) {
          newpage = newpage+"."+lvl[i+1]
        }
        let history = []
        history["dashPage"] = this.state.prev["dash"][newpage].dashPage
        history["dashClient"]= this.state.prev["dash"][newpage].dashClient
        history["dashData"]= this.state.prev["dash"][newpage].dashData
        let historyApi = []
        historyApi = this.state.prev["api"][newpage]

        this.setState({
          dashboardData:history,
          apiInfo:historyApi
        })
        if (newpage==="0.3") this.goTo("compte")
        if (newpage==="0.1.1") this.goTo("baseclient")

  }





  majBaseList(data){
    let st = {...this.state.apiInfo}

    if (data["creation"]==="done" && data["basename"]!==""){
      const pos = st.info.titrebase.indexOf(data["oldname"])
      st.info.titrebase[pos]=data["basename"]
    }
    this.setState({
      showLoading:false,
      apiInfo:st
    })
  }


  createNewbase(baseName,type=""){
    const   datas= {
        client : this.state.dashboardData.dashClient,
        base :  baseName,
        action : type
    }
    if (type === "") this.setState({showLoading:true})
    putAPI("/changeexpertbase",this.props.state.alldata.tokenrefresh,datas).then(data =>   {
      if (type === "") {
        this.majBaseList(data)
      } else {
        this.setState({tmpInfo:data})
      }
    })

  }

  auditClient(baseName){
    const   datas= {
        client : this.state.dashboardData.dashClient,
        base :  baseName
    }
    putAPI("/changeauditbase",this.props.state.alldata.tokenrefresh,datas).then(data =>   {
      this.setState({tmpInfo:data["script"]})
    })

  }

  modifBase(baseName,statut,baseNumber){
    const   datas= {
        client : this.state.dashboardData.dashClient,
        base : baseName,
        statut : statut,

    }

    let st = {...this.state.apiInfo}
    st.info.statut[baseNumber]=statut
    this.setState({apiInfo:st})
    putAPI("/changebasestatut",this.props.state.alldata.tokenrefresh,datas)
  }

  reset(baseName){
    const   datas= {
        base : baseName,
      }
    putAPI("/reset",this.props.state.alldata.tokenrefresh,datas).then(data => this.setState({tmpInfo:data}))
  }


  sendMail(baseName){
    const   datas= {
        base : baseName,
      }
    putAPI("/sendmailclientexpert",this.props.state.alldata.tokenrefresh,datas).then(data => this.setState({tmpInfo:data}))
  }


  doEverything(baseName,baseNumber){
    this.modifBase(baseName,"validation client",baseNumber)
    this.reset(baseName)
    this.sendMail(baseName)

  }

  doCheck(client, baseName){
    const datas = {
      client : client,
      base : baseName,
    }
    putAPI("/checkbase", this.props.state.alldata.tokenrefresh, datas)      
  }


  analyses(){
    const url = "/tests"
    getAPI(url,this.props.state.alldata.tokenrefresh).then(data => this.setState({tests:"done"}))

  }


  modifTechnidata2(dataFile){

    const formData = new FormData();
    formData.append('type', "csv");
    formData.append('client',  this.state.dashboardData.dashClient);
    formData.append('namefile',"technidata");
    formData.append('action',"export");
    formData.append('file', dataFile);
    
    postAPI("/modiftechnidata",this.props.state.alldata.tokenrefresh ,formData, "blob").then(data => {
      const urld = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = urld;
      link.title = "Onaos";
      link.setAttribute('download', "technidata.csv");
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(urld);
    })
  }


  modifTechnidata(dataFile){

    const formData = new FormData();
    formData.append('type', "csv");
    formData.append('client',  this.state.dashboardData.dashClient);
    formData.append('namefile',"technidata");
    formData.append('action',"change");

    formData.append('file', dataFile);
    postAPI("/modiftechnidata",this.props.state.alldata.tokenrefresh ,formData)
  }
  



  sendFile(dataFile){
    //this.setState({showFile:false})
      if (dataFile.type !== undefined && dataFile.nom !== undefined && dataFile.file !== undefined &&
        dataFile.type !== "" && dataFile.nom !== "" && dataFile.file !== ""
       ){
      const formData = new FormData();
      formData.append('type', dataFile.type);
      formData.append('name',  this.state.dashboardData.dashClient);
      formData.append('namefile',  dataFile.nom);
      formData.append('file', dataFile.file);


      postAPI("/sendfile",this.props.state.alldata.tokenrefresh ,formData).then(data => {
        const apiInfo = {...this.state.apiInfo}
        apiInfo.infos.listingbase = data.listingbase
        this.setState({
          apiInfo:apiInfo,
          errorSend:{}
        })
      });
    } else {
      const error = {...this.state.errorSend}
      error.state = true
      error.nom = dataFile.nom === undefined || dataFile.nom === "" ? true : false
      error.type = dataFile.type === undefined || dataFile.type === "" ? true : false
      error.file = dataFile.file === undefined || dataFile.file === "" ? true : false
      this.setState({errorSend:error})

    }
  }

  deleteFile(id){
    const url = "/deletefile"
    const data = {
      client : this.state.dashboardData.dashClient,
      id : id
    }
    putAPI(url,this.props.state.alldata.tokenrefresh,data).then(data => {
      const apiInfo = {...this.state.apiInfo}
      apiInfo.infos.listingbase = data.listingbase
      this.setState({
        apiInfo:apiInfo,
        errorSend:{}
      })
    })

  }

  onSelectExpert(type,e,key,number = 0){


    key = key +1
    const tmp = {...this.state.preTraitement}

    if (tmp.data[key]["Pretraitement"]=== undefined){
      tmp.data[key]["Pretraitement"] = []
      tmp.data[key]["Pretraitement"][number] = e.value
    } else {
      tmp.data[key]["Pretraitement"][number] = e.value
    }

    //modif for all check 

    let allID = []
    Object.entries(this.state.isChecked).forEach( (v,k)=>{
      if (v[1] !== undefined && v[1] !== null && v[1] === true ) {
        const nbr = Number(v[0])+1
        allID.push(tmp.data[nbr]["id"])
        if (nbr !== key)  tmp.data[nbr]["Pretraitement"] = tmp.data[key]["Pretraitement"]
      } 
    })
    if (allID.length === 0)   allID.push(tmp.data[key]["id"])


    this.setState({preTraitement:tmp})
    //add request to backEnd

    const url = "/modifpret"
    const data = {
      type : tmp.type,
      id : allID,//tmp.data[key]["id"],
      number : number,
      value : e.value,
      action:'select',
      client:this.state.dashboardData.dashClient,
      typeBase:type
    }
    putAPI(url,this.props.state.alldata.tokenrefresh,data)

  }


  deleteMedium(type,line,index){
    line = line + 1
    const tmp = {...this.state.preTraitement}

    tmp.data[line]["Pretraitement"].splice(index,1)
    if (tmp.data[line]["Pretraitement"].length === 0) tmp.data[line]["Pretraitement"][0] = "N/A"
 
    let allID = []
    Object.entries(this.state.isChecked).forEach( (v,k)=>{
      if (v[1] !== undefined && v[1] !== null && v[1] === true ) {
        const nbr = Number(v[0])+1
        allID.push(tmp.data[nbr]["id"])
        if (nbr !== line)  tmp.data[nbr]["Pretraitement"] = tmp.data[line]["Pretraitement"]
      } 
    })
    if (allID.length === 0)   allID.push(tmp.data[line]["id"])



    this.setState({preTraitement:tmp})
    const url = "/modifpret"
    const data = {
      type : tmp.type,
      id : allID,
      value : index,
      action:'delete',
      client:this.state.dashboardData.dashClient,
      typeBase:type
    }
    putAPI(url,this.props.state.alldata.tokenrefresh,data)
  }


  addMedium(line){
    line = line + 1

    const tmp = {...this.state.preTraitement}

    const length = tmp.data[line]["Pretraitement"]
    if (tmp.data[line]["Pretraitement"][length-1]!== "N/A" ){
      tmp.data[line]["Pretraitement"].push("N/A")
      this.setState({preTraitement:tmp})
    }

  }


  resetDashMsg(){
    this.setState({
      message:"",
      messageDebug:"",
    })
  }

  resetDashPret(){
    this.setState({
      message:"",
      messageDebug:"",
      preTraitement:{
        confirmFile: false,
        show : false,
        data : [],
        expertmMdifs:[],
        attributs:[],
        render : false,
        expertChoice:[[]],
        empty :0
      },
      fileInfoJointure:{
        origin : [],
        jointure : [],
        path : "",
        showFileOrigine : false,
        showFileJointure : false,
        confirmFile : false,
        programme:""
      },
      showFile:false,
      fileInfo:[],
      dataDoublon:[],
      filePath:"",
      confirmFile:false,
      fileRender:false,
      isChecked:[],
      allChecked:false
    })
  }


  doConfirmFile(id){
    this.setState({fileRender:true})
    getAPI("/confirmfile/"+id,this.props.state.alldata.tokenrefresh).then(data =>
      this.setState({
        confirmFile:false,
        fileInfo:[],
        showFile:false,
        fileRender:false,
        apiInfo:data,

      })
    )

  }


  checkfilefortranscoding(typeFile){
    this.setState({fileRender:true})

    const data = {
      client : this.state.dashboardData.dashClient,
      typefile : typeFile,
      action:"fileinfo"
    }
    putAPI("/transcoding",this.props.state.alldata.tokenrefresh,data).then(data => {
      this.setState({
        filetotranscode : data,
        showFile:true,
        fileRender : false,
      })
    })

  }

  doTranscode(id,merge,mode){
    this.setState({fileRender:true})
    id = id ==="chapitre" ? this.state.dashboardData.dashClient : id
    const data = {
      id : id,
      fusion: merge,
      mode : mode
    }
    putAPI("/dotranscode",this.props.state.alldata.tokenrefresh,data).then(data =>{
      const msg  = data.done === "ok" ? data.msg : "error"
      this.setState({
        showFile:false,
        filetotranscode:[],
        fileRender : false,
        message : msg,
        messageDebug : data.debugmsg
      })

    })
  }

  formation(dataFile){
   
    const formData = new FormData();
   formData.append('file', dataFile);
    formData.append('action', "create");

    if (window.confirm("Créer les comptes formations prédéfinit ?")) postAPI("/formation",this.props.state.alldata.tokenrefresh,formData,"blob").then (data =>
      {

        const urld = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = urld;
        link.title = "Onaos";
        link.setAttribute('download', "listing.csv");
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(urld);
      })
  }

  deleteformation (){
    const formData = new FormData();
    formData.append('action', "delete");
    if (window.confirm("Supprimer les comptes formations ?")) postAPI("/formation",this.props.state.alldata.tokenrefresh,formData)
      
  }

  onchecked(e,number){
    if (number >-1){
      const tmp = {...this.state.isChecked}
      let tmp2 = this.state.allChecked
      tmp[number] = this.state.isChecked[number] === undefined ? true : !this.state.isChecked[number]
      tmp2 = (Object.values(tmp).filter(v => v === true).length>0 ) ? true :false
      this.setState({isChecked:tmp,allChecked:tmp2})
    } else {
      let tmp = {...this.state.isChecked}
      if (this.state.allChecked) {
        tmp=[]
      }
      this.setState({isChecked:tmp,allChecked:!this.state.allChecked})
    }
  }

  stopMailCheck(action){
    const data = {
      client :  this.state.dashboardData.dashClient,
      action:action,
    }
    putAPI("/stopemail", this.props.state.alldata.tokenrefresh, data)
  }

  render(){
    return (

      <div className="dash_background">
      {this.state.showAxoRoller &&
        <div className="axonaute_roller">
          <Roller color="#f9af2e" />
        </div>
      }

      {this.props.access === "expert" && (this.state.dashboardData.dashPage===undefined || this.state.dashboardData.dashPage==="0") &&

          <DashClientListing  goto={this.goTo} alldata={this.state} expertother={ this.props.expertrestreint }/>

      }

      {this.props.access === "expert" && this.state.dashboardData.dashPage!==undefined && this.state.dashboardData.dashPage.indexOf("0.")>-1 &&  this.state.dashboardData.dashPage.split(".").length===2  &&

          <DashClientListing 
            data={this.state.apiInfo.info} 
            goto={this.goTo} 
            alldata={this.state}
            formation = {this.formation}
            deleteformation = {this.deleteformation}
          />

      }



      {this.props.access === "expert" && this.state.dashboardData.dashPage!==undefined && this.state.dashboardData.dashPage==="0.1.1"    &&
        <DashClientActions
          createNewbase={this.createNewbase}
          alldata={this.state}
          goto={this.goTo}
          modifBase={this.modifBase}
          reset={this.reset}
          auditClient={this.auditClient}
          sendMail = {this.sendMail}
          doEverything = {this.doEverything}
          downloadJournal = {this.props.downloadJournal}
          downloadConsensual = {this.props.downloadConsensual}
          downloadClientExport = {this.props.downloadClientExport}
          downloadClientFullExport = {this.props.downloadClientFullExport}
          downloadUntreated = {this.props.downloadUntreated}

          expertother={ this.props.expertrestreint }
          doCheck = {this.doCheck}

        />

      }

      {this.props.access === "expert" && this.state.dashboardData.dashPage!==undefined && this.state.dashboardData.dashPage==="0.3.1"  &&

          <DashAccount
            data={this.state.apiInfo.info}
            goto={this.goTo}
            onFileChange={this.onFileChange}
            onFileUpload={this.onFileUpload}
            showFile = {this.state.showFile}
            fileInfo = {this.state.fileInfo}
            modifChap = {this.modifChap}
            confirmFile = {this.state.confirmFile}
            accountDone = {this.state.accountDone}
            accountSend = {this.accountSend}
            end = {this.state.end}
          />

      }



      {this.props.access === "expert" && this.state.dashboardData.dashPage!==undefined && this.state.dashboardData.dashPage==="0.1.1.1" &&

        <DashAccountGestion
          data={this.state.apiInfo}
          action = {this.gestionAction}
          inputChange = {this.inputChange}
          selectChange = {this.selectChange}
          fileChange = {this.fileChange}
          sendFile = {this.sendFile}
          error = {this.state.errorSend}
          deleteFile = {this.deleteFile}
          stopMailCheck = {this.stopMailCheck}
          expertother={ this.props.expertrestreint }
          assignExpert = {this.assignExpert}
          modifTechnidata = {this.modifTechnidata}
          modifTechnidata2 = {this.modifTechnidata2}
          addDSI = {this.addDSI}
        />

      }

      {this.props.access === "expert" && this.state.dashboardData.dashPage!==undefined && this.state.dashboardData.dashPage==="0.1.1.2"  &&
        <DashPreTraitement
          data = {this.state.apiInfo}
          readFile = {this.readFile}
          showFile = {this.state.showFile}
          alldata = {this.state}
          modifChap = {this.modifChap}
          fileInfoJointure = {this.state.fileInfoJointure}
          doJoin = {this.doJoin}
          preTraitement = {this.state.preTraitement}
          doPreTraitement = {this.doPreTraitement}
          onSelectExpert = {this.onSelectExpert}
          expertChoice = {this.state.preTraitement.expertChoice}
          deleteMedium = {this.deleteMedium}
          addMedium = {this.addMedium}
          resetDashPret = {this.resetDashPret}
          readFinal = {this.readFinal}
          doConfirmFile = {this.doConfirmFile}
          filetotranscode = {this.state.filetotranscode}
          checkfilefortranscoding = {this.checkfilefortranscoding}
          doTranscode = {this.doTranscode}
          resetDashMsg = {this.resetDashMsg}
          onBack = {this.onBack}
          onDisconnect = {this.props.disconnect}
          dataDoublon = {this.state.dataDoublon}
          onchecked = {this.onchecked}
          isChecked = {this.state.isChecked}
          allChecked = {this.state.allChecked}
          finalInformation = {this.finalInformation}
          finalinfo = {this.state.finalinfo}
          findJointureType = {this.findJointureType}
          jointureTypeData = {this.state.jointureType}
          tokenrefresh =  {this.props.state.alldata.tokenrefresh}
          client = {this.state.dashboardData.dashClient}
        />
      }

      {this.props.access === "expert" && (this.state.dashboardData.dashPage === undefined || this.state.dashboardData.dashPage!=="0.1.1.2")  &&
        <div className="dash_navigation">
          <NavButton isDefine={this.state.dashboardData.dashPage!=="0"}  onClick={this.onBack} text="Retour" styles="back"/>
          <NavButton isDefine={true}  onClick={()=>this.props.disconnect()} text="Quitter" />
        </div>
      }

      </div>

    )
  }

}



export default Dashboard
