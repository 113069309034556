import React from 'react'
import './clientlisting.css'
import Interligne from "../../interligne/interligne.js"
import NavButton from "../../next"

import Progressinfos from "./progressinfos.js"




class DashClientListing extends React.Component  {

  constructor(props){
    super(props);
    this.state =  {
      fileInfos:{},
    }
    this.fileChange = this.fileChange.bind(this)
  }


  fileChange(event){
    this.setState({fileInfos:event.target.files[0]})
  }

  createBase(){
    if (this.state.fileInfos !== undefined){
      this.props.formation(this.state.fileInfos)
    }
  }

  render(){

    return (
      <React.Fragment>

        {this.props.alldata.dashboardData.dashPage==="0" &&
          <div className = "dash_container_client">

            <div className="dash_client" onClick={() => this.props.goto("prive")}>
                <Interligne styles = "center"  label = "" value = "Labos privés" />
            </div>

            <div className="dash_client" onClick={() => this.props.goto("public")}>
              <Interligne  styles = "center" label = "" value = "Labos publics" />
            </div>

            {!this.props.expertother && 
            <React.Fragment>
              <div className="dash_client" onClick={() => this.props.goto("compte")}>
                <Interligne styles = "center"  label = "" value = "Ajouter un labo" />
              </div>

              <div className="dash_client" onClick={() => this.props.goto("done")}>
                <Interligne styles = "center"  label = "" value = "Reviewing client terminé" />
              </div>

              <div className="dash_client" onClick={() => this.props.goto("formation")}>
                <Interligne styles = "center"  label = "" value = "Gérer les formations" />
              </div>
            </React.Fragment>
            }


          

          </div>
        }
        {this.props.alldata.dashboardData.dashPage!==undefined && this.props.alldata.dashboardData.dashPage!=="0.3" && this.props.alldata.dashboardData.dashPage!=="0.4" &&  this.props.alldata.dashboardData.dashPage.indexOf("0.")>-1 &&
        <div className = "dash_container_client">
          {this.props.data !== undefined && this.props.data.clientlist !== undefined && this.props.data.clientlist.map( (v,k) => (
            <div className="dash_client dash_client_big" onClick={() => this.props.goto("baseclient",this.props.data.clientlist[k])}>
              <Interligne styles = "center"  label = "Client" value = {this.props.data.clientlist[k]} />
              <hr></hr>
 
              <div className="container_client_dico">
              { this.props.data.infoclient !== undefined && this.props.data.infoclient[this.props.data.clientlist[k]] !== undefined && this.props.data.infoclient[this.props.data.clientlist[k]].date !== "N/A" && 
                <Interligne styles = "alignement"  label = "Date" value = {this.props.data.infoclient[this.props.data.clientlist[k]].date} />
              }
              {this.props.data.infoclient[this.props.data.clientlist[k]].titrebase.map( (vv,kk) =>(
                <Progressinfos
                  data = {this.props.data}
                  client = {this.props.data.clientlist[k]}
                  number = {kk}
                  isReview = {vv.indexOf("review_") === 0}
                  test = {vv}
                />

               )
              )}
              </div>


           </div>
         ))}
         </div>
        }

        { this.props.alldata.dashboardData.dashPage!==undefined && this.props.alldata.dashboardData.dashPage==="0.3" &&
          <div className = "dash_container_client">
          {this.props.data !== undefined && this.props.data.clientlist !== undefined && this.props.data.clientlist.map( (v,k) => (
            <div className="dash_client" onClick={() => this.props.goto("accountclient",this.props.data.clientlist[k])}>
              <Interligne styles = "center"  label = "" value = {this.props.data.clientlist[k]} />
              <Interligne styles = "center"  label = "" value = {this.props.data.infoclient[this.props.data.clientlist[k]].labo_statut} />
            </div>
          ))}
          </div>

        }

      { this.props.alldata.dashboardData.dashPage!==undefined && this.props.alldata.dashboardData.dashPage==="0.4" &&
          <div className = "dash_container_client">
            <div className="dash_client dash_client_big formation_container">
            <div className = "import_files_action">
                  <input
                    type="file"
                    className = "input_import"
                    onChange={this.fileChange}
                    accept=".csv"
                  />
              </div>
              <NavButton isDefine={true}  onClick={()=>this.createBase()} text="Créer les bases"  />
              <NavButton isDefine={true}  onClick={this.props.deleteformation} text="Supprimer les bases" styles = "auditRed" />
              
            </div>
          </div>

        }


      </React.Fragment>





    )
  }
}


export default DashClientListing
