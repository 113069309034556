import React from 'react'
import Select from 'react-select'
import Interligne from "../interligne/interligne.js"
import './SelectAndTxt.css'

class SelectAndTxt extends React.Component  {

  render(){
    const width = this.props.width !== undefined ? this.props.width : 300
    const customStyles = {
      container: provided => ({
        ...provided,
        width: width,
        zIndex:this.props.index !== undefined ? this.props.index : 0
      })
    }

    return (
      <div className="container_select_txt">
        <Interligne styles = "alignement"  label = {this.props.label.name} value = {this.props.label.txt} />
        <Select
          value = {this.props.data.find(option => option.value === this.props.selectdata )}
          options = {this.props.data}
          onChange = {(e) => this.props.selectChange(e,this.props.tag) }
          styles={customStyles}
          className = {this.props.styles !== undefined ? this.props.styles : ""}
        />
      </div>
    )
  }

}



export default SelectAndTxt
