import React from 'react';

import ReactToPrint from 'react-to-print'
import Interligne from '../../interligne/interligne';
import NavButton from '../../next.js'
import './DataVisu.css'
import { BarChart, Bar,Cell, Pie,PieChart, XAxis, YAxis,  Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import { Roller } from 'react-awesome-spinners'


class DataVisu extends React.Component{


  constructor(props){
    super(props);
    this.state = {
        isOpen: false,
    }
  }


  rgbtoHex(){
    const r = Math.floor(Math.random() * 256) 
    const g = Math.floor(Math.random() * 256) 
    const b = Math.floor(Math.random() * 256) 
    return  '#'+ [r,g,b].map(x => {
      const hex = x.toString(16)
      return hex.length === 1 ? '0' + hex : hex
    }).join('')
  
  }



  render(){
    const dsiEmail = this.props.data!==undefined && this.props.data.dsi !== undefined &&  this.props.data.dsi.email!==undefined ?  this.props.data.dsi.email : ""
    const dsiNom = this.props.data!==undefined && this.props.data.dsi !== undefined &&  this.props.data.dsi.nomprenom!==undefined ?  this.props.data.dsi.nomprenom : ""
    const endDate =  this.props.data!==undefined && this.props.data.enddate !== undefined ? this.props.data.enddate : ""
    const exportDate =  this.props.data!==undefined && this.props.data.exportdate !== undefined ? this.props.data.exportdate : ""
    const deliverydate =  this.props.data!==undefined && this.props.data.deliverydate !== undefined ? this.props.data.deliverydate : ""


    const data = this.props.data !== undefined  && this.props.data.data ? this.props.data.data : []

    const arrayDonut = ["Unités LOINC", "Chapitres", "Milieu", "Milieu LOINC", "Echelle", "Echelle LOINC"]
    const classArray = ["pdf_leftdonut","pdf_rightdonut"]

    return(
        <div className = "container_compterendu">
                 { this.props.fileRender &&
              <div className="container_render center">
                <p>Pre-Chargement en cours</p>
                <Roller color="#f9af2e" />
              </div>
            }

          {this.props.data !== undefined && this.props.data.msg === "done" && 
          <div className='pdf' ref={(response) => (this.componentRef = response)} >

            <div className='container_head'>
              <div className='container_infoclient'>
                <Interligne styles="alignement" label="Contact" value = {dsiNom} />
                <Interligne styles="alignement" label="Contact" value = {dsiEmail} />

              </div>
              <div className='container_date'>
                <Interligne styles="alignement" label="Réception des exports le" value = {exportDate} />
                <Interligne styles="alignement" label="Livraison client pour vérification le" value = {deliverydate} />
                <Interligne styles="alignement" label="Compte-rendu généré le" value = {endDate.split(" ")[0]} />
              </div>
            </div>
            <div className='container_middle'>
              <div className='pdf_totalval'>
              <ResponsiveContainer width="100%" aspect={1.5}>

                  <BarChart 
                    width={400} 
                    data={data}   
                   >
                     <XAxis dataKey="name" textAnchor="end" angle={-45} height={80}/>

                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="Nombre de codes traités" fill="#8884d8" />
                  </BarChart>
                  </ResponsiveContainer>

              </div>
              <div className='pdf_repartition'>
                <ResponsiveContainer width="100%" aspect={1.5}>
                  <BarChart 
                    width={400} 
                    data={data}   
                    >
                    <XAxis dataKey="name" textAnchor="end" angle={-45} height={80}>
                       <Label value="Répartitions des codes LOINC" offset={0} position="insideBottom" />
                       </XAxis>

                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="Circuit bio."  stackId="a" fill="#8884d8" />
                    <Bar dataKey="Hors circuit bio."  stackId="a" fill="#82ca9d" />
                    <Bar dataKey="En attente"  stackId="a" fill="#ffc658" />
                    <Bar dataKey="Non transcodable"  stackId="a" fill="#ff1111" />
                    <Legend layout="vertical" verticalAlign="top" align="right" margin={{left:150}} />
       
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
            <div className='container_middle'>
              <div className='pdf_gold'>
                <ResponsiveContainer width="100%" aspect={1.5}>
                  <BarChart 
                    width={400} 
                    data={data}   
                    >
                    <XAxis dataKey="name" textAnchor="end" angle={-45} height={80}>
                    <Label value="Comparaison transcodage avant et après Onaos" offset={0} position="insideBottom" />
                       </XAxis>

                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="Gold client"  stackId="a" fill="#8884d8" />
                    <Bar dataKey="Gold non choisis"  stackId="a" fill="#82ca9d" />
                    <Legend layout="vertical" verticalAlign="top" align="right" margin={{left:150}} />
                  </BarChart>
                </ResponsiveContainer>
              </div>
              <div className='pdf_gold'>
                <ResponsiveContainer width="100%" aspect={1.5}>
                  <BarChart 
                    width={400} 
                    data={data.filter((v,k) => k!==0)}   
                    >
                    <XAxis dataKey="name" textAnchor="end" angle={-45} height={80}>
                      <Label value="Entrée cliente avec un libellé unité fourni" offset={0} position="insideBottom" />
                    </XAxis>
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="Entrées avec unité"  stackId="a" fill="#8884d8" />
                    <Legend layout="vertical" verticalAlign="top" align="right" margin={{left:150}} />
                
                          
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
            
    

{ arrayDonut.map((item,pos) => (
              <div className='container_foot'>
                <div  className='pdf_row'>
                  <h4 className='pdf_p'>{item}</h4>
                </div>
                <div className='pdf_row'>
                  { classArray.map((v,k) => (
                    <div className={v}>
                      <h4 className='head_piechart'>{data[k+1]["name"]}</h4>
                    <ResponsiveContainer width="100%" aspect="1">
                      <PieChart width={400} height={400}>
                        <Legend layout="vertical" verticalAlign="middle" align="right" />
                        <Pie
                          dataKey={item}
                        
                          data={data[k+1][item]}
                          cx="50%"
                          cy="50%"
                          fill="#8884d8"
                          
                          >
                          {data[1][item].map((entry, index) => (
                            <Cell key={"cell_"+item+"_"+k+"_"+index} fill={this.rgbtoHex()} />
                          ))}
        
                        </Pie>
                
                        <Tooltip />

                      </PieChart>
                    </ResponsiveContainer>
                    
                  </div>
                  ))
                  }
                </div>
              </div>
))}
        




            


         




          </div>
          }
          <ReactToPrint
            content={() => this.componentRef}
            trigger={() => <NavButton isDefine={true}  text="Exporter PDF" />}
          />
        </div>
    )
  }


}



export default DataVisu
