import React from 'react'
import './clientactions.css'
import Interligne from "../../interligne/interligne.js"
import ClientInfo from "../clientinfo/clientinfo.js"
import NavButton from "../../next";
import { Roller } from 'react-awesome-spinners'


class DashClientActions extends React.Component  {
  constructor(props){
    super(props);
    this.state= {
      base:''
    }
    this.switchbase = this.switchbase.bind(this)
    this.classbase = this.classbase.bind(this)
  }

  componentDidUpdate(prevProps, prevState){

  }

  switchbase(basenumber){
    this.setState({base:Number(basenumber)})
  }

  goToBase(tag){
    if (this.state.base!=="" && tag===this.props.alldata.apiInfo.info.statut[this.state.base]) {
      this.props.goto("base",this.props.alldata.dashboardData.dashClient,this.props.alldata.apiInfo.info.titrebase[this.state.base])
    }
  }

  classbase(tag){
    let color = "dash_client_actions dash_client_actions_unactif"
    if (this.state.base!=="" &&  tag===this.props.alldata.apiInfo.info.statut[this.state.base]) {
      color = "dash_client_actions dash_client_actions_actif"
    }
    return color;
  }


  downloadConsensus(){
    this.props.downloadConsensual(this.props.alldata.apiInfo.info.titrebase[this.state.base])
  }

  downloadClientExport(){
    this.props.downloadClientExport(this.props.alldata.apiInfo.info.titrebase[this.state.base])
  }

  downloadClientFullExport(){
    this.props.downloadClientFullExport(this.props.alldata.apiInfo.info.titrebase[this.state.base])
  }


  downloadClientFullExportWtComm(){
    this.props.downloadClientFullExport(this.props.alldata.apiInfo.info.titrebase[this.state.base], true)
  }

  downloadUntreated(type = ""){
    this.props.downloadUntreated(this.props.alldata.apiInfo.info.titrebase[this.state.base], type)
  }


  

  render(){
    const expertAllowed = this.props.expertother
    return (
      <div className = "dash_container_client_all">
        <ClientInfo 
          data={this.props.alldata} 
          expertAllowed = {expertAllowed} 
          switchbase={this.switchbase} 
          modifBase={this.props.modifBase} 
          doCheck = {this.props.doCheck}
          doEverything = {this.props.doEverything} 
          downloadJournal = {this.props.downloadJournal}
        />


        <div className = "dash_container_client_action">
          { !expertAllowed && 
            <React.Fragment>
              <div className="dash_client_actions dash_client_actions_actif" onClick={()=>this.props.goto("accountgestion")}>
                <Interligne styles = "center"  label = "Gestion comptes" value = "" />
              </div>
                <div className="dash_client_actions dash_client_actions_actif" onClick={()=>this.props.goto("pretraitement",this.props.alldata.dashboardData.dashClient,"pretraitement")}>
                <Interligne styles = "center"  label = "Pré-traitement" value = "" />
              </div>

            

            </React.Fragment>

          }


       
          <div className={this.classbase("validation expert")} style = {{display:"flex", flexDirection: 'column', gap : "10px"}} >
            <Interligne styles = "center"  label = "Validation expert" value = "" />
            <NavButton
              isDefine={this.props.alldata.apiInfo.info.statut[this.state.base]==="validation expert"}
              onClick={()=>this.goToBase("validation expert")}
              text="Accéder"
              styles="dashbutton"
            />
            <NavButton
              isDefine={this.props.alldata.apiInfo.info.statut[this.state.base]==="validation expert" &&
                this.props.alldata.apiInfo.info.titrebase[this.state.base].indexOf("review_")===-1 && this.props.alldata.apiInfo.info.progression[this.state.base] === 100
                }
              onClick={() =>
                window.confirm("Êtes-vous sur de vouloir lancer ce script ?") &&
                this.props.createNewbase(this.props.alldata.apiInfo.info.titrebase[this.state.base])
              }
              text="Créer une base de relecture"
              styles="auditRed dashbutton"
            />        
            {this.props.alldata.apiInfo.info.statut[this.state.base]==="validation client" && 
              <NavButton
                isDefine={true}
                onClick={()=>this.downloadConsensus()}
                text="Télécharger (consensus)"
                styles="dashbutton"
              />
            }
            {this.props.alldata.apiInfo.info.statut[this.state.base]==="validation client" && 
              <NavButton
                isDefine={true}
                onClick={()=>this.downloadClientExport()}
                text="Export client"
                styles="dashbutton"
              />
            }
            {this.props.alldata.apiInfo.info.statut[this.state.base]==="validation client" && 
              <NavButton
                isDefine={true}
                onClick={()=>this.downloadClientFullExport()}
                text="Export client full"
                styles="dashbutton"
              />
            }

            {this.props.alldata.apiInfo.info.statut[this.state.base]==="validation expert" && 
              <NavButton
                isDefine={true}
                onClick={()=>this.downloadUntreated()}
                text="Export ANS (non relue)"
                styles="dashbutton"
              />
            }

            {this.props.alldata.apiInfo.info.statut[this.state.base]==="validation expert" && 
              <NavButton
                isDefine={true}
                onClick={()=>this.downloadUntreated("mapper")}
                text="Export ANS (MAPPER)"
                styles="dashbutton"
              />
            }


            
            {this.props.alldata.apiInfo.info.statut[this.state.base]==="validation client" && 
              <NavButton
                isDefine={true}
                onClick={()=>this.downloadClientFullExportWtComm()}
                text="Export client full avec Comm"
                styles="dashbutton"
              />
            }

            {this.props.alldata.showLoading &&
              <Roller color="#f9af2e" />
            }

          </div>
          {!expertAllowed && <div className={this.classbase("validation client")} style = {{display:"flex", flexDirection: 'column', gap : "10px"}} >
            <Interligne styles = "center"  label = "Validation client" value = "" />
            <NavButton
              isDefine={this.props.alldata.apiInfo.info.statut[this.state.base]==="validation client" }
              onClick={()=>
                window.confirm("Êtes-vous sur de vouloir remettre à 0 la base client ?") &&
                this.props.reset(this.props.alldata.apiInfo.info.titrebase[this.state.base])
              }
              text="Reset"
              styles="auditRed dashbutton"
            />
            <NavButton
              isDefine={this.props.alldata.apiInfo.info.statut[this.state.base]==="validation client" &&
                this.props.alldata.apiInfo.info.isconsensus[this.state.base] === "non" &&
                this.props.alldata.apiInfo.info.needconsensus[this.state.base]
              }
              onClick={() =>
                window.confirm("Êtes-vous sur de vouloir lancer ce script ?") &&
                this.props.createNewbase(this.props.alldata.apiInfo.info.titrebase[this.state.base],"consensus")
              }
              text="Passer en consensus"
              styles="auditRed dashbutton"
            />
            {this.props.alldata.apiInfo.info.statut[this.state.base]==="validation client" && 
              <NavButton
                isDefine={true}
                tooltip = "mail"
                tooltipText = {"email : "+this.props.alldata.apiInfo.info.nomexpert[this.state.base]}
                onClick={()=>
                  this.props.alldata.apiInfo.info.nomexpert[this.state.base]!=="" && window.confirm("Envoyer un mail à l'expert du client ?") &&
                  this.props.sendMail(this.props.alldata.apiInfo.info.titrebase[this.state.base])
                }
                text="Envoyer un mail"
                styles="auditRed dashbutton"
              />
            }


    
          </div>}
          {this.props.alldata.tmpInfo==="done" &&
              <Interligne styles = "center"  label = "" value = "Script exécuté" />
            }
        </div>
      </div>
    )
  }
}


export default DashClientActions
