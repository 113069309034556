import React from 'react';
import './main.css';
import Navtuto from '../navtuto/navtuto.js'
import Page from '../page/page.js'

const MAXPAGE = 50

class Tuto extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        page :0
      }
      this.prev = this.prev.bind(this)
      this.next = this.next.bind(this)
      this.end = this.end.bind(this)
    }

    next(){
      if (this.state.page >=MAXPAGE) this.end()
      this.setState({
        page : this.state.page +1
      })
    }

    prev(){
      const pp = this.state.page >0 ?   this.state.page - 1 :0
      this.setState({
        page : pp
      })
    }

    end(){
      if (this.props.tutostate){
        this.props.showTuto()
      } else {
        this.props.toEnd()
      }
      
    }

  render(){
    return(
      <div className="container_tuto">
        <input className="exit_tuto" type="button" value="Fermer le tutoriel" onClick={this.end} />
        <div className="container_page">
          <Page
            numero = {this.state.page}
         />
        </div>
        <Navtuto
          suivant = {this.next}
          precedent = {this.prev}
          numero = {this.state.page}
          maxpage = {MAXPAGE}

        />
      </div>
    )
  }
}


export default Tuto
