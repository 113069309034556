import React from 'react'
import NavButton from "../../next"
import InputField from '../../InputField.js'
import './PasswordSaisie.css'


class PasswordSaisie extends React.Component  {

  constructor(props){
      super(props);
      this.state = {
          password: '',
          confirmPassword  :'',
      }
      this.doConfirm = this.doConfirm.bind(this)
  }


  getError(data){
    return false
  }

  doConfirm(){
    if (!this.state.confirmPassword){
        this.setState({logerror:true});
        return;
    }
    if (!this.state.password){
        this.setState({logerror:true});
        return;
    }
    if (this.state.password!==this.state.confirmPassword){
        this.setState({logerror:true});
        return;
    }
    this.props.createPassword(this.state.password)


  }

  setInputValue(property, val){
      val = val.trim();
      this.setState({
          [property]: val
      })
  }

  componentDidMount(){
    document.body.classList.remove("body_back");

  }

  render (){
    //todo pour this.props.type==="dsi"
    const email = this.props.type==="expert" ? this.props.data.infos.Email : this.props.data
    const texte = this.props.type==="expert" ? "Création d'un compte Onaos" : "Modification du mot de passe"
    return(
      <React.Fragment>
      <div className="login_page_leftside">
        <img src="img_forgot_password.svg" alt="Compte" className="labo_img"/>
      </div>
      <div className="expert__account">
        <div className="loginForm">
          <img src="logo_onaos.png" alt="Logo" className="title_onaos"/>
          <h1 className="modif_pswd_title">{texte}</h1>
          <InputField
              type='text'
              placeholder='login'
              value={email}
              disabled = {true}
              styles="input_size_recup"
          />

          <InputField
              type='password'
              placeholder='Mot de passe'
              defaultValue={this.state.password ? this.state.password : ''}
              onChange={ (val) => this.setInputValue('password', val) }
              onKeyPress={this.doConfirm}
              styles="input_size_recup"
          />
          <InputField
              type='password'
              placeholder='Confirmez votre mot de passe'
              defaultValue={this.state.confirmPassword ? this.state.confirmPassword : ''}
              onChange={ (val) => this.setInputValue('confirmPassword', val) }
              onKeyPress={this.doConfirm}
              styles="input_size_recup"
          />
          <p className="underbox font_color">Saisissez de nouveau votre mot de passe</p>
          { this.state.logerror===true &&
            <p className="errorLog">Les mots de passe ne correspondent pas</p>
          }
          <NavButton
            isDefine={true}
            text="Valider"
            styles="labo_account_btn"
            onClick = {()=>this.doConfirm()}
          />

        </div>
      </div>

      </React.Fragment>
    )
  }

}


export default PasswordSaisie;
