import React, {useState, useEffect} from "react"

import Logo from "../../img/logo_onaos.png";

import { Roller } from 'react-awesome-spinners'
import NavButton from '../next.js'
import Interligne from "../interligne/interligne";
import { putAPI, getAPI } from "../../api/api";
import Select from 'react-select'



export const DSIExports = (props) => {

    const dataAPI = props.alldata
    const [showLoading, setLoading] = useState(false)
    const [selectTypeExport, setExport] = useState([
        "ans","ans","ans"
    ])
    const [showInformation, setInformations] = useState(0)

    const [datasForExport, setDatasForExport] = useState({})

    const [showNOK, setNOK] = useState(false)


    const mainDiv = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        overflow: "auto"
    }
    
    const logoLine = {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "right",
        marginTop: "1%",
        width: "100%",
    }

    const mainGround = {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "80%",
        backgroundColor: "rgba(255,255,255,0.5)",
        borderStyle: "none",
        borderRadius: "25px",
        marginTop: "1%",
        marginBottom: "1%",
    }

    const upAndDownDiv = {
        width: "80%",
        borderStyle: "none",
        borderRadius: "25px",
        boxShadow:" 0 5px 14px rgba(0,0,0,.15)",
        padding: "10px",
        backgroundColor: "#fff",
        height:" 350px",
        display: "flex",
        flexDirection: "column",
        margin:"2%",
        justifyContent: "space-around",
        padding:"20px"
    }

    const upAndDownDivDown = {
        ...upAndDownDiv,
        height:" 200px",
    }

    const rollerDiv = {
        position: "absolute",
        top:"40%",
        zIndex:"100",
        textAlign: "center"
    }

    const datas = [
        "Chapitres",
        "Demandes",
        "Résultats",
    ]

    const lineStyle = {
        width : "99%",
        display: "flex",
        padding : "1%",
        justifyContent : "space-between",
        alignItems : "center"
    }

    const customStyles = {
        container: provided => ({
          ...provided,
          width: 250,
        })
      };


    const titleText = {
        color: "#315D79"  ,
        textAlign: "center",
        marginBottom: 0,
      marginTop: "10px"
    }


    const textStyle = {
        textAlign: "center",
        lineHeight: "1.5em"
    }


    const assignExport = (value, position, itemPos) => {
        const tmp = {...selectTypeExport}
        tmp[position] = value
        setExport(tmp)
        setInformations(itemPos)
    }

    const options = [
        {label : "Export ANS", value :"ans", pos : 0},
        {label : "Export complet", value :"full", pos : 1},
        {label : "Export complet Dedalus", value :"dedalus", pos : 2},
        {label : "Export complet CGM Molis", value :"withbiocgm", pos : 3},
    ]

    const explicationExport = [
        <p style = {textStyle} >Cet export ne contient que les codes LOINC appartennant au jeu de valeurs 'Circuit de la biologie' conformément aux exigences de l'ANS.</p>,
        <p style = {textStyle} >Ce fichier contient l'ensemble des codes LOINC retenus, y compris les codes en dehors du jeu de valeurs 'Circuit de la Biologie'. Attention, conformément aux exigences de l'ANS ce fichier ne vous est fourni qu'à titre informatif.</p>,
        <p style = {textStyle} >Ce fichier contient l'ensemble des codes LOINC retenus, y compris les codes en dehors du jeu de valeurs 'Circuit de la Biologie' dans un format répondant aux exigences spécifiques de Dedalus.</p>,
        <p style = {textStyle} >Ce fichier contient l'ensemble des codes LOINC retenus, y compris les codes en dehors du jeu de valeurs 'Circuit de la Biologie' dans un format répondant aux exigences de CGM Molis.</p>

    ]




    const defineTitle = (position, type, dateFile) => {
        const dateFileName = dateFile === "" ? getCurrentDate() : dateFile
        switch (position) {
            case 0:
                if (type === "ans") return "LOINC_4bis_transcodage_chapitres_"+dateFileName+".csv"
                if (type === "full") return "Export_Complet_LOINC_4bis_transcodage_chapitres_"+dateFileName+".csv"
                if (type === "dedalus") return "LOINC_4_transcodage_chapitres_"+dateFileName+".csv"
                if (type === "withbiocgm") return "LOINC_4bis_transcodage_chapitres_"+dateFileName+".csv"

            case 1:
                if (type === "ans") return "LOINC_5bis_transcodage_demandes_"+dateFileName+".csv"
                if (type === "full") return "Export_Complet_LOINC_5bis_transcodage_demandes_"+dateFileName+".csv"
                if (type === "dedalus") return "LOINC_5_transcodage_demandes_"+dateFileName+".csv"
                if (type === "withbiocgm") return "LOINC_5bis_transcodage_demandes_"+dateFileName+".csv"

            case 2:
                if (type === "ans") return "LOINC_6bis_transcodage_résultats_"+dateFileName+".csv"
                if (type === "full") return "Export_Complet_LOINC_6bis_transcodage_résultats_"+dateFileName+".csv"
                if (type === "dedalus") return "LOINC_6_transcodage_resultats_"+dateFileName+".csv"
                if (type === "withbiocgm") return "LOINC_6bis_transcodage_resultats_"+dateFileName+".csv"
            default:
                return ""
        }

    }


    const getCurrentDate = () => {
        const separator=''
        let newDate = new Date();
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
     
        return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`
    }
     


    const downloadFile = (position) => {
        const dataPut = {
            dico : position,
            type : selectTypeExport[position]
        }
        setLoading(true)
        getAPI("/startdateclient/"+datasForExport.client,dataAPI.tokenrefresh).then(dateFile => {
            putAPI("/downloadDSI",dataAPI.tokenrefresh,dataPut,"blob").then(data => {
                const nameFile = defineTitle(position, selectTypeExport[position], dateFile["date"])
                const urld = window.URL.createObjectURL(new Blob([data]));
                const link = document.createElement('a');
                link.href = urld;
                link.title = "Onaos";
                link.setAttribute('download', nameFile);
                document.body.appendChild(link);
                link.click();
                link.remove();
                window.URL.revokeObjectURL(urld);
                setLoading(false)
            })
        })
    }

    useEffect( () => {
        getAPI("/dsiexport", dataAPI.tokenrefresh).then (data => {
            if (data["Chapitres"].done === "nok" || data["Demandes"].done === "nok"  || data["Résultats"].done === "nok" ) setNOK(true)
            setDatasForExport(data)
        })
    }, [])

    return(
        <div style={mainDiv}>
            <div style={logoLine}>
                <img className="logo_onaos_mapping" src={Logo} alt="Logo Onaos"/>
            </div>

            {showLoading &&
            <div style={rollerDiv}>
              <Roller color="#ffab40" />
            </div>
            }
            
            <div style = {mainGround}>
                <div  style = {upAndDownDiv}>
                 { datas.map((v,k) => (
                    <div style = {lineStyle}>
                        <Interligne label = {"Télécharger l'export du dictionnaire de "+v} value = "" />
                        <Select 
                            styles={customStyles}
                            onChange={(e) => assignExport(e.value, k, e.pos) }
                            options={options}
                            value = {options.find(option => option.value === selectTypeExport[k])}
                        />
                        <NavButton isDefine={true} onClick={()=> datasForExport[v] === undefined || datasForExport[v].done === "nok" ? null : downloadFile(k)} text="Télécharger" style = {datasForExport[v] === undefined || datasForExport[v].done === "nok" ? {backgroundColor:"rgb(147,147,147)"} : {}} />
                    </div>
                 )) }

                 { showNOK && 
                    <p style = { {color : "rgb(147,147,147)", fontSize : "14px", fontWeight:"bold", fontStyle : "italic"}}>Téléchargement impossible car un ou plusieurs experts n'ont pas terminé la vérification des transcodages de leur dictionnaire</p>
                    }
                </div>

                <div  style = {upAndDownDivDown}>
                    <h4 style = {titleText}>Informations sur l'export sélectionné :</h4>
                   {explicationExport[showInformation]}
                </div>

            </div>
                
            
            <div className="container_btn_endform">
                <div style = {{
                display : "flex",
                width : "100%",
                justifyContent : "center",
                alignItems : "center"

                }}>
                    <NavButton isDefine={props.alldata.contact}  onClick={()=>props.gobackDSIPage()} text="Retour" />
                    <NavButton isDefine={true} onClick={props.disconnect} text="Quitter"  />
                </div>
            </div>
        </div>
    )

}