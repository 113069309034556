import React from "react";
import SelectAndTxt from '../../SelectAndTxt/SelectAndTxt.js'
import NavButton from "../../next.js"
import CheckList from './CheckList.js'

class TranscodageAuto extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      basetraitement:"",
      type:"",
      done : false,
    }
    this.selectChange = this.selectChange.bind(this)
    this.done = this.done.bind(this)
  }


  done(value){
    this.setState({
      done : value
    })
  }


  selectChange(e,tag){
    this.props.resetDashMsg()
    this.setState({
      [tag] : e.value,
      type : e.type,
      done:false
    })
    if (e.value !=="") this.props.checkfilefortranscoding(e.type)

  }

  validation(merge){
    this.props.doTranscode(this.state.basetraitement,merge,this.props.mode)
    this.setState({done:false})

  }

  render(){

    const selectData = this.props.data.dico!==undefined ?  this.props.data.dico.filter(e=> e.status==="ready").map( (v,k)=>({
      label : v["nom"] + " (" + v["type"]+")",
      value : v["id"],
      type : v["type"] === "Résultat" ? "resultats" : "demandes"
    })) : {label:"",value:""}

    selectData.push({label:"chapitre",value:"chapitre",type:"chapitres"})
    const btnMsg = this.props.mode === "debug" ? "Lancer le mapper (Debug)" : "Lancer le mapper"
    const btnMsgMerge = this.props.mode === "debug" ? "Fusionner avec la ou les bases déjà transcodées (Debug)" : "Fusionner avec la ou les bases déjà transcodées"
    return(
      <div className="container_modif">
        <SelectAndTxt
          label = { {name : "Sélectionnez le dictionnaire à traiter" ,txt : ""} }
          data  = {selectData}
          selectChange = {this.selectChange}
          tag = "basetraitement"
          selectData = {this.state.basetraitement}
          index = {400}
        />

        { this.props.alldata.showFile  && this.state.type !== "chapitres" &&
          <CheckList
            data = {this.props.filetotranscode}
            type = {this.state.type}
            done = {this.done}
          />
        }

        {this.props.alldata.showFile && this.props.alldata.filetotranscode.find  &&
          <div>
            <p className="basefind">Cette base a déjà été transcodée !</p>
            <NavButton isDefine={this.state.done  || this.state.type === "chapitres"}  onClick={()=>this.validation(true)} text={btnMsgMerge} styles="btn_modif_dico" />

          </div>

        }

        <NavButton isDefine={!this.props.alldata.filetotranscode.find && (this.state.done || this.state.type === "chapitres" )}  onClick={()=>this.validation(false)} text={btnMsg} styles="btn_modif_dico" />

        {this.props.alldata.message !=="" &&
        <p className="interligne center margin50">{this.props.alldata.message}</p>
        }

        {this.props.alldata.messageDebug !=="" &&
        <p className="interligne center margin50">{this.props.alldata.messageDebug}</p>
        }

      </div>
    )
  }
}



export default TranscodageAuto
