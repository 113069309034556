import React from 'react'
import './dictionnairetable.css'
import Select from 'react-select'




class Dictionnairetable extends React.Component  {


  getSelect(type){
    let data=[]
    let option=[]
    switch (type){
      case 'chapitre':
        option = ["Ne pas garder","Code chapitre SGL","Libellé chapitre SGL","Code sous-chapitre SGL","Libellé sous-chapitre SGL","Code LOINC chapitre" , "Libellé chapitre" , "Code LOINC sous-chapitre" , "Libellé sous-chapitre","Non transcodable"]
        break
      case 'Demande':
        option = ["Ne pas garder","Garder sans modif","Code examen SGL" , "Libellé examen SGL", "Synonyme SGL", "Code chapitre SGL" ,"Code sous-chapitre SGL" , "Milieu SGL" , "Codes NABM" , "Code analyse" , "Libellé analyse" ,"LOINC","Tube à prélever","Non transcodable","Onaos_Code chapitre SGL","Résultats associés"]
        break
      case "Résultat":
        option = ["Ne pas garder","Garder sans modif",  "Code SGL analyse résultat" , "Libellé SGL analyse résultat", "Synonyme libellé SGL de l'analyse", "Grandeur SGL" , "Code unité SGL" , "Libellé unité SGL" , "Temporalité SGL" , "Code milieu SGL" , "Libellé milieu SGL" , "Echelle SGL" , "Technique SGL", "Codes NABM" ,"LOINC","Tube à prélever","Code chapitre SGL","Non transcodable","Onaos_Code chapitre SGL","Code demande","Libellé demande","Demandes associées"]
        break
      case 'Jointure_origine':
        option = ["Select ...","Identifiant unique","Origine des données"]
        break
      case 'Jointure_destination':
        option = ["Select ...","Identifiant unique","Colonne de destination"]
        break
        default:
        break
    }

    option.forEach((item, i) => {
      data[i]={
        label:item,
        value:item
      }
    });
    return data
  }





  render(){



    const customStyles = {
      container: provided => ({
        ...provided,
        width: 150
      })
    }

    const head = this.props.data[0]
    const body = this.props.confirmStatut ?  this.props.data.slice(1) : this.props.data.slice(1,20)
    const headSelect = this.getSelect(this.props.type)
    const defaultVal = this.props.type === "Jointure_origine" || this.props.type === "Jointure_destination" ? "Select ..." : "Garder sans modif"
    return(
      <div className="container_dicotable">
        <table className="dico_table">
        <thead>
          <tr>
            {  head.map( (v,k) => (
              <th>
              {!this.props.confirmStatut &&
                <Select className="center select_table"
                  value = {headSelect.find(option => option.value === (this.props.select[k]===undefined ? defaultVal : this.props.select[k]) )}
                  options={headSelect}
                  onChange={(e)=>this.props.onSelect(e,k)}
                  styles={customStyles}
                />
              }
                {v}
              </th>
            ))}

          </tr>
        </thead>
        <tbody>
          {body.map( (v,k) => (
              <tr>
              {  v.map( (vv,kk) => (
                  <td>{vv}</td>
                ) )}
              </tr>
          ))}
        </tbody>
        </table>
      </div>

    )
  }

}


export default Dictionnairetable
