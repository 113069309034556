import React from 'react';
import './sidebartitle.css';
import { GrFormDown,GrFormUp,GrCircleInformation } from 'react-icons/gr';
import ReactTooltip from 'react-tooltip';


class SideBarTitle extends React.Component {


  render(){
    const styles = this.props.classname ? this.props.classname:"title_filter"
    const tooltip = this.props.tooltipVal !== undefined ? this.props.tooltipVal : ""
    const notForClick = this.props.noIcon !== undefined ? this.props.noIcon : []

    return(
          <React.Fragment>
      {this.props.styles!==undefined &&

        <th  className={styles} onClick={()=>this.props.onClick(this.props.data)} data-tip data-for={this.props.data}>


          <span className="container_information_table">
            { (this.props.data!=="NABM"  && !notForClick.includes(this.props.data) && (this.props.dropdown[this.props.data]===undefined ||  this.props.dropdown[this.props.data]!==true)) &&
              <GrFormUp  />
            }
            { (this.props.data!=="NABM"  && !notForClick.includes(this.props.data) && this.props.dropdown[this.props.data]!==undefined && this.props.dropdown[this.props.data]===true) &&
              <GrFormDown  />
            }
            {this.props.data}
            {notForClick.includes(this.props.data) &&
              <GrCircleInformation className = "information_table"/>
            }

          </span>
          {tooltip !== "" &&
            <ReactTooltip multiline={true} id={this.props.data}  type="warning" effect="solid">
              {tooltip}
            </ReactTooltip>
          }

        </th>
      }

      {this.props.styles===undefined &&

        <p  className={styles} onClick={()=>this.props.onClick(this.props.data)} >

          { this.props.dropdown[this.props.data]!==true &&
            <GrFormDown  />
          }
          { this.props.dropdown[this.props.data]===true &&
            <GrFormUp  />
          }

          {this.props.data}
        </p>
      }
    </React.Fragment>
    )

  }
}


export default SideBarTitle;
