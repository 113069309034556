import React from 'react';
import './navtuto.css';


class Navtuto extends React.Component {


  render(){
    const next = this.props.numero>=this.props.maxpage ? "Terminer" : "Suivant"
  

    return(
    <div className="container_global_button">
      <div className="container_button">
        <div className="container_left_button">
          {this.props.numero > 0  && 
            <React.Fragment>
              <div className="left_side_button" onClick={()=>this.props.precedent()}></div>
              <p className="prec">Précédent</p>
            </React.Fragment>
            
          }
        </div>
        <div className="container_right_button">
          <p className="next">{next}</p>
          <div className="right_side_button" onClick={()=>this.props.suivant()}></div>
        </div>
      </div>
    </div>
    )
  }

}


export default Navtuto
