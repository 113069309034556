import React from 'react';
import './map.css';
import {  GrFormCheckmark, GrFormClose, GrUpdate } from 'react-icons/gr';
import Select from 'react-select'
import NavButton from "../next";
import Search from '../search/Search.js'
import Interligne from '../interligne/interligne.js'
import LoincInfo from '../loincinfo/loincinfo.js'
import { LoincVeille } from './LoincVeille';


class MultiMap extends React.Component {
  constructor(props){
    super(props);
    this.state={
      back:[]
    }
    this.cancelEdit = this.cancelEdit.bind(this)
    this.onRegister = this.onRegister.bind(this)
    this.onPanelClick = this.onPanelClick.bind(this)


  }

  findNewLOINC(even,tag) {
    if (tag ==="Technique" && even.value==="Non renseignée dans le LOINC") even.value=null
    this.props.findNewLOINC(this.props.duplicatecard["loinc"],this.props.duplicatecard["numero"],tag,even.value)
  }


  duplicate(e,who,composant,loinc){
    e.stopPropagation()
    this.props.findDuplicate(who,composant,loinc)
  }

  getGoldClient(data,numero, isveille){
    let result = "container_propositions_cards_map container_propositions_cards_map_scale"
    let loinc = data["Code LOINC"]
    let attribut = data["attribut"]
    if (this.props.loincData["goldclient"]=== loinc && this.props.basestatut!== "audit" && ((this.props.basestatut=== "validation client") || (this.props.basestatut=== "validation expert" && numero===0 ) )){
        return result+" goldclient";
    }

    if (data["fromotherclient"] ) return result+" from_other_client";
    if (data["fromca"] ) return result+" from_ca";


    if (loinc==="Absent du LOINC" && this.props.basestatut!== "audit"){
        return result+" outloinc";
    }
    if (loinc==="Non transcodable" && this.props.basestatut!== "audit"){
        return result+" outloinc_nt";
    }

    if (attribut==="Codes alternatifs"  && this.props.basestatut=== "validation client" && isveille === undefined){
        return result+" outloinc_client";
    }

    if (attribut==="Codes alternatifs client"  && this.props.basestatut=== "validation client" && isveille === undefined){
        return result+" add_client";
    }


    return result
  }



  getClassCards(statut,loinc="", position = 0){
    let result="";
    switch (this.props.basestatut){
      case 'validation expert':
        if (loinc!=="" && this.props.loincData["goldclient"]=== loinc){
            if (position === 0) {
              return result
            } else {
              if (statut===false){
                return result=result + "img_reject_test_map";
              } else {
                return result= result + "img_agree_test_map";
              }
            }
        }
        if (statut===false){
          result=result + "img_reject_test_map";
        } else {
          result= result + "img_agree_test_map";
        }
        return result;
      case 'validation client':
        if (statut !== "") {//((Object.entries(this.props.loincData["Choix client"]).length >0 && statut!=='') || (this.props.number>1 && statut!=='') ) {
          if (statut===false){
            result=result + "img_reject_test_map";
          }
          if (statut===true) {
            result= result + "img_agree_test_map";
          }
        }
        return result;
      default:
        return result
    }
  }

  onCardClick(e,param,loinc=""){
    if (this.state.back[param]===true) return true
    const code = this.props.loincData["Propositions LOINC"][param]['Code LOINC']
    if (this.props.basestatut!=="validation client" &&  (code==="Non transcodable" || code==="Absent du LOINC")) return true
    if (this.props.basestatut==="audit") return true
    if (this.props.duplicatecard!=="" && loinc==="") return true
    if (this.props.loincData["goldclient"]=== code && this.props.basestatut!=="validation client") return true
    if (this.props.basestatut==="validation client" &&  this.props.number===1) {
      this.props.click(param,loinc, true); 
      return true
    }

    this.props.click(param,loinc);

  }

    onCancel(){
      this.props.duplicateCancel();
    }

    showAdd(){
      this.props.showAdd(true)
    }

    cancelEdit(){
      this.props.showAdd(false)
    }

    onRegister(code){
      this.props.addAfterSearch(code)
      this.props.showAdd(false)
    }

    showSearch(){
      this.props.showSearch(true)
    }

    getNoCodeStyle(){
      let result = "nocode"
      if (this.props.loincData["transcodablechoix"]==="accepté") result=result + " img_agree_test_map";
      if (this.props.loincData["transcodablechoix"]==="refusé") result=result + " img_reject_test_map";
      return result
    }


    getImg(text){
      let src = "croix_rouge.png"
      if (text==="Absent du LOINC") src="croix.png"
      return src
    }

    getTextIsRestreint(data){
      let resultat = data["résultat inRestreint"] ? "Résultats" : ""
      let demande = data["demande inRestreint"] ? "Demandes" : ""
      let texte = "non"
      if (this.props.baseinfos.typebase === "Chapitres" && data["chap/souschap inRestreint"]) {
        texte = "Chap"
      }  else {
        if (resultat !=="" && demande==="") texte = resultat
        if (demande !=="" && resultat==="") texte = demande
        if (demande !=="" && resultat!=="") texte = "Rés. + Dem."
      }

      return texte

    }

    onPanelClick(k,loinc,type){
      const tmp = {...this.state.back}
      tmp[k] = !tmp[k]
      this.setState({back:tmp})
      this.props.panelSearch(loinc,type)
    }

  render(){

    const duplication = [
      ["Grandeur","listingunit","Grandeur","Grandeur"],
      ["Milieu","listingmilieu","Milieu biologique","Milieu biologique"],
      ["Temps","listingtime","Temps","Temps"],
      ["Échelle","listingechelle","Echelle","Echelle"],
      ["Technique","listingtechnique","Technique","Technique"]
    ]
    return(
      <div className="full_container_propositions">
      { this.props.showAddVal===true &&
        <Search show={true} cancel={this.cancelEdit} onRegister={this.onRegister} />
      }

      <div className="tmp_recherche_dash_block">
        <div className= 'btn_search_container'>
          <h1 className="title_propositions margin_title h_no_bottom" >Propositions LOINC</h1>
          <NavButton isDefine={true}  onClick={()=>this.showSearch()} text="Rechercher" />
        </div>
      </div>
      <div className="container_propositions_map">

      {this.props.loincData["veille"] && 
        <LoincVeille
            data={this.props.loincData["veille"]}                 
        />
      }

      {(this.props.donottranscode===false || this.props.basestatut==="validation expert") && this.props.loincData["Propositions LOINC"].length>=1 && this.props.loincData["Propositions LOINC"][0]["Code LOINC"]!==undefined &&  this.props.loincData["Propositions LOINC"].map( (vv,kk) => (
          <div className={this.getGoldClient(vv,kk,  this.props.loincData["veille"]) }>

  


            { this.props.basestatut==="validation expert"  && (this.props.duplicatecard==="" || this.props.duplicatecard["numero"]!==kk ) && (this.props.loincData["goldclient"]!==vv['Code LOINC'])
              && (this.props.loincData["transcodable"]==="oui" || (vv['Code LOINC']!=="Absent du LOINC"  && vv['Code LOINC']!=="Non transcodable" )) &&
              <span className="duplicate"><GrUpdate  className="icone_duplicate" onClick={(e) => this.duplicate(e,kk,vv['Composant'],vv['Code LOINC'])}/></span>
            }

            { this.props.basestatut==="validation expert" &&  this.props.duplicatecard["numero"]===kk  &&
            <div>
              <span className="duplicate duplicate_margin"><GrFormCheckmark   className="icone_duplicate"  onClick={(e) => this.onCardClick(e,kk,vv['Code LOINC'])} /></span>
              <span className="duplicate"><GrFormClose  className="icone_duplicate"   onClick={(e) => this.onCancel(e)} /></span>
            </div>


            }
            {this.props.basestatut==="validation expert" &&  this.props.duplicatecard["numero"]===kk && (this.props.loincData["goldclient"]==="" || this.props.loincData["goldclient"]!== vv['Code LOINC']) &&

            <div className="commun_map">


              <Interligne styles="center" label="" value = {vv['Composant']} />
              <Interligne styles="center" label="" value = {vv['Code LOINC']} />


              <hr></hr>
              {duplication.map( (val,key) => (
                <Interligne styles="alignement" label={val[0]} value = {
                  <Select className="center"
                    value = {this.props.select[val[1]].find(option => option.value ===  vv[val[2]]  )}
                    onChange={(e)=>this.findNewLOINC(e,val[3])}
                    options={this.props.select[val[1]]}
                  /> }
                 />
              ))
              }


              <Interligne styles="center" label="Circuit de biologie" value = "" />
              <Interligne styles="alignement" label=" Type" value = {this.props.duplicatecard["Type"]} />
              <Interligne styles="alignement" label="Circuit de biologie" value = {this.getTextIsRestreint(this.props.duplicatecard)}  />

            </div>

            }


            {((this.props.basestatut==="validation expert" &&  this.props.duplicatecard["numero"]!==kk) || (this.props.basestatut!=="validation expert") )  &&
                <LoincInfo
                  styles={this.getClassCards(vv['sélectionné'],vv['Code LOINC'], kk) }
                  onClick={(e)=>this.onCardClick(e,kk)}
                  data={vv}
                  restreint={vv}
                  loinc={vv['Code LOINC']}
                  composant={vv['Composant']}
                  basestatut={this.props.basestatut}
                  baseinfos={this.props.baseinfos}
                  tooltip={"nabm_"+kk}
                  onPanelClick={this.onPanelClick}
                  numero = {kk}
                  isVeille = {this.props.loincData["veille"] !== undefined}
                  newAttributs = {this.props.loincData["modif"]}
                  blockShowNewAttribut = {this.props.loincData["veille"] === undefined || this.props.loincData["veille"]["Code LOINC"] !== vv['Code LOINC']}
                />
              }



        </div>

        ))
      }


      {this.props.donottranscode && this.props.basestatut==="validation client" &&
        <div className="nocode">
          <img class="croix" src="croix_rouge.png" alt="Croix" />
          <p className="nocode_text">Ne pas transcoder</p>
        </div>
      }

      {/*(this.props.loincData["transcodable"]==="Non transcodable" && this.props.basestatut!=="audit" ) &&
        <div className={this.getNoCodeStyle()}>
          <img class="croix" src={this.getImg(this.props.loincData["transcodable"])} alt="Croix" />
          <p className="nocode_text">{this.props.loincData["transcodable"]}</p>
        </div>
      */}

        {this.props.basestatut==="validation expert"  &&
          <div className="addLoinc_container">
            <div className="addLoinc" onClick={() => this.showAdd()}>
            </div>
          </div>
        }

      </div>
    </div>
    );
  }

}

export default MultiMap;
