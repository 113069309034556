import React from 'react'
import './importfile.css'
import NavButton from "../../next.js"
import { GrFormTrash } from "react-icons/gr";
import Select from 'react-select'



class ImportFile extends React.Component  {
  constructor(props){
    super(props);
    this.state =  {
      fileInfos:{},
      inputKey:""
    }
    this.myRef = React.createRef()
   this.fileChange = this.fileChange.bind(this)
   this.fileref = React.createRef()
   this.selectref = React.createRef()

  }

  inputChange(e){
    const tmp = {...this.state.fileInfos}
    tmp.nom = e.target.value
    this.setState({fileInfos:tmp})
  }

  fileChange(event){
    const tmp = {...this.state.fileInfos}
    tmp.file = event.target.files[0]
    this.setState({fileInfos:tmp})
  }

  selectChange(e){
    const tmp = {...this.state.fileInfos}
    tmp.type = e.value
    this.setState({fileInfos:tmp})
  }


  sendFile(){

    this.props.sendFile(this.state.fileInfos)
    this.setState({fileInfos :{} })
    this.fileref.current.value = null;
    this.myRef.current.value = "";
    this.selectref.current.state.value = null

  }


  getError(type){
    let style = ""
    switch (type) {
      case 'input':
        style =   this.props.error.nom !== undefined && this.props.error.nom === true ? "select_input error_input" : "select_input"
        break
      case 'select':
        style =  this.props.error.type !== undefined && this.props.error.type === true ? "select_input error_select" : "select_input"
      break
      case 'file':
        style = this.props.error.file !== undefined && this.props.error.file === true ? "input_import error_file" : "input_import"
      break
      default:
      break
    }
    return style


  }

  render(){

    const typeUp = []
      typeUp.push({label : "Résultat",value : "Résultat"})
      typeUp.push({label : "Demande",value : "Demande"})
      typeUp.push({label : "Jointure",value : "Jointure"})

    const customStyles = {
      container: provided => ({
        ...provided,
        width: 200
      })
    }


    return(
      <div className="container_import_files">
        <table className="table_import_files">
          <thead>
            <tr>
              <th>Nom</th>
              <th>Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            { this.props.data!== undefined && this.props.data.listingbase.length>0  && this.props.data.listingbase.map( (v,k)=> (
              <tr>
                <td>{v["nom"]}</td>
                <td>{v["type"]}</td>
                <td>
                  <GrFormTrash className = "trash_import" onClick={()=> this.props.deleteFile(v["id"])}/>
                </td>
              </tr>
            ))}
            <tr>
              <td>
                <input
                  className={this.getError("input")}
                  type="text"
                  placeholder="Nom de la table"
                  onChange={(e)=>this.inputChange(e)}
                  ref = {this.myRef}
                  value = {this.state.fileInfos.nom}
                />
              </td>
              <td className = "td_select_import">
                <div className={this.getError("select")}>
                  <Select
                    options = {typeUp}
                    onChange = {(e) => this.selectChange(e) }
                    styles={customStyles}
                    value = {typeUp.find(option => option.value === this.state.fileInfos.type)}
                    ref = {   this.selectref }
                  />
                </div>
              </td>
              <td>
                <div className = "import_files_action">
                  <input
                    type="file"
                    className = {this.getError("file")}
                    onChange={this.fileChange}
                    accept=".csv"
                    ref = {this.fileref}
                  />
                  <NavButton isDefine={this.state.fileInfos.file !== undefined}  onClick={() => this.sendFile()} text="Téléverser" styles="upload_btn" />
                </div>
              </td>
            </tr>

          </tbody>
        </table>
      </div>
    )
  }

}

export default ImportFile
