import React from 'react'

import SelectAndTxt from '../../SelectAndTxt/SelectAndTxt.js'
import NavButton from "../../next.js"
import { Roller } from 'react-awesome-spinners'
import "../dictionnairetable/dictionnairetable.css"
import Interligne from "../../interligne/interligne.js"


class CheckDico extends React.Component {


      constructor(props){
        super(props);
        this.state = {
          dico:"",
          id:-1,
          type:""
        }
        this.selectChange = this.selectChange.bind(this)
      }

      selectChange(e,tag){
        this.setState({
          id:e.value,
          dico : e.value,
          type:e.type
        })
        this.props.readFinal(e.value)
      }

  validation(){
    this.props.doConfirmFile(this.state.id)
  }

  getToCheck(type){
    if (type === "") return []
    return type === "resultats" ? [
      "Colonne 'Onaos_Libellé milieu SGL' renseignée ( = pré-traitement)",
      "Colonne 'Code chapitre SGL' (avec libellé) renseignée ?",
      "Colonne 'Code demande' renseignée ?",
      "Colonne 'Onaos_Libellé demande' renseignée ?",
      "Colonne 'Onaos_Tube à prélever' renseignée ?",

    ]: [
      "Colonne 'Onaos_Milieu SGL' renseignée ?",
     "Colonne 'Onaos_Libellé unité SGL' renseignée ?",
     "Colonne 'Code chapitre SGL' (avec libellé) renseignée ?",
     "Colonne 'Onaos_Libellé analyse' renseignée ?"
    ]
  }


  render(){
    const selectData = this.props.data.dico!==undefined ?  this.props.data.dico.map( (v,k)=>({
      label : v["nom"] + " (" + v["type"]+")",
      value : v["id"],
      type : v["type"] === "Résultat" ? "resultats" : "demandes"

    })) : {label:"",value:"",type:""}

    const head =  this.props.alldata!== undefined &&  this.props.alldata.fileInfo.length>0 ? this.props.alldata.fileInfo[0] : []
    const body = this.props.alldata!== undefined &&  this.props.alldata.fileInfo.length>0 ? this.props.alldata.fileInfo.slice(1) : []
    const texte = this.props.alldata!== undefined &&  this.props.alldata.confirmFile ? "Validation en cours" : "Chargement en cours"
    const verif = this.getToCheck(this.state.type)

    return(
      <div className="container_modif">

        <SelectAndTxt
          label = { {name : "Dictionnaire à vérifier" ,txt : ""} }
          data  = {selectData}
          selectChange = {this.selectChange}
          tag = "verif"
          selectData = {this.state.dico}
          index = {400}
        />

        { this.props.fileRender &&
          <div className="container_render center">
            <p>{texte}</p>
            <Roller color="#f9af2e" />
          </div>
        }

        {this.props.showFile  &&
          <div className = "check_container">
            {this.props.showFile && this.state.type !== "chapitres" && verif.map((v,k)=> (
              <Interligne styles = "alignement" bold="" label = "" value = {v} />
            ))}
          </div>
        }

        <div className="dash_navigation">
          <NavButton isDefine={this.props.alldata.confirmFile}  onClick={()=>this.validation()} text="Valider le dictionnaire" styles="btn_modif_dico" />
        </div>

        {this.props.showFile && this.props.alldata.fileInfo.length>0 &&
          <React.Fragment>
          <div className="container_dicotable">
            <table className="dico_table">
            <thead>
              <tr>
                {  head.map( (v,k) => (
                  <th>{v}</th>
                ))}

              </tr>
            </thead>
            <tbody>
              {body.map( (v,k) => (
                  <tr>
                  {  v.map( (vv,kk) => (
                      <td>{vv}</td>
                    ) )}
                  </tr>
              ))}
            </tbody>
            </table>
          </div>


          </React.Fragment>
      }




      </div>
    )
  }

}


export default CheckDico
