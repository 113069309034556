import React from "react";
import './maintenance.css'


class Maintenance extends React.Component  {


  render(){
    return(
      <div className="container_maintenance">
        <p>Maintenance en cours : <span className="maintenance_msg">{this.props.msg}</span></p>
        <img className="maintenance" src = "maintenance.svg" alt="Maintenance"/>
      </div>
    )
  }

}


export default Maintenance
