import React from 'react'
import Select from "react-select";
import SelectAndTxt from '../../SelectAndTxt/SelectAndTxt.js'
import Dictionnairetablepre from "../dictionnairetable/dictionnairetablepre.js"
import NavButton from "../../next.js"
import { Roller } from 'react-awesome-spinners'
import Interligne from "../../interligne/interligne.js"



class TraitementAuto extends React.Component  {


    constructor(props){
      super(props);
      this.state = {
        basetraitement:"",
        typetraitement:"",
        block:false,
        typebase:"",
        showPanel:true,
        pageNow:1,

      }
      this.selectChange = this.selectChange.bind(this)
      this.onPageChange = this.onPageChange.bind(this)
    }


    onPageChange(e){
      this.setState({pageNow : e.value})
    }

    selectChange(e,tag){
      this.props.resetDashPret()

      this.setState({
        [tag] : e.value,
        typebase : e.type !== undefined ? e.type : this.state.typebase
      })
    }

    validation(){
      const action = this.props.preTraitement.confirmFile ? "validation" : "read"
      this.props.doPreTraitement(this.state.basetraitement,this.state.typetraitement,action,this.state.typebase)
      this.setState({showPanel: action === "read" ? false : true })
    }

    reset(){
      if (window.confirm("Cette action va recharger le mapping en supprimant toutes les modifications manuelles que vous avez faites, confirmer ?")) {
        this.props.doPreTraitement(this.state.basetraitement,this.state.typetraitement,"reset",this.state.typebase)
        this.setState({showPanel:  false  })
      }
        
    }

    reload(){
      this.props.doPreTraitement(this.state.basetraitement,this.state.typetraitement,"reload",this.state.typebase)
      this.setState({showPanel:  false  })

    }

    cancel(){
      this.setState({showPanel:  true  })

    }


    getText(){
      const total = this.props.preTraitement.data.length -1
      return "Nombre d'entrées : "+total+" trouvées/non trouvées : "+Number(total-this.props.preTraitement.empty)+"/"+this.props.preTraitement.empty
    }

  getNbrPage(data){
    const max= data.length/100 
      let all = []
      for (let i = 0; i<max; i++){ 
          all.push({
              value : i+1,
              label : i+1
          })
        }
        return all
  }


  suivant(){
    if (this.state.pageNow<this.props.preTraitement.data.length/100) this.setState({pageNow:Number(this.state.pageNow)+1, showPanel:  false })

  }

  precedent(){
    if (this.state.pageNow>1) this.setState({pageNow:Number(this.state.pageNow)-1,  showPanel:  false})
  }

  render(){

    const selectData = this.props.data.dico!==undefined ?  this.props.data.dico.map( (v,k)=>({
      label : v["nom"] + " (" + v["type"]+")",
      value : v["id"],
      type : v["type"] === "Résultat" ? "resultats" : "demandes"
    })) : {label:"",value:"",type:""}

    let typePretraitement = []
    typePretraitement.push({label:"Milieu",value:"milieu"})
    typePretraitement.push({label:"Ratio",value:"ratio",disabled:true})
    // typePretraitement.push({label:"Epreuve",value:"epreuve",disabled:true})
    typePretraitement.push({label:"Grandeur",value:"grandeur",disabled:true})
    typePretraitement.push({label:"PCR",value:"arn",disabled:true})
    // typePretraitement.push({label:"Anticorps",value:"ac",disabled:true})
    typePretraitement.push({label:"Antigène",value:"ag",disabled:true})

    const nbrPage = this.getNbrPage(this.props.preTraitement.data.slice(1))

    return(
      <div className="container_modif">
        {this.state.showPanel && 
        <React.Fragment>
          <SelectAndTxt
            label = { {name : "Sélectionnez le dictionnaire à traiter" ,txt : ""} }
            data  = {selectData}
            selectChange = {this.selectChange}
            tag = "basetraitement"
            selectData = {this.state.basetraitement}
            index = {400}
          />

          <SelectAndTxt
            label = { {name : "Sélectionnez le traitement à réaliser" ,txt : ""} }
            data  = {typePretraitement}
            selectChange = {this.selectChange}
            tag = "typetraitement"
            selectData = {this.state.typetraitement}
            index = {300}
          />
        </React.Fragment>
        }


        {this.props.preTraitement !== undefined && this.props.preTraitement.render &&
          <div className="container_render center">
            <p>Pre-traitement en cours</p>
            <Roller color="#f9af2e" />
          </div>
        }
        <div className="dash_nav">
          {this.props.preTraitement.show && 
          <div className='dash_nav_page'>
              <NavButton isDefine={true}   onClick={()=>this.precedent()} text="Précédent" styles="btn_modif_dico" />

              <div className ="container_select_pdf">
                  <p className='p_pdf'> Page : </p>
              <Select  className="select_pdf"
                  value = {nbrPage.find(option => option.value === this.state.pageNow)}
                  onChange={this.onPageChange}
                  options={nbrPage}
              />
              </div>
              <NavButton isDefine={true} onClick={()=>this.suivant()} text="Suivant" styles="btn_modif_dico" />
               
          </div>
          }
          <div className='dash_nav_page'>
            <NavButton isDefine={true}  onClick={()=>this.validation()} text={this.props.preTraitement.confirmFile ? "Confirmer" : "Valider"} styles={this.state.showPanel ? "": "btn_modif_dico"} />
            <NavButton isDefine={this.props.preTraitement.confirmFile}  onClick={()=>this.reload()} text="Recharger" styles={this.state.showPanel ? "": "btn_modif_dico"} />
            <NavButton isDefine={true}  onClick={()=>this.reset()} text="Reset" styles={this.state.showPanel ? "": "btn_modif_dico"} />
            <NavButton isDefine={!this.state.showPanel}  onClick={()=>this.cancel()} text="Interface" styles="btn_modif_dico" />
          </div>
        </div>

        {this.props.preTraitement.show && !this.props.preTraitement.render && this.props.preTraitement.data !== undefined &&

          <div classname = "container_pretraitement_all">
            <div className = "container_stat">
              <Interligne styles = "center"  label = "" value = {this.getText()} />
            </div>
            <Dictionnairetablepre
              data = {this.props.preTraitement.data.slice(1)}
              attributs = {this.props.preTraitement.attributs}
              onSelectExpert = {this.props.onSelectExpert}
              head = {this.props.preTraitement.data[0]}
              deleteMedium = {this.props.deleteMedium}
              addMedium = {this.props.addMedium}
              type = {this.state.typebase}
              onchecked = {this.props.onchecked}
              isChecked = {this.props.isChecked}
              allChecked = {this.props.allChecked}
              nbrPage = {nbrPage}
              pageNow = {this.state.pageNow}

            />
          </div>



       }





      </div>
    )
  }
}


export default TraitementAuto
