import React from 'react';
import './sidebaritem.css';

class SideBarItem extends React.Component {
  

  getChecked(title,item){
    let result=false
    if (this.props.checked!==undefined && this.props.checked[title]!==undefined && this.props.checked[title][item]!==undefined){
      result=this.props.checked[title][item]
    } else {
      result=false
    }
    return result
  }

  getLabel(data){
    let result = data 
    if (data === "Both") result = "Résultat + Demande"
    if (data === "Observation") result = "Résultat"
    if (data === "Order") result = "Demande"
    return result 
  }

  render(){
    return(
      <div>
      { this.props.show!==undefined && this.props.show===true &&
        <div className="dropdown_filter">
          <ul>
          { Object.keys(this.props.data).map( (v,k)=> (

            <li >
              <input id={this.props.title+"_"+v} type="checkbox"
                onChange={(e)=>this.props.onClick(this.props.title,v,e)}
                checked={this.getChecked(this.props.title,v)}
                />
                <label className="text_filter" htmlFor={this.props.title+"_"+v}>{this.getLabel(v)}</label><span className="filter_value">{ this.props.data[v]}</span>
            </li>
          ))}
          </ul>

        </div>

      }
      </div>

    )
  }

}


export default SideBarItem;
