import React from 'react'
import ReactTooltip from 'react-tooltip'



export const CadreVeille = (props) => {

    const idTooltip = "veille_" + Math.random().toString(36)

    const style = {
        borderRadius: "5px",
        minHeight: "35px",
        backgroundColor : "rgba(126, 54, 235, 0.36)",
        display : 'flex',
        alignItems : 'center',
        ...props.style
    }

    return(
        <React.Fragment>
            {props.renderVeille &&
                <div  data-tip data-for = {idTooltip} style = {style}>
                    {props.children}
                    <ReactTooltip id = {idTooltip} type = "info" effect = "solid">
                        Modification liée à la mise à jour LOINC
                    </ReactTooltip>
                </div>
            } 
            {
               !props.renderVeille && props.children
            }
        </React.Fragment>
        
    )

}