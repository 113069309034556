import React from 'react';
import InputField from './InputField';
import SubmitButton from './SubmitButton';
import Logo from "../img/logo_onaos.png";

import {apiLogin} from '../api/api.js';

class LoginForm extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            username: '',
            password: '',
            buttonDisabled: false,
            token:'',
            tokenrefresh:'',
            isLoggedIn:false,
            logerror:false
        }
        this.doLogin = this.doLogin.bind(this)
    }
    setInputValue(property, val){
        val = val.trim();
        this.setState({
            [property]: val
        })
    }
    resetForm(){
        this.setState({
            username: '',
            password: '',
            buttonDisabled: false,
            isLoggedIn:false,
            token:false,
            tokenrefresh:false,
            logerror:false
        });
        localStorage.clear();
    }

    componentDidMount(){
      localStorage.setItem("token","")
    }

    doCPS(){
      const array = new Uint32Array(10);
      const nonce = window.crypto.getRandomValues(array)[0];
      const array2 = new Uint32Array(10);
      const state = window.crypto.getRandomValues(array2)[0];
      const url = "https://wallet.bas.esw.esante.gouv.fr/auth/?response_type=code&client_id=onaos-loinc-mapper-bas&redirect_uri=https://devmapper.yoann.onaos.com&scope=openid scope_all&state="+state+"&nonce="+nonce+"&acr_values=eidas2"
      localStorage.setItem("state",state)
      localStorage.setItem("nonce",nonce)
      window.location.href=url
    }

    doLogin(){
      if (!this.state.username){
          this.setState({logerror:true});
          return;
      }
      if (!this.state.password){
          this.setState({logerror:true});
          return;
      }
      apiLogin(this.state.username,this.state.password).then(data =>{
        if (data.status===undefined) {
          this.setState({logerror:false});
          this.result(data);
        } else {
            this.setState({logerror:true});
        }
      })
    }


    result(data){
      this.props.afterlog(data);
    }


    render(){

       return(
          <div className="loginForm">
          <img className="logo_onaos" src={Logo} alt="Logo Onaos"/>
            <div className="input_size_login">
              <InputField
                  type='text'
                  placeholder='Identifiant'
                  defaultValue={this.state.username ? this.state.username : ''}
                  onChange={ (val) => this.setInputValue('username', val) }
                  onKeyPress={this.doLogin}

              />
              <InputField
                  type='password'
                  placeholder='Mot de passe'
                  defaultValue={this.state.password ? this.state.password : ''}
                  onChange={ (val) => this.setInputValue('password', val) }
                  onKeyPress={this.doLogin}

              />
            </div>
              { this.state.logerror===true &&
                <p className="errorLog">Login ou mot de passe incorrect</p>
              }
              { true===false &&
                <div className="btns_connexion">
                  <SubmitButton
                     text='Connexion'
                     onClick={ () => this.doLogin() }
                  />
                  <SubmitButton
                     text='e-CPS/CPS'
                     onClick={ () => this.doCPS() }
                  />
                </div>
              }
              <div className="container_forgot_mdp">
                <a href="?recup=email" className="forgot_mdp">Mot de passe oublié ?</a>
              </div>
              <SubmitButton
                 text='Connexion'
                 onClick={ () => this.doLogin() }
              />

          </div>
      );
    }
}

export default LoginForm;
