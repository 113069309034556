import React from 'react'
import "./inputaccount.css"
import Selectcomm from "../../modal/selectcomm.js"


class InputMultiple extends React.Component  {

  constructor(props){
      super(props);
      this.state = {
        show:false
      }
      this.onCreateOption = this.onCreateOption.bind(this)
      this.onChange = this.onChange.bind(this)
    }

    onChange(e){
      this.props.onChange(e,"S.I.L.","logiciel")
    }

    onCreateOption(value,add = true){
      const e = {value:value}
      this.props.onCreateOption(value,add)
      this.props.onChange(e,"S.I.L.","logiciel")


    }

  getError(data){
    if (this.props.error!==false){
      return this.props.error.labo.error[this.props.data[0]]!==undefined && this.props.error.labo.error[this.props.data[0]][data]!==undefined && this.props.error.labo.error[this.props.data[0]][data]===true ? "account_input_multiple input_error": "account_input_multiple"
    }
    return "account_input_multiple"
  }


  render(){
    const width_min = 100 / Object.entries(this.props.data[1]).length - 5 +"%"

    return (
      <div className="account_input_multiple_container">

      { this.props.data[0] !== "logiciel" && Object.entries(this.props.data[1]).map( (v,k)=>(
        <input
          className={this.getError(v[0])}
          style={{width: width_min}}
          type="text"
          defaultValue={v[1]}
          placeholder={v[0]}
          value={v[1]}
          onChange={(e)=>this.props.onChange(e,v[0],this.props.data[0])}
          disabled = {true}
        />
      ))
      }
      { this.props.data[0] === "logiciel" &&
        <React.Fragment>
          <Selectcomm
           onCreateOption = {this.onCreateOption}
           onChange = {this.onChange}
           defaultValue = {this.props.data[1][Object.keys(this.props.data[1])[0]]}
           type = "sil"
           style={{width: width_min}}
           expertallreco = {this.props.allSIL}
           classname = "account_select"
           placeholder = "S.I.L."
          />
          <input
            className="account_input_multiple"
            style={{width: width_min}}
            type="text"
            defaultValue={this.props.data[1][Object.keys(this.props.data[1])[1]]}
            placeholder={Object.keys(this.props.data[1])[1]}
            value={this.props.data[1][Object.keys(this.props.data[1])[1]]}
            onChange={(e)=>this.props.onChange(e,Object.keys(this.props.data[1])[1],this.props.data[0])}
          />
        </React.Fragment>

      }

      </div>
    )
  }
}


export default InputMultiple;
