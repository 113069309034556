import React from "react";
import '../clientmap/clientmap.css';
import Interligne from "../interligne/interligne.js";
import {Collapse} from 'react-collapse';

import { GrFormDown,GrFormUp } from 'react-icons/gr';
import ReactTooltip from 'react-tooltip';


class ClientEntry extends React.Component {

  constructor(props){
    super(props);
    this.state={
      showRes:false,
      showOnaos:false,
      showMatrice:this.props.title==="Locale" ? false : true,
    }
  }

  componentDidUpdate(prevpros,prevstate){
    if (this.props.data["Code examen SGL"] !== undefined && this.props.data["Code examen SGL"] !== prevpros.data["Code examen SGL"] )   this.setState({showRes:false})
    if (this.props.data["Code SGL analyse résultat"] !== undefined && this.props.data["Code SGL analyse résultat"] !== prevpros.data["Code SGL analyse résultat"] )   this.setState({showRes:false})

  }

  collapse(tag){
    this.setState({[tag]:!this.state[tag]})
  }

  getText(){
    let result=""
    if (this.props.typebase !== undefined){
      if (this.props.typebase === "resultats"){
        return "Demande liée à ce résultat"

      }
      if (this.props.typebase === "demandes"){
        return "Résultats liés à cette demande"
      }
    }

    return result
  }

  nabmClick(code){
    if (this.props.nabmClick !==undefined && code !==""){
      this.props.nabmClick(code)
    }
  }

  render(){
    const container = (this.props.styles!==undefined) ? this.props.styles : ""
    const styleline = (this.props.stylesLine!==undefined) ? this.props.stylesLine : "sizing_entree"
    const textbase = this.getText()
    const NABMCode =  this.props.data["Codes NABM"] !== undefined &&  this.props.data["Codes NABM"]["code"] !== undefined && this.props.data["Codes NABM"]["code"] !== "" ? this.props.data["Codes NABM"]["code"] : ""
    const NABMLabel = this.props.data["Codes NABM"] !== undefined &&  this.props.data["Codes NABM"]["label"] !== undefined && this.props.data["Codes NABM"]["label"] !== "" ? this.props.data["Codes NABM"]["label"] : ""
    const Matrice = this.props.data["Matrice"] !== undefined ? this.props.data["Matrice"].split('|') : ""
    const stylelineValidation = this.props.stateBaseData !== undefined && this.props.stateBaseData === "traité" ? "green_validation" : ""
    const stylelinePass = this.props.stateBaseData !== undefined && this.props.stateBaseData === "passé" ? "yellow_pass" : ""

    return(
      <div className={container}>
        {this.props.title!==undefined && <h1 className="title_client_search center">{this.props.title}</h1>}
        
        { this.props.stateBaseData !== undefined && this.props.stateBaseData === "traité" &&
          <Interligne styles = {styleline + " " + stylelineValidation } label = "" value = {"Entrée déjà validée"} />
        }

        { this.props.stateBaseData !== undefined && this.props.stateBaseData === "passé" &&
          <Interligne styles = {styleline + " " + stylelinePass } label = "" value = {"Entrée passée"} />
        }

        {Object.entries(this.props.data).filter(e => e[1] !== "" && e[0] !== "Codes NABM" && e[0] !== "Tube à prélever" &&  e[0] !== "Matrice" &&  e[0].indexOf("Onaos_") === -1 &&  !Array.isArray(e[1]) ).map((vv,kk)=> (
         // <Interligne styles = {styleline} label = {vv[0]} value = {vv[1].length > 35 ? vv[1].substring(0,35)+"..." : vv[1]}  tooltip = {vv[1].length > 35 ? vv[0]+"_cliententry" : undefined} tooltipText = { vv[1].length > 35 ? vv[1] : undefined } />
         <Interligne styles = {styleline} label = {vv[0]} value = {vv[1]} />
      
        ))}
      {this.props.title!=="Locale" && NABMCode !== "" &&
        <Interligne styles = {styleline} label = "Codes NABM" value = {NABMCode} tooltip ="NABM_label" tooltipText = {NABMLabel} />
      }

      {this.props.title==="Locale" && NABMCode !== "" &&
        <p className="interligne sizing_entree_search search_nabm" data-tip data-for="NABM_search" onClick={()=>this.nabmClick(NABMCode)}>Codes NABM : <span className="characters">{NABMCode}</span> </p>
      }

      <ReactTooltip id="NABM_search" type="info" effect="solid" html={true}>
        {NABMLabel+"<br />"}
        (Cliquer pour faire une recherche sur ce code NABM)
      </ReactTooltip>


      {  Matrice !==  "" &&
      <React.Fragment>
        <p className = {styleline + " no_top"} onClick={()=> this.collapse("showMatrice")}>Matrice :<span> {this.state.showMatrice ? <GrFormUp  /> : <GrFormDown  />}</span></p>
       
        <Collapse  isOpened={this.state.showMatrice} >
          <div className="entry_collapse_div">
            {Matrice.map((v,k)=>(
              <Interligne styles = {styleline} label = "" value = {v} />
            )) }
          </div>
        </Collapse>
      </React.Fragment>
      

      }

      

      {Object.entries(this.props.data).filter(e => e[1] !== "" && e[0].indexOf("Onaos_") !== -1).length>0 && 
        <p className = {styleline+ " no_top"} onClick={()=> this.collapse("showOnaos")}>Onaos tag<span> {this.state.showOnaos ? <GrFormUp  /> : <GrFormDown  />}</span></p>
      }

      <Collapse  isOpened={this.state.showOnaos} >
      <div className="entry_collapse_div">
      {Object.entries(this.props.data).filter(e => e[1] !== "" && e[0] !== "Codes NABM" && e[0].indexOf("Onaos_") !==-1  &&  !Array.isArray(e[1]) ).map((vv,kk)=> (
          <Interligne styles = {styleline} label = {vv[0]} value = {vv[1]} />
        ))}
      </div>

      </Collapse>

      {this.props.data.linkresult !== undefined && this.props.data.linkresult.length>0 &&
        <React.Fragment>
          <hr></hr>
          <div className="container_client_res_updown">
            <p className = {styleline} onClick={()=> this.collapse("showRes")}>{textbase}<span> {this.state.showExpert ? <GrFormUp  /> : <GrFormDown  />}</span></p>
          </div>
        </React.Fragment>
      }
      <Collapse  isOpened={this.state.showRes} >
      <div className="entry_collapse_div">
        {this.props.data.linkresult !== undefined && this.props.data.linkresult.map((v,k) => (
            <Interligne styles = {styleline} label = "" value = {v["lib"]} />
        ))
        }
      </div>

      </Collapse>


      {this.props.testaudit["goldnotation"]!==undefined &&
        <div>
      <hr></hr>
      <Interligne styles = "sizing_entree"  label = "audit notation" value = {this.props.testaudit["goldnotation"]} />
      <Interligne styles = "sizing_entree"  label = "audit position" value = {this.props.testaudit["goldlvl"]} />
      <Interligne styles = "sizing_entree"  label = "gold name" value = {this.props.testaudit["testauditchoix"]} />
      <hr></hr>
      <Interligne styles = "sizing_entree"  label = "Gold find" value = {this.props.testaudit["goldfind"]} />


      </div>
      }
      </div>
    )
  }
}

export default ClientEntry;
