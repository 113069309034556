import Select from "react-select";
import React from "react";
import './dictionnairetable.css'
import Addorremove from '../../addorremove/addorremove.js'



class Dictionnairetablepre extends  React.Component  {
  constructor(props) {
   super(props);
   this.state = {
      start : 0,
      stop : 100,
      items: 100,
      loadingState: false,
      data: []
    };
     this.iScroll = React.createRef();

  }


  componentDidMount() {
   /* this.iScroll.current.addEventListener("scroll", () => {
      if (this.iScroll.current.scrollTop + this.iScroll.current.clientHeight === this.iScroll.current.scrollHeight){
        this.loadMoreItems();
      }
      if (this.iScroll.current.scrollTop === 0){
        this.loadLessItems();
      }

    });*/
    this.setState({data : this.props.data.slice(this.state.start,this.state.stop)})
  }


  componentDidUpdate(prevProps,prevState){
    if (prevProps.pageNow !== this.props.pageNow){
      const stop = this.props.pageNow*100
      const start =  (this.props.pageNow-1)*100
      const data  = this.props.data.slice(start,stop)
      this.setState({start :start,stop:stop, loadingState: false,data:data});
    }
  }

  loadLessItems() {

    if (this.state.loadingState) return
    this.setState({ loadingState: true });

    if (this.state.start <100   ) {
       const data  = this.props.data.slice(0,100)

      this.setState({ loadingState: false,data:data,start:0,stop:100 });
    } else {
      const stop = this.state.stop-30
      const start =  this.state.start-30
      const data  = this.props.data.slice(start,stop)
      this.setState({start :start,stop:stop, loadingState: false,data:data },
        ()=>{ this.iScroll.current.scrollTop =  30*(this.iScroll.current.scrollHeight )/100
      });

    }


  }


  loadMoreItems() {
    if (this.state.loadingState) return
    this.setState({ loadingState: true });
    if (this.state.stop +30 > this.props.data.length ) {
       const data  = this.props.data.slice(this.props.data.length -100)
      this.setState({ loadingState: false,data:data });
    } else {
      const start =  this.state.stop-(100-30)
      const stop = this.state.stop+30
      const data  = this.props.data.slice(start,stop)
      this.setState({start : start,stop:stop, loadingState: false,data:data },()=>{  this.iScroll.current.scrollTop = 65*(this.iScroll.current.scrollHeight )/100});

    }



  }
  checkboxChange(e, number){
    this.props.onchecked(e,number)
  }

  render() {

    const customStyles = {
      container: provided => ({
        ...provided,
        width: 150
      })
    }

    return (
      <div className="container_dicotable_infinite" ref={ this.iScroll}  >


      <table className="dico_table_sticky">
        <thead>
          <tr>
            <th className="sitcky">
              id
              <input 
                  type="checkbox"
                  onChange={(e)=>this.checkboxChange(e,-1)}
                  checked = {this.props.allChecked}                
              />
              </th>

            {  this.props.head.filter( e => e !== "id").map( (v,k) => (
              <th  className="sitcky">{v}</th>

            ))}
            <th  className="sitcky index_scroll">Modifier</th>
          </tr>
        </thead>
        <tbody>
        {this.state.data !==undefined && this.state.data.map( (v,k)=> (
            <tr>
              <td>
                {this.state.start+k}
                <input 
                  type="checkbox"
                  onChange={(e)=>this.checkboxChange(e,k+this.state.start)}
                  checked = {this.props.isChecked[k+this.state.start] !== undefined  && this.props.isChecked[k+this.state.start]  ? true : false }
                />
              </td>
              {Object.entries(v).filter(d => d[0] !== "id" ).map((vv,kk)=>(    
                 <td>{vv[0] !== "Pretraitement" ? vv[1] : vv[1].join(' | ')}</td>
              )) }
              <td>
                {Object.entries(v)[1][1] !== "" &&
                  <div className="container_select_pre">
                    {
                      v["Pretraitement"]!==undefined &&  v["Pretraitement"].map( (testv,testk) => (
                        <div className="select_pre" >
                          <Select
                            options = {this.props.attributs}
                            value = { this.props.attributs.find(option => option.value === testv)}
                            styles={customStyles}
                            onChange = {(e)=> this.props.onSelectExpert(this.props.type,e,k+this.state.start,testk)}
                          />
                          <Addorremove
                            type = "delete"
                            action = {this.props.deleteMedium}
                            actionIndex = {k+this.state.start}
                            actionKey = {testk}
                            typebase = {this.props.type}
                          />
                        </div> 
                      ))
                    }
                  </div>
                }
                { Object.entries(v)[1][1] !== "" && v["Pretraitement"]!==undefined && 
                  v["Pretraitement"][v["Pretraitement"].length-1] !== "N/A" && v["Pretraitement"][v["Pretraitement"].length-1] !== "" &&
                    <div className="select_add">
                      <Addorremove
                        type = "add"
                        action = {this.props.addMedium}
                        actionIndex = {k+this.state.start}
                      />
                    </div>
                }
              </td>

            </tr>
        )

        )  }
        </tbody>
      </table>
       <div style={{height:200}}>
       </div>
      </div>
    )
  }
}

export default Dictionnairetablepre
