import React from 'react'
import './KeyBoard.css'
import NavButton from '../next'

import LeftIcon from "../../img/shortcut/left.png";
import RightIcon from "../../img/shortcut/right.png";
import ValIcon from "../../img/shortcut/v.png";
import PassIcon from "../../img/shortcut/p.png";
import RejIcon from "../../img/shortcut/r.png";


class KeyBoard extends React.Component {

    render(){
        return(
            <div className='keyboard_main'>
               <div className='keyboard_container'>
               <div className='shortcut_line'>
                   <h4 className='shortcut_title'>Raccourcis clavier</h4>
                </div>
                   <div className='shortcut_line'>
                       <div className='shortcut_img_lbl'>
                            <img className="shortcut_img" src={LeftIcon} alt = "Flèche gauche"/>
                            <p>Retourner sur l'entrée précédente</p>
                       </div>
                        
                    </div>
                    <div className='shortcut_line'>
                        <div className='shortcut_img_lbl'>
                            <img className="shortcut_img" src={RightIcon}  alt = "Flèche droite"/>
                            <p>Aller à l'entrée suivante</p>
                        </div>
                    </div>
                    <div className='shortcut_line'>
                        <div className='shortcut_img_lbl'>
                            <img className="shortcut_img" src={ValIcon}  alt = "Touche V"/>
                            <p>Valider l'entrée courante</p>
                        </div>
                    </div>
                    <div className='shortcut_line'>
                        <div className='shortcut_img_lbl'>
                            <img className="shortcut_img" src={PassIcon}  alt = "Touche P"/>
                            <p>Passer l'entrée courante</p>
                        </div>
                    </div>
                    {this.props.baseStatut === "validation client" &&
                    <div className='shortcut_line'>
                        <div className='shortcut_img_lbl'>
                            <img className="shortcut_img" src={RejIcon}  alt = "Touche R"/>
                            <p>Refuser l'entrée courante</p>
                        </div>
                    </div>
                    }
                    <div className='shortcut_line'>
                    <div className='shortcut_bouton_container'>
                        <NavButton
                            isDefine={true}
                            onClick={()=>this.props.close(false)}
                            text="Fermer"
                            styles="shortcut"
                        />
                        </div>
                   </div>
               </div>
            </div>
        )
    }


}


export default KeyBoard