import React from 'react'
import './AccountValidation.css'

class AccountValidation extends React.Component  {

    render(){
        return(
            <div className="containerSideValidation">
                <div className="leftsideValidation">
                    <img src="comptelabo.svg" alt="Compte" className="imgAccountValidation"/>
                </div>
                <div className="rightsideValidation">
                    <img src="img_validation.svg" alt="Compte"/>
                    <h1 className="font_color">Compte laboratoire créé !</h1>
                    <p className="rsvtxt font_color">Un email a été envoyé aux différents biologistes experts renseignés afin qu’ils puissent créer un compte d’accès à notre plateforme</p>
                    <div className="container_return_validation font_color">
                        <div className="return_button_validation" onClick = {()=> { window.location.href = "/" }}></div>
                        <p>Revenir à la connexion !</p>
                    </div>
                </div>
            </div>
        )
    }

}

export default AccountValidation;
