import './Search.css';
import React from 'react';

class Search extends React.Component {

  constructor(props) {
     super(props);
     this.state={
       comm:'',
       keyboard:false
     }
     this.inputChang = this.inputChang.bind(this);
     this.handleKeyPress = this.handleKeyPress.bind(this)

   }

  inputChang(e) {
    this.setState({comm: e.target.value});
  }



  reg() {
    this.props.onRegister(this.state.comm);
    this.setState({"comm":""});
    this.form.reset();
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.reg();
    }
  }

  cancel(){
    this.setState({"comm":""});
    this.props.cancel();
  }





  render(){
    const showHideClassName = this.props.show ? "modal display-block" : "modal display-none"
    const texte = "Ajout d'un code LOINC"

    return (

      <div className={showHideClassName}>
        <section className="modal-main">
          <form className="modal-position" ref={form => this.form = form}>
            <label className="modal-label">
              {texte}
              <input
                ref={(ref) => this.mainInput= ref}
                type="text"
                onChange={this.inputChang}
                onKeyPress={this.handleKeyPress}
              />
            </label>
            <div className="modal-btn">
              <button type="button" onClick={()=>this.reg()}>Valider</button>
              <button type="button" onClick={()=>this.cancel()}>Annuler</button>
            </div>
          </form>
        </section>

      </div>
    );
  }
};

export default Search;
