import React from 'react';
import './SearchResult.css';
import LoincTable from '../loinctable/loinctable.js'
import LoincResultHead from '../loincresulthead/loincresulthead.js'


class SearchResult extends React.Component {


  render(){
    const total = this.props.data["result"]!==undefined  ? this.props.data["result"].length : 0
    return(

      <div className="container_propositions_map_search">
        <LoincResultHead
          total = {total}
          resultPageNumber = {this.props.resultPageNumber}
          resultPageData = {this.props.resultPageData}
          onPageChange = {this.props.onPageChange}
          showBackPanel = {this.props.showBackPanel}
          backSearch = {this.props.backSearch}


        />

      { this.props.data["result"]!==undefined  && this.props.data["result"].length>0
      && 
          <LoincTable
            data={this.props.data["result"]}
            onClick={this.props.onclick}
            select={this.props.selectData}
            addFilter = {this.props.addFilter}
            dropdownOrder = {this.props.dropdownOrder}
            resultPageNumber = {this.props.resultPageNumber}
            basetype = {this.props.basestatut}
            findPanel = {this.props.findPanel}
            findAssos = {this.props.findAssos}
            attributs = {this.props.attributs}
            isPanel = {this.props.data["type"] === "panel"}

          />
      }



      {this.props.data["result"]!==undefined  && this.props.data["result"].length>0
      &&  
      <LoincResultHead
          total = {total}
          resultPageNumber = {this.props.resultPageNumber}
          resultPageData = {this.props.resultPageData}
          onPageChange = {this.props.onPageChange}
          showBackPanel = {this.props.showBackPanel}
          backSearch = {this.props.backSearch}


        />
      }
        

      </div>
    );
  }

}

export default SearchResult;
