import React from 'react'
import Logo from "../../img/logo_onaos.png";
import RadioQuestionnaire from "./radioquestionnaire/RadioQuestionnaire.js"
import TextInputQuestionnaire from './TextInputQuestionnaire/TextInputQuestionnaire.js'
import QuestionnaireNav from './QuestionnaireNav/QuestionnaireNav.js'

import './main.css'
import NavButton from '../next';




class MainQuestion extends React.Component {

    constructor(props) {
        super(props);
        this.state={
          value:"",
          textValue:"",
          position:-1,
          error:false
        }
        this.onChangeValue = this.onChangeValue.bind(this);
        this.onValidation = this.onValidation.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
        this.previous = this.previous.bind(this)

      }  


    onChangeValue(value, position){
        this.setState({
            value : value,
            position : position,
            error:false
        })
    }

    onChangeText(text){
        this.setState({
            textValue : text
        })
    }

    previous(){
        this.props.previous();
        this.setState({error:false})
    }

    onValidation(){
        const val = this.state.value === "" ? this.props.dataResponse["réponse"] : this.state.value
        const position  = this.state.position === -1 || this.state.position === "" ? this.props.dataResponse["réponse valeur"] : this.state.position
        const text = this.state.textValue === "" && this.props.dataResponse !== undefined && this.props.dataResponse["texte libre"] !== "" ? this.props.dataResponse["texte libre"] : this.state.textValue
        if (val === "" || val === undefined) {
            window.alert("Réponse incomplète , merci de répondre à la question posée")
            this.setState({
                error:true
            })
        } else {
            this.props.questionValidation(val, position, text)
            this.setState({
                textValue:"",
                value:"",
                position : -1,
                error:false
            })
        }

    }

    render(){
        const textFree = this.props.dataResponse !== undefined && this.props.dataResponse["texte libre"] !== undefined ? this.props.dataResponse["texte libre"] : this.state.textValue
        return(
            <div className="container_main_question">
                <div className="question_leftside">
                    <img src="questionnaire.svg" alt="Questionnaire" className="question_img"/>
                </div>
                <div className="container_question">
                    <div className="base_type_end logo_questionnaire">
                        <img className="logo_onaos_mapping" src={Logo} alt="Logo Onaos"/>
                    </div>
                    {this.props.data.position !== 0 && this.props.data.position !== "end" &&                 
                        <div className="question_rightside">

                            <p className='questionnaire'>{this.props.data.texte}</p>
                            {this.props.data.reponse.Type === "radio" &&   
                                <RadioQuestionnaire 
                                    nombre = {this.props.data.reponse["nombre de niveau"]} 
                                    textes={this.props.data.reponse.texte} 
                                    onChangeValue = {this.onChangeValue}
                                    response = {this.props.dataResponse}
                                    valeur = {this.state.value}
                                    error = {this.state.error}

                                />
                            }
                            {this.props.data["texte libre"] === "oui" && 
                                <TextInputQuestionnaire 
                                    onChangeText = {this.onChangeText}
                                    textValue = {textFree}
                                    valeur = {this.state.textValue}
                                />
                            }
                            <QuestionnaireNav 
                                position = {this.props.data.position} 
                                total={this.props.data.total} 
                                validation = {this.onValidation}
                                previous = {this.previous}
                            />
                        </div>
                    }
                    {this.props.data.position === 0 &&
                        <div className="question_rightside">
                            <p className='questionnaire questionnaire_page'>Félicitations, vous venez de terminer la vérification de votre dernier dictionnaire.</p> 
                            <p className='questionnaire questionnaire_page'>Avant de télécharger votre fichier transcodé, merci de prendre quelques instants pour compléter un rapide questionnaire ({this.props.data.total} questions) qui nous permettra, le cas échéant, d'améliorer la qualité de notre service.</p>
                            <NavButton                 
                                isDefine={true}
                                text="Commencer"
                                styles="btn_questionnaire"
                                onClick={()=>this.props.start()}
                            />
                        </div>
                    }     

                    {this.props.data.position === "end" &&
                        <div className="question_rightside">
                            <p className='questionnaire questionnaire_page'>Merci pour vos réponses, vous pouvez désormais accéder à la page de téléchargement de vos livrables</p>
                            <NavButton                 
                                isDefine={true}
                                text="Accéder"
                                styles="btn_questionnaire"
                                onClick={()=>this.props.end()}
                            />
                        </div>
                    }                       


                </div>
            </div>

        )   
    }


}

export default MainQuestion