import React from 'react';
import './sidebar.css';
import SideBarTitle from './sidebartitle/sidebartitle.js';

import SideBarItem from './sidebaritem/sidebaritem.js';



class SideBar extends React.Component {
    constructor(props){
      super(props);
      this.state = {
        dropdown:[]
      }
      this.handleButtonClick = this.handleButtonClick.bind(this)
      this.handleCheck = this.handleCheck.bind(this)
    }


   /* componentDidUpdate(prevProps,prevState){
      if (prevProps!==this.props){
        let value = {...this.state.dropdown}
        if (this.props.checked!==undefined && this.props.checked["Milieu biologique"]!==undefined && this.props.checked["Milieu biologique"]!==null){

          value["Milieu biologique"]=true

        } else {
          value["Milieu biologique"]=false
        }
        this.setState({dropdown:value})
      }
    }*/

    handleButtonClick(tag){
      const open = this.state.dropdown[tag]!==undefined ? !this.state.dropdown[tag] : true
      let value = {...this.state.dropdown}
      value[tag]=open
      this.setState({dropdown:value})
    }

    handleCheck(title,tag){
      this.props.addFilter(title,tag)
    }

    componentDidUpdate(prevProps,prevState){
      if (prevProps !== this.props){
        let vardropdown = {...this.state.dropdown}
        Object.keys(this.props.checked).filter(v => v !== "composant" &&  v !== "order").map((v,k) => (
          vardropdown[v] = true
        ))
        this.setState({dropdown:vardropdown})
      }
    }

render(){
  const sidebarClassname = this.props.sidebarOpen ? 'sidebar open' : 'sidebar'
  
  return (
    <div className={sidebarClassname}>
      {this.props.data!==undefined && this.props.data!==null && Object.keys(this.props.data).map( (v,k)=> (
        <div>
        {Object.keys(this.props.data[v]).length>0 &&
          <div>
            <SideBarTitle
              dropdown={this.state.dropdown}
              data={v}
              onClick={this.handleButtonClick}
            />
            <SideBarItem
              show={this.state.dropdown[v]}
              data={this.props.data[v]}
              onClick={this.handleCheck}
              title={v}
              checked={this.props.checked}/>
          </div>
        }
        </div>
      )) }


    </div>


  )
}

}


export default SideBar;
