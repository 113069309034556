import React from 'react'
import "./expertinfos.css"
import InputAccount from '../inputaccount/inputaccount.js'
import CheckBox from '../checkbox/checkbox.js'
import ChapitreChoice from '../chapitrechoice/chapitrechoice.js'




class ExpertInfos extends React.Component  {

  constructor(props){
      super(props);
      this.state = {
        checked:{
          chapitre:false,
          demande:false,
          resultat:false
        },
      }
      this.handlChange = this.handlChange.bind(this)
    }

    handlChange(type){
      let data = {...this.state.checked}
      data[type] = !this.state.checked[type]
      this.setState({
        checked:data
      })
    }



    getErrorExpert(data){
      return this.props.error.expert.error[this.props.expert]!==undefined && this.props.error.expert.error[this.props.expert].infos[data[0]]
    }

  render(){
    const checkedResult = this.props.data.bases.baseresultat!==undefined  ? this.props.data.bases.baseresultat : false
    const checkedDemande = this.props.data.bases.basedemande!==undefined  ? this.props.data.bases.basedemande : false
    const checkedChapitre = this.props.data.bases.basechapitre!==undefined  ? this.props.data.bases.basechapitre : false
    const texte = this.props.total === 1 ? "" : " n°"+Number(this.props.expert+1)
    return(
      <div className="container_expert">
        <div className="supp_expert">
          <h1 className="_expert_account_title">Biologiste expert{texte}</h1>
          {this.props.expert!==0 && this.props.expert===this.props.total-1 &&
            <div className="supp_button" onClick={()=>this.props.deleteExpert()}></div>
          }
        </div>


      {this.props.data.infos !== undefined && Object.entries(this.props.data.infos).map( (v,k)=>(
        <InputAccount
          data={v}
          onChange={this.props.onChange}
          inputData = {v}
          tag="expert"
          numero={this.props.expert}
          error={this.getErrorExpert(v)}
          disabled = {false}
        />
      )
      )}
      <div className="checkbox_container">
        <CheckBox
          label="Dictionnaire Chapitre"
          value={checkedChapitre}
          onChange={()=>this.props.checkChange("basechapitre",this.props.expert)}
          error = {this.props.error.expert.error["basechapitre"]}
          disabled = {this.props.disabled["basechapitre"] && !checkedChapitre}
          tooltip = {"chap_tooltip_"+this.props.expert}
        />
        <CheckBox
          label="Dictionnaire Demande"
          value={checkedDemande}
          onChange={()=>this.props.checkChange("basedemande",this.props.expert)}
          error = {this.props.error.expert.error["basedemande"]}
          disabled = {false}
          tooltip = {"cdem_tooltip_"+this.props.expert}
        />
      </div>
      {checkedDemande===true &&
        <ChapitreChoice
          data = {this.props.data.bases.chapitres_demande}
          numero={this.props.expert}
          chapitreChange = {this.props.chapitreDemandeChange}
          allchapitre={this.props.allChapitreDemande}
          error = {this.props.error.expert.error["chapitreDemande"]}
        />
      }
      <div className="checkbox_container">
        <CheckBox
          label="Dictionnaire Résultat"
          value={checkedResult}
          onChange={()=>this.props.checkChange("baseresultat",this.props.expert)}
          error = {this.props.error.expert.error["baseresultat"]}
          disabled = {false}
          tooltip = {"res_tooltip_"+this.props.expert}
        />
      </div>
      {checkedResult===true &&
        <ChapitreChoice
          data = {this.props.data.bases.chapitres_resultat}
          numero={this.props.expert}
          chapitreChange = {this.props.chapitreChange}
          allchapitre={this.props.allChapitreResultat}
          error = {this.props.error.expert.error["chapitreResultat"]}
        />
      }

      </div>
    )
  }

}

export default  ExpertInfos
