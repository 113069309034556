import React from 'react'

import SelectAndTxt from '../../SelectAndTxt/SelectAndTxt.js'
import DictionnaireTable from "../dictionnairetable/dictionnairetable.js"
import NavButton from "../../next.js"
import { Roller } from 'react-awesome-spinners'
import {Collapse} from 'react-collapse';
import { GrFormDown,GrFormUp } from 'react-icons/gr';
import Interligne from "../../interligne/interligne.js"



class ModifCol extends React.Component  {


    constructor(props){
      super(props);
      this.state = {
        selectChoice:[],
        id:-1,
        block:true,
        dico:"",
        showInfos:false
      }
      this.selectChange = this.selectChange.bind(this)
      this.onSelect=this.onSelect.bind(this)
    }


    collapse(){
      this.setState({showInfos:!this.state.showInfos})
    }
  

    onSelect(event,key){
      let tmp = {...this.state.selectChoice}
      tmp[key]=event.value
      this.setState({selectChoice:tmp})
    }

    selectChange(e,tag){
      this.props.resetDashPret()

      this.setState({
        id:e.value,
        block:false,
        dico : e.value,
        selectChoice:[],

      })
      this.props.readFile(e.value)
    }

    validation(){
      if (this.props.alldata.confirmFile) this.setState({block:true})


      this.props.modifChap(this.state.selectChoice,null,this.props.alldata.fileType)


    }

    reset(){
      this.props.readFile(this.state.id)
    }



  render(){

    const selectData = this.props.data.dico!==undefined ?  this.props.data.dico.map( (v,k)=>({
      label : v["nom"] + " (" + v["type"]+")",
      value : v["id"],
    })) : {label:"",value:""}


    return(
      <div className="container_modif">
        <SelectAndTxt
          label = { {name : "Dictionnaires disponibles" ,txt : ""} }
          data  = {selectData}
          selectChange = {this.selectChange}
          tag = "dico"
          selectData = {this.state.dico}
          index = {400}
        />

        { this.props.fileRender &&
          <div className="container_render center">
            <p>Pre-Chargement en cours</p>
            <Roller color="#f9af2e" />
          </div>
        }

        {this.props.showFile === true && !this.state.block && this.props.alldata.fileInfo.length>0 &&
          <React.Fragment>
            <div className="dash_navigation">
              <NavButton isDefine={true}  onClick={()=>this.validation()} text={this.props.alldata.confirmFile ? "Confirmer" : "Valider"} styles="btn_modif_dico" />
              <NavButton isDefine={this.props.alldata.confirmFile}  onClick={()=>this.reset()} text="Rétablir" styles="btn_modif_dico" />
            </div>

            {this.props.dataDoublon !== undefined && this.props.dataDoublon.length>0 && 
            <React.Fragment>
              <p className = "interligne alignement" onClick={()=> this.collapse()}>Les entrées suivantes (dépliez) ne seront pas traitées car elles apparaissent en double 'imparfait': <span> {this.state.showInfos ? <GrFormUp  /> : <GrFormDown  />}</span></p>
              <Collapse  isOpened={this.state.showInfos} >
                <div className = "check_container">
                  {this.props.dataDoublon.map((v,k)=> (
                    <Interligne styles = "sizing_entree alignement"  label = "" value = {v.join(" - ")} bold ="" />
                  ))}
                </div>
              </Collapse>
            </React.Fragment>
          }
            
          

            <DictionnaireTable
              data = {this.props.alldata.fileInfo}
              onSelect = {this.onSelect}
              type = {this.props.alldata.fileType}
              select = {this.state.selectChoice}
              confirmStatut = {this.props.alldata.confirmFile}
            />

          </React.Fragment>
        }



      </div>
    )
  }
}



export default ModifCol
