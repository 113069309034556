import React from 'react'
import './CheckAndTxt.css'

class CheckAndTxt extends React.Component  {

  render(){
    const style = this.props.styles !== undefined ? " " + this.props.styles : ""
    return (
      <div className="container_check_txt">
        <label className={this.props.disabled ? "checkboxandtxt inputdisabled" : "checkboxandtxt" + style } >
          {this.props.label}
          <input  className="check_txt"  disabled = {this.props.disabled} type="checkbox" checked={this.props.state} onChange={(e)=>this.props.onCheck(e,this.props.position)} />

        </label>
      </div>
    )
  }

}



export default CheckAndTxt
