import React from 'react';

import InputField from '../InputField';
import NavButton from "../next"
import './recupemail.css'
import {putAPI} from '../../api/api.js';


class Recup extends React.Component {

  constructor(props){
      super(props);
      this.state = {
          email: '',
          emailSend:false,
          accountInfo:{

          }
      }
      this.doConfirm = this.doConfirm.bind(this)
  }


  componentDidMount(){
    document.body.classList.remove("body_back")

  }
  componentDidUpdate(prev,prevstate){
      document.body.classList.remove("body_back")
  }

  doConfirm(){
    if (this.state.email!==""){
      const data={
        token : "T3wSU29IDEiJV13uw0TS",
        email : this.state.email,
        type : "recup"
      }
      putAPI("/emailrecup","",data).then(data => this.setState({emailSend:true}))

    }
  }

  setInputValue(val){
    this.setState({email:val})
  }

  render(){
    return(
      <div className="container_main_email">
        <div className="login_page_leftside">
          <img src="img_forgot_password.svg" alt="Compte" className="labo_img"/>
        </div>
        <div className="recupMailContainer">
          <div className="containerRecupForm">
            <div className="recupForm">
            {this.props.type === "email" && this.props.token==="email" &&
              <React.Fragment>
              <div>
                <img src="logo_onaos.png" alt="logo" className="logoRecupMail"/>
              </div>
              <div className="container_upperbox">
                <p className="upperbox font_color">Mot de passe oublié</p>
                <p className="underboxbold font_color">Entrez votre email pour récupérer votre mot de passe</p>
                <InputField
                  type='text'
                  placeholder='Email'
                  defaultValue={this.state.email ? this.state.email : ''}
                  onChange={ (val) => this.setInputValue(val) }
                  onKeyPress={this.doConfirm}
                  styles="input_size_recup"
                />
              </div>
              <NavButton
                isDefine={true}
                text="Confirmer"
                styles="labo_account_btn"
                onClick = {()=>this.doConfirm()}
              />

              <div className="container_msg">
                {this.state.emailSend &&
                  <React.Fragment>
                    <p>Un email de récupération, valable 15 min, vient de vous être envoyé</p>
                  </React.Fragment>
                }
              </div>
              <div className="container_password_not_received font_color">
                <p>Vous n'avez pas reçu le mot de passe ?</p>
                <p className="fake_link" onClick = {()=>this.doConfirm()}>Renvoyer à nouveau</p>
              </div>
              <div className="container_return_validation">
                <div className="return_button_validation" onClick = {() =>   {window.location.href = "/" }}></div>
                <p>Revenir à la page de connexion !</p>
              </div>
            </React.Fragment>
            }
        </div>
      </div>
    </div>
  </div>


    )
  }

}



export default Recup;
