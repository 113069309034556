import React from 'react'
import './loinctable.css'
import {GrAlert} from 'react-icons/gr'
import ReactTooltip from 'react-tooltip';
import SideBarTitle from '../sidebar/sidebartitle/sidebartitle.js';
import Interligne from "../interligne/interligne.js";


class LoincTable extends React.Component {

  constructor(props){
    super(props);
    this.orderClick = this.orderClick.bind(this)
  }

  orderClick(tag){
    const notForClick = ["Statut","Codes associés"]
    if (!notForClick.includes(tag)) this.props.addFilter("order",tag,true)
  }

  getRestreint(data){
    let result = "Aucun"
    const enattente = data !== undefined && data["Statut"] === "en attente" ? true : false
    let resultat = ""
    let demande = ""
    if (data === undefined || data["Circuit bio. chapitre"] === undefined || data["Circuit bio. résultat"] === undefined || data["Circuit bio. demande"] === undefined) return result
    if (data["Circuit bio. chapitre"] === "oui") return "Chapitre/Sous-Chapitre"
    if (data["Circuit bio. résultat"] === "oui") resultat="Résultat"
    if (data["Circuit bio. demande"] === "oui") demande="Demande"

    if (resultat!=="" && demande!=="") result = "Résultat + demande"

    if (resultat!=="" && result === "Aucun") result = resultat
    if (demande!=="" && result === "Aucun" ) result = demande

    return enattente ? "En attente ("+result+")" : result
  }




  onLOINCClick(data){
    if (data["Statut"]==="Actif" || data["Statut"]==="en attente" )  this.props.onClick(data["Code"])
  }

  checkRestreintVersusBase(restreint){
    const result= (restreint==="Aucun" ||
      (this.props.basetype==="demandes" && restreint==="Résultat" ) ||
      (this.props.basetype==="resultats" && restreint==="Demande" ) ||
      (this.props.basetype==="chapitres" && restreint!=="Chapitre/Sous-Chapitre" )
      ) ?  false : true
      return result
  }

  getClick(loinc,restreint){
    let result = "loincresult_body"
    if (this.props.select[loinc]!==undefined && this.props.select[loinc]==="select"){
      result=result+" result_select"
    } else  {
      result = this.checkRestreintVersusBase(restreint) ? result : "loincresult_unrestreint"
    }
    return result
  }


  getNABM(data){
    let result = []
    if (data["NABM"]!==undefined && data["NABM"]!==null) {
      data["NABM"].forEach((item, i) => {
        if (item["Code NABM"]!==undefined) {
          result.push(item["Code NABM"])
        }
      });
    }
    return result.join(', ')
  }

  getNABMLabel(data){
    let result = []
    if (data["NABM"]!==undefined && data["NABM"]!==null ) {
      data["NABM"].forEach((item, i) => {
        if (item["Libellé NABM"]!==undefined) {
          result.push(item["Libellé NABM"])
        }
      });
    }
    return result.join('\n')
  }

  findPanel(e,data){
    e.stopPropagation()
    if (data["Panel"]!==undefined && data["Panel"]==='oui') {
      this.props.findPanel(data["Code"])
    }
  }

  findAssos(e,data,type){
    e.stopPropagation()
    if (type === "inpanel"){
      if (data["PanelAssoc"]!==undefined && data["PanelAssoc"].length>0) {
        this.props.findAssos(data["Code"],type)
      }
    } else {
      this.props.findAssos(data["Code"],type)
    }
  }



  getStatutPanel(data){
    if (data["StatutPanel"] === "R") return "Obligatoire"
    if (data["StatutPanel"] === "O") return "Optionnel"
    if (data["StatutPanel"] === "C") return "Conditionnel"
    return "-"
  }

  getToolTip(text){
    let result = ""
    switch (text) {
      case "Codes associés":
        result = this.props.basetype ==="demandes" ? "Codes 'résultat' du Circuit de Biologie associés à ce code" : "Codes 'demande' du Circuit de Biologie associés à ce code"
        break;
     case "Statut":
        result = "Un code panel contient des codes qui doivent être déclenchés de manière Obligatoire, Conditionnelle ou Optionnelle"
        break;
      case "Appartient à un panel":
         result = "Voir le ou les panels déclenchant ce code"
         break;
     case "Panel":
        result = "Voir les examens (ou demandes) déclenchés par un panel"
        break;
      default:
        break;
    }
    return result
  }


  getLOINCInternational(data){
    let result = "-"
    if (data==="Both"){
      result = "Rés. + Dem."
    }
    if (data==="Observation") {
      result = "Résultat"
    }

    if (data==="Order") {
      result = "Demande"
    }

    return result
  }



  render(){
      let cellVal = [
        "LOINC",
        "Composant",
        "Statut",
        "Grandeur",
        "Milieu",
        "Temps",
        "Échelle",
        "Technique",
        "Chapitre",
        "Type",
        "Circuit de biologie"
      ]
      if (this.props.basetype !== "chapitres") {
        cellVal.push("Panel")
        cellVal.push("Appartient à un panel")
        cellVal.push("Codes associés")

      }
      if (this.props.basetype === "demandes") {
        cellVal.push("NABM")
      }

      const sliceResultMax = (this.props.resultPageNumber*100)-1
      const sliceResultMin = sliceResultMax-99

    return(
      <div>
      <table  className="loincresult_table"  >
        <thead class="loincresult_head_container">
          <tr className="loincresult_head">
            {cellVal.map((v,k) => (
                <SideBarTitle
                  dropdown={this.props.dropdownOrder}
                  data={v}
                  onClick={this.orderClick}
                  styles="table"
                  classname="loincresult_head_cell"
                  tooltipVal = {this.getToolTip(v)}
                  noIcon =  {["Statut","Codes associés","Appartient à un panel"]}

                />
            )
            )}
          </tr>
        </thead>
        <tbody class="loincresult_body_container">
          {this.props.data.slice(sliceResultMin,sliceResultMax).map( (v,k)=> (
            <tr className={v["Statut"] === "Inactif LOINC" ? "inactif_loinc" :  this.getClick(v["Code"],this.getRestreint(v))} onClick={() => this.onLOINCClick(v)}>

              <td data-tip={!this.checkRestreintVersusBase(this.getRestreint(v)) ? 'Show description' :"Hide description"  } data-for={!this.checkRestreintVersusBase(this.getRestreint(v)) ? v["Code"] : ""}
              key={!this.checkRestreintVersusBase(this.getRestreint(v)) ? "show-description-showed" : "show-description-hidden" }    className={!this.checkRestreintVersusBase(this.getRestreint(v)) ? "loincresult_body_cell mouse_unrestreint": "loincresult_body_cell"} >
                  {v["Code"]}

                  <ReactTooltip id={v["Code"]} type="info" effect="solid" >
                    Non utilisable : le code n'est pas disponible dans la base de donnée "Circuit de Biologie"
                  </ReactTooltip>
                </td>


              {v["Statut"] === "Inactif LOINC" && <td className="loincresult_body_cell"><span className='boldLOINC'>Inactif</span> - {v["Composant"]}</td>}
              {v["Statut"] !== "Inactif LOINC" && <td className="loincresult_body_cell">{v["Composant"]}</td>}

              <td className="loincresult_body_cell"><span className={this.getStatutPanel(v)}>{this.getStatutPanel(v)}</span></td>

              <td className="loincresult_body_cell">{v["Grandeur"]}</td>
              <td className="loincresult_body_cell">{v["Milieu biologique"]}</td>
              <td className="loincresult_body_cell">{v["Temps"]}</td>
              <td className="loincresult_body_cell">{v["Echelle"]}</td>
              <td className="loincresult_body_cell">{v["Technique"]}</td>
              <td className="loincresult_body_cell">{v["Chapitre"]}</td>
              <td className="loincresult_body_cell">{this.getLOINCInternational(v["Type"])}</td>
              <td  className="loincresult_body_cell"><span className="restreint_cell">{this.getRestreint(v)} {!this.checkRestreintVersusBase(this.getRestreint(v)) &&
                <GrAlert className="icone_caution" />
              }</span>
              </td>
              { this.props.basetype !== "chapitres"  &&
                <React.Fragment>
                <td className="loincresult_body_cell" onClick={(e)=>this.findPanel(e,v)}>
                  {v["Panel"]==="oui" && v["Statut"]==="Actif" &&
                    <span className="panelsearch_Oui">Oui</span>
                  }

                  {v["Panel"]==="oui" && v["Statut"]!=="Actif" &&
                    <span className="container_panel_inactif">
                    <span className="panelsearch_OuiNon">Oui</span>
                     <GrAlert className="margin_left" data-tip data-for={v["Code"]+"_inactif"} />
                     <ReactTooltip id={v["Code"]+"_inactif"} type="info" effect="float" >
                        Code panel non actif
                      </ReactTooltip>
                    </span>
                  }

                  {v["Panel"] === "non" &&
                    <span className="panelsearch_Non">Non</span>
                  }
                </td>
                <td className="loincresult_body_cell" onClick={(e)=>this.findAssos(e,v,"inpanel")}>
                {   v["PanelAssoc"].length>0 && <span className="panelsearch_Oui">Oui</span> }

                {  ( v["PanelAssoc"].length === 0) && <span className="panelsearch_Non">Non</span> }

                </td>
                <td className="loincresult_body_cell" onClick={(e)=>this.findAssos(e,v,"assos")} >
                  <span className="panelsearch_Voir">Voir</span>
                </td>
                </React.Fragment>
              }


              {this.props.basetype === "demandes" &&
                <td className="loincresult_body_cell" data-tip data-for={"NABM_"+v["Code"]}>{this.getNABM(v)}
                  <ReactTooltip multiline={true} id={"NABM_"+v["Code"]} type="light" effect="solid" >
                    <div className="tooltip_lines">
                      {this.getNABMLabel(v)}
                    </div>
                  </ReactTooltip>

                </td>
              }



            </tr>

          )) }
        </tbody>
      </table>

      { this.props.data!==undefined && this.props.data.length === 1 && this.props.attributs !== undefined && this.props.attributs.composant !== undefined && this.props.data[0]["Code"] === this.props.attributs.composant && this.props.isPanel &&

        <Interligne styles = "center"  label = "" value = "Aucun code défini dans ce panel sur Loinc.org" />

      }
      </div>

    )
  }

}


export default LoincTable;
