import React from 'react'
import { MultiSelect } from "react-multi-select-component";
import "./chapitrechoice.css"



class ChapitreChoice extends React.Component  {

  constructor(props){
      super(props);
      this.state = {
        selected:[]
      }
      this.handleChange = this.handleChange.bind(this)
    }

  handleChange(e){
    this.props.chapitreChange(this.props.numero,e)
  }

  getError(){
    return this.props.error ? "multiselect_container multiselect_container_red" : "multiselect_container"
  }


  render(){

      const options = this.props.data.map( (v,k) => ({
        label:v,
        value:v
      }))


      let allchapitre=[]
      this.props.allchapitre.forEach((item, i) => {
        if (Array.isArray(item)){
           allchapitre.push({
             label : item[0],
             value : item[0],
             disabled : true
           })
        } else {
          allchapitre.push({
            label : item,
            value : item
          })
        }
      });




      const overrideStrings = {
        "allItemsAreSelected": "L'ensemble des items sont sélectionnés",
        "clearSearch": "Effacer la recherche",
        "noOptions": "Aucune option",
        "search": "Recherche",
        "selectAll": "Tout sélectionner",
        "selectAllFiltered": "Tout sélectionner (Filtered)",
        "selectSomeItems": "Chapitres pris en charge..."
      }

    return(
      <div className="chapitre_container">
        <div className={this.getError()}>
        <MultiSelect
          options={allchapitre}
          value={options}
          onChange={this.handleChange}
          labelledBy="Select"
          overrideStrings={overrideStrings}
        />
        </div>
      </div>
    )
  }
}



export default ChapitreChoice
