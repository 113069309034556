import './modal.css';
import React from 'react';
import Selectcomm from './selectcomm.js'
import Interligne from '../interligne/interligne';

class Modal extends React.Component {

  constructor(props) {
     super(props);
     this.state={
       error:'',
       comm:'',
       keyboard:false
     }
     this.inputChang = this.inputChang.bind(this);
     this.handleKeyPress = this.handleKeyPress.bind(this)
     this.selectChange = this.selectChange.bind(this)
     this.onCreateOption = this.onCreateOption.bind(this)
     this.reg = this.reg.bind(this)
   }

  inputChang(e) {
    this.setState({comm: e.target.value});
    this.setState({keyboard:true});
  }

  selectChange(e,meta){
    if (meta.action === "clear"){
      this.props.clearSelect(meta.removedValues[0].value,this.props.type)
    } else {
      this.onCreateOption(e.value,false)

    }



  }

  onCreateOption(value,add = true){
    this.props.selectCommChange(value,this.props.type,add)
    this.setState({comm: value},()=> this.reg());
 
  }


  reg() {
    let comm = ""
    let error=""
    if (this.state.keyboard===true){ //supp or text
      comm = this.state.comm
    } else {
      comm = this.props.value
    }
    if (comm==="" && this.props.title==="Explication rejet proposition : "){
      error="Merci de détailler la raison du rejet du transcodage proposé afin d'aider nos experts à vous faire une meilleure proposition"
    } else {
      this.props.onRegister(comm);
      error="";
      comm="";
      this.form.reset();
    }
    this.setState({
      comm:comm,
      error:error,
      keyboard:false,
    });

  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      this.reg();
    }
    if (e.key === 'Escape' ) {
      this.cancel();
    }
    
  }

cancel(){
  this.setState({"error":""});
  this.setState({"comm":""});
  this.props.cancel();
}





  render(){
    const showHideClassName = this.props.show ? "modal display-block" : "modal display-none"
  //  const texte = this.props.baseStatut==="audit" ? "Commentaire d'audit : " : "Commentaire : "
    const texte = this.props.title

    const showSelect = this.props.alldata.access !== undefined && this.props.toEdit === false &&  this.props.alldata.access === "expert" &&  (this.props.type === "commEx" || this.props.type === "reco") ? true : false

    return (

      <div className={showHideClassName}>
        <section className="modal-main">
          <form className="modal-position" ref={form => this.form = form}>
            <label className="modal-label">
              {texte}
              {showSelect &&
                <Selectcomm
                  type = {this.props.type}
                  onChange = {this.selectChange}
                  defaultValue={this.props.value}
                  onCreateOption = {this.onCreateOption}
                  expertallcomm = {this.props.expertallcomm}
                  expertallreco = {this.props.expertallreco}
                  validation = {this.reg}
               />
              }

              {!showSelect &&
                <input
                  ref={(ref) => this.mainInput= ref}
                  type="text"
                  defaultValue={this.props.value}
                  onChange={this.inputChang}
                  onKeyPress={this.handleKeyPress}
                />
              }
            </label>
            {this.props.title === "Explication rejet proposition : " && this.props.isVeille && 
              <div style = {{display : "flex"}}>
                <span className="end_interligne" style = {{justifyContent:"center"}}><b>Attention</b> dans le cadre d'une mise à jour LOINC, l'absence de sélection d'un code LOINC supprimera le transcodage initial dans votre export</span>
              </div>
            
            }
            <div className="modal-btn">
              <button type="button" onClick={()=>this.reg()}>Valider</button>
              <button type="button" onClick={()=>this.cancel()}>Annuler</button>
            </div>
            {this.state.error!=='' && <span className="error">{this.state.error}</span> }
          </form>
        </section>

      </div>
    );
  }
};

export default Modal;
