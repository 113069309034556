import React from 'react'
import './TextInputQuestionnaire.css'




class TextInputQuestionnaire extends React.Component{

    constructor(props) {
        super(props)

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {    
        this.props.onChangeText(event.target.value)
    }

    render(){

        const rep = this.props.textValue !== "" && this.props.valeur === "" ? this.props.textValue : this.props.valeur
        return(
            <div className='container_text_questionnaire'>
                <p className='text_p_questionnaire'>Propositions d'amélioration (facultatif) :</p>
                <textarea  className='input_questionnaire' value={rep} onChange={this.handleChange}/>
            </div>
        )
    }


}


export default TextInputQuestionnaire