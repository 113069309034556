
import React from 'react';
import './searchhistory.css'





class SearchHistory extends React.Component {

  constructor(props) {
     super(props);

     this.itemRefs = {};
   }


  listCLick(item){
    this.props.closeList()
    this.props.addFilter("composant",item)

  }

  getClass(number,item){
    if (number===this.props.activeSuggestion){
      return "search_history-active"
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.activeSuggestion!==prevProps.activeSuggestion && this.itemRefs!==undefined && this.itemRefs[this.props.activeSuggestion]!==null  && this.itemRefs[this.props.activeSuggestion]!==undefined ){
      this.itemRefs[this.props.activeSuggestion].scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
    }

  }

  render(){

    return(
      <div>
      {this.props.show && this.props.lengthComposant>0 && this.props.filteredSuggestions!==null && this.props.filteredSuggestions.length>0 &&
        <ul className="search_history" >
        {
          this.props.filteredSuggestions.map( (v,k)=> (

            <li
              ref={el => (this.itemRefs[k] = el)}
              key={k}
              className={this.getClass(k,v)}
              onClick={()=>this.listCLick(v)}>
              {v}
            </li>


          )
        )

        }

        </ul>
      }
      </div>
    )
  }

}


export default SearchHistory;
