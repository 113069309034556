import React  from "react";
import './searchbox.css';
import NavButton from "../next";
import SearchHistory from '../searchhistory/searchhistory.js'



class SearchBox extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      input:"",
      reset:"N/A",
      indexList:-1
    }
    this.inputChang = this.inputChang.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this)
    this.inputChangDenom = this.inputChangDenom.bind(this);
  }



  inputChang(e) {
    this.props.addFilter("composant",e.target.value)

  }

  inputChangDenom(e) {
    this.props.addFilter("denominateur",e.target.value)
  }


  handleKeyPress = (e) => {
    const data = this.setFilter()

    if (e.key === 'Delete'){
      this.setState({indexList:-1})
     // this.props.addFilter("composant","")
     // this.props.reset()
      return true
    }
    if (e.key==="Backspace") {
      this.setState({indexList:-1})
      return true
    }
    if (e.key === 'Enter') {
      e.stopPropagation()
      if (this.state.indexList>-1){

        this.props.closeList()
        if (data!==null)  this.props.addFilter("composant",data[this.state.indexList],true)
      } else {
        this.search()
      }

    }else{
      if ((e.key==="ArrowUp" || e.key==="ArrowDown" )){
        if (this.props.showlisthistory===true){
          if (e.key==="ArrowUp"){
            if (this.state.indexList!==undefined && this.state.indexList>0) {
              this.setState({indexList:this.state.indexList-1})
            }
          } else {

            if (this.state.indexList!==undefined && this.state.indexList<data.length-1){
              this.setState({indexList:this.state.indexList+1})
            }
          }
        }
      } else {
        this.props.closeList(false)
        this.setState({indexList:-1})
      }
    }

  }

  setFilter(){
    return this.props.composantList!== undefined && this.props.composantList!==null && this.props.composantList.length>0 &&  this.props.state.attributs!==undefined && this.props.state.attributs["composant"]!==undefined ?  
      this.props.composantList.filter(suggestion => suggestion!== undefined && suggestion.toLowerCase().indexOf(this.props.state.attributs["composant"].toLowerCase()) > -1)
      : 
      null;
  }


  search(){
    this.props.closeList()
    this.props.search()
  }

  showFilters(){
    this.props.closeList()
    this.props.showFilters()
  }



  reset(){
    this.props.closeList()
    this.props.reset()
  }

  getFiltreStyle(){
    let result="btn_search"
    if (this.props.redfilter===true){
      result=result+" auditGreen"
    }
    return result
  }

  checkChang(typeChang){
    this.props.autoFilterChange(typeChang)
  }

  render(){
    return(
      <div className="container_search_box">
        <div className="container_input_check">
          <div className="container_btn_search">
            <p className="search_input_p">
              <input
                className="search_input"
                type="text"
                defaultValue={this.props.state.attributs["composant"]}
                onChange={this.inputChang}
                onKeyDown={this.handleKeyPress}
                placeholder="Composant"
                value={this.props.state.attributs["composant"]}
              />
              <SearchHistory
                data={this.props.composantList}
                input = {this.props.state.attributs["composant"]!==undefined ? this.props.state.attributs["composant"] : ""}
                lengthComposant={this.props.state.attributs["composant"]!==undefined ? this.props.state.attributs["composant"].length:0 }
                addFilter={this.props.addFilter}
                show={this.props.showlisthistory}
                closeList={this.props.closeList}
                activeSuggestion={this.state.indexList}
                filteredSuggestions={this.setFilter()}
              />
            </p>
          
            <NavButton isDefine={true}  onClick={()=>this.search()} text="Rechercher" styles="btn_search"/>
            <NavButton isDefine={true}  onClick={()=>this.showFilters()} text="Filtres" styles={this.getFiltreStyle()}/>
            <NavButton isDefine={this.props.getIfOneClick}  onClick={this.props.validation} text="Confirmer" styles="btn_search btn_color_search"/>
            <NavButton isDefine={true}  onClick={()=>this.reset()} text="Reset" styles="btn_search" />
            <NavButton isDefine={true}  onClick={this.props.cancel} text="Annuler" styles="btn_search"/>
            <NavButton isDefine={this.props.showBackPanel}  onClick={()=>this.props.backSearch()} text="Précédent" styles="btn_search"/>
          </div>

          {this.props.showAutoFilter && 
            <p className="search_input_check">
              <input
                id = "autofilterCheck"
                type="checkbox"
                checked = {this.props.autoFilterMedium}               
                onChange={(e)=>this.checkChang("medium")}
              />
              <label className="label_checkbox_search" htmlFor="autofilterCheck">Auto-filtre Milieu</label>
              <input
                id = "autofilterCheckGrandeur"
                type="checkbox"
                checked = {this.props.autoFilterGrandeur}               
                onChange={(e)=>this.checkChang("grandeur")}
              />
              <label className="label_checkbox_search" htmlFor="autofilterCheckGrandeur">Auto-filtre Grandeur</label>
            </p>
          }
        </div>



        </div>

    )


  }
}

export default SearchBox;
