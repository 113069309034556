import React from 'react'
import './dashaccountgestion.css'
import Interligne from "../../interligne/interligne.js"
import Select from 'react-select'
import {Collapse} from 'react-collapse';
import { GrFormDown,GrFormUp } from 'react-icons/gr';
import ImportFile from "./ImportFile.js"
import CheckAndTxt from '../../CheckAndTxt/CheckAndTxt';
import NavButton from '../../next';
import SelectAndTxt from '../../SelectAndTxt/SelectAndTxt';

class DashAccountGestion extends React.Component  {
  constructor(props){
    super(props);
    this.state={
      selectlabo:"",
      selectExpert:[],
      show:{
        expert:false,
        labo:false
      },
      stopMail :  this.props.data.infos !== undefined ? this.props.data.infos.stopmail : "",
      expertOnaos :  this.props.data.onaos!== undefined ?  this.props.data.onaos.assignation : "",
      technidata : false,
      technidata2 : false,
      technidataFile:"",
      technidataFile2:"",
      dsiContact : "",
      dsiAction : "",
    }
    this.test = this.test.bind(this)
    this.changExpert = this.changExpert.bind(this)
    this.filerefTech = React.createRef()
    this.fileChange = this.fileChange.bind(this)
    this.fileChange2 = this.fileChange2.bind(this)
    this.filerefTech2 = React.createRef()
    this.dsiAction  = this.dsiAction.bind(this)


  }


  test(){
    this.props.testCR()

  }

  setSelectval(){

    let result=[]
    result.push({
      label:"Actions",
      value:''
    })
    if (this.props.data.accountstatus!=="done"){
      result.push({
        label:"Renvoyer l'email d'inscription",
        value:'send'
      })
    }
    result.push({
      label:"Supprimer le compte labo",
      value:'delete'
    })
    return result
  }

  expertAction(data){
    let result=[]
    result.push({
      label:"Actions",
      value:''
    })

    if (!this.getStatutExpert(data)){//pass non crée
      result.push({
        label : "Renvoyer l'email de mot de passe",
        value:'sendexpert'
      })
    }
    return result
  }

  selectChange(data,tag,value=null){
    if (data.value!==""){
      if (data.value==="delete"){
        if (window.confirm("Êtes-vous sur de vouloir supprimer ce compte ?") === false)
        return true
      }
      this.setState({selectlabo:data.value})
      this.props.action(tag,data.value,value)
    }
  }

  getDictionnaires(data){
    let result=[]
    if (data.bases===undefined) return ""
    if (data.bases.basechapitre===true) result.push("Chapitres")
    if (data.bases.basedemande===true) result.push("Demandes")
    if (data.bases.baseresultat===true) result.push("Résultats")
    return  result.join(" - ")
  }

  getChapitres(data){
    let result = ""
    if (data.bases.chapitres_resultat === undefined || data.bases.chapitres_resultat === null) return ""
    result = data.bases.chapitres_resultat.join(", ") //TODO : full en infobulle ?
    return result.length > 100 ?  result.substring(0, 80) + '...' : result

  }

  getChapitresDem(data){
    let result = ""
    if (data.bases.chapitres_demande === undefined || data.bases.chapitres_demande === null) return ""
    result = data.bases.chapitres_demande.join(", ") //TODO : full en infobulle ?
    return result.length > 100 ?  result.substring(0, 80) + '...' : result

  }

  getStatutExpert(data){
    return data.idtable.creation === "done" ? true : false
  }


  collapse(who){
    const tmp = {...this.state.show}
    tmp[who] = !tmp[who]
    this.setState({show:tmp})
  }

  getSIL(){
    return this.props.data !== undefined && this.props.data.infos !== undefined && this.props.data.infos.labo.logiciel["S.I.L. version"]!==undefined && this.props.data.infos.labo.logiciel["S.I.L. version"]!=="" ? this.props.data.infos.labo.logiciel["S.I.L."]+" ("+this.props.data.infos.labo.logiciel["S.I.L. version"]+")" : this.props.data.infos.labo.logiciel["S.I.L."]
  }


  stopMail(){
    const action = this.state.stopMail === "send" ? "stop" : "activer"
    const val = this.state.stopMail == "send" ? "close" : "send"
    this.setState({stopMail:val})
    this.props.stopMailCheck(action)

  }

  changExpert(e,tag){
    this.props.assignExpert(e.value)
    this.setState({expertOnaos : e.value})
  }


  fileChange(event){
    this.setState({
      technidata:true,
      technidataFile:event.target.files[0]
    })
  }

  fileChange2(event){
    this.setState({
      technidata2:true,
      technidataFile2:event.target.files[0]
    })
  }

  sendFileTechnidata(){

    this.setState({technidata:false})
    this.filerefTech.current.value = null;
    this.props.modifTechnidata(this.state.technidataFile)
  }


  sendFileTechnidata2(){

    this.setState({technidata2:false})
    this.filerefTech2.current.value = null;
    this.props.modifTechnidata2(this.state.technidataFile2)
  }

  dsiChange(value) {
    this.setState({dsiContact : value})
  }

  sendCreateDSI(){

    if (this.state.dsiContact === "") return null
    this.props.addDSI(this.props.data.infos.labo["Nom du laboratoire"],this.state.dsiContact )

  }


  dsiAction(data){
    this.setState({dsiAction : data.value})
    if (data.value !== "") this.props.action("senddsi",data.value)
  }

  render(){

    const customStyles = {
      container: provided => ({
        ...provided,
        width: 300
      })
    }
    const laboAccount =  this.props.data.accountstatus === undefined ? "" : this.props.data.accountstatus==="en cours" ? "En attente de validation client depuis le "+this.props.data.accountsend.since : "Compte créé"
    const selectVal = this.setSelectval()

    const expertOnaos =  this.props.data.onaos !== undefined && this.props.data.onaos.listexpert !== undefined ? this.props.data.onaos.listexpert.map( (v,k) => ({
      label : v, 
      value : v
    }))     : [{label : "-", value :"-"}]

    const dsiAction = this.props.data.dsi !== undefined && this.props.data.dsi.find === "ok" ? 
    [
      {
        label:"Action",
        value:''
      },
      {
        label:"Renvoyer l'email d'inscription",
        value:'senddsi'
      }
    ] : [{label : "Action", value : ""}]

    return(
      <div className="container_account_gestion"  >
        <div className="labo_gestion">
          <p className = "interligne alignement" onClick={()=> this.collapse("labo")}>Compte laboratoire : <span> {this.state.show.labo ? <GrFormUp  style={{marginLeft:"10px"}}/> : <GrFormDown style={{marginLeft:"10px"}} />}</span></p>
          <Select
            value = {selectVal.find(option => option.value === this.state.selectlabo )}
            options = {selectVal}
            onChange = {(e) => this.selectChange(e,"labo") }
            styles={customStyles}
          />
        </div>
        <Collapse isOpened={this.state.show.labo} >
          <div className="expert_gestion">
            <Interligne styles = "alignement"  label = "" value = {laboAccount} />
            {this.props.data !== undefined && this.props.data.infos !== undefined &&
            <React.Fragment>
            <Interligne styles = "alignement"  label = "Contact Admin" value = {this.props.data.accountsend.contact} />
            <Interligne styles = "alignement"  label = "Nom de la structure" value = {this.props.data.infos.labo["Nom de la structure"]} />
            <Interligne styles = "alignement"  label = "Adresse" value = {this.props.data.infos.labo.Adresse+" "+this.props.data.infos.labo.complement["Code postal"] + " "+this.props.data.infos.labo.complement["Ville"] +" "+this.props.data.infos.labo.complement["Pays"] } />
            <Interligne styles = "alignement"  label = "S.I.L." value = {this.getSIL()} />
            <Interligne styles = "alignement"  label = "Exports reçus le" value = {this.props.data.infos.date.export} />
            <Interligne styles = "alignement"  label = "Livré le" value = {this.props.data.infos.date.delivrary} />
            <div className='expert_gestion_btn'>
              <Interligne styles = "alignement"  label = "Email de progression" value = {this.state.stopMail === "send" ? "Actif" : "Bloqué"  } />
              <NavButton 
                isDefine={true}
                onClick={()=>this.stopMail()}
                text={this.state.stopMail === "send" ? "Bloquer" : "Activer"}
                styles="dashbutton"
              />
            </div>
            </React.Fragment>
             }

            {
              this.props.data.dsi !== undefined && this.props.data.dsi.find === "ok" && 
                <React.Fragment>
                  <Interligne styles = "alignement"  label = "Contact DSI" value = {this.props.data.dsi.prenom + " " + this.props.data.dsi.nom} />
                  <Interligne styles = "alignement"  label = "Email DSI" value = {this.props.data.dsi.email} />
                  <div className="expert_statut">
                    <Interligne styles = "alignement"  label = "Statut du compte" value = {this.props.data.dsi.passSet ? "Compte DSI actif" : "En attente de création du mot de passe"} />
                    { this.props.data.dsi.passSet !== true && 
                      <Select
                        options = {dsiAction}
                        value = {dsiAction.find(option => option.value === this.state.dsiAction)}
                        onChange = {(e) => this.dsiAction(e) }
                        styles={customStyles}
                      />
                    }
                  </div>


                </React.Fragment>

            }

            {
              (this.props.data.dsi === undefined || this.props.data.dsi.find === "nok")  &&
                <React.Fragment>
                  <div className="expert_statut">
                    <Interligne styles = "alignement"  label = "Créer un compte DSI" value = "" />
                    <input
                      style = {{width : "200px", height:"25px"}}
                      onChange = {(e) => this.dsiChange(e.target.value)}
                    />
                    <NavButton isDefine={true}  onClick={() => this.sendCreateDSI()} text="Créer" styles="upload_btn_technidata" />

                  </div>
                </React.Fragment>
            }
      
          </div>
        
        </Collapse>
        {this.props.data !== undefined && this.props.data.infos !== undefined &&
        <div className="container_expert_gestion">
          {this.props.data.accountstatus!=="en cours" &&
            <p className = "interligne alignement" onClick={()=> this.collapse("expert")}>Experts labo <span> {this.state.show.expert ? <GrFormUp style={{marginLeft:"10px"}} /> : <GrFormDown style={{marginLeft:"10px"}} />}</span> :</p>
          }
          <Collapse isOpened={this.state.show.expert} >
            {this.props.data.accountstatus!=="en cours" && this.props.data.infos.experts!==undefined && this.props.data.infos.experts.map( (v,k) => (
                <div className="expert_gestion">
                  <div className="expert_infos">

                    <Interligne styles = "alignement"  label = "Nom" value = {v.infos.Prénom !=="" ? v.infos.Prénom + " " +v.infos.Nom  : "Erreur"} />

                    <Interligne styles = "alignement"  label = "Email" value = {v.infos.Email!=="" ? v.infos.Email : "Erreur"} />
                      <Interligne styles = "alignement"  label = "Tél" value = {v.infos.Téléphone } />

                      <Interligne styles = "alignement"  label = "N° CPS" value = {v.infos.CPS!==undefined ? v.infos.CPS : ""} />
                      <Interligne styles = "alignement"  label = "Dictionnaires" value = {this.getDictionnaires(v)} />
                      <Interligne styles = "alignement"  label = "Chapitres de demande" value = {this.getChapitresDem(v)} />
                      <Interligne styles = "alignement"  label = "Chapitres de résultat" value = {this.getChapitres(v)} />

                  </div>
                  <div className="expert_statut">
                    <Interligne styles = "alignement"  label = "Statut du compte" value = {v.idtable.creation === "done" ? "Compte expert actif" : "En attente de création du mot de passe"} />
                    <Select
                      value = {this.expertAction(v).find(option => option.value === this.state.selectExpert[k] )}
                      options = {this.expertAction(v)}
                      onChange = {(e) => this.selectChange(e,'sendexpert',v.infos.Email) }
                      styles={customStyles}
                    />
                  </div>
                </div>
            ))}
          </Collapse>
        </div>
        }
        <div className="main__import">
          <Interligne styles = "alignement"  label = "Téléverser des tables" value = "" />
          <ImportFile
            data = {this.props.data.infos}
            sendFile = {this.props.sendFile}
            error = {this.props.error}
            deleteFile = {this.props.deleteFile}
          />
        </div>

        {!this.props.expertother && <div className="main_expert">
          <SelectAndTxt 
            label = {{name : "Assigner à un expert particulier", txt : ""}}
            data = {expertOnaos}
            selectdata = {this.state.expertOnaos}
            tag = ""
            selectChange={this.changExpert }
          />
          

        </div>
        }

        <div className = "technidata">
          <Interligne styles = "alignement"  label = "Technidata - export client" value = "" />

          <input
            type="file"
            className = "input_import"
            onChange={this.fileChange2}
            accept=".csv"
            ref = {this.filerefTech2}
          />
          <NavButton isDefine={this.state.technidata2}  onClick={() => this.sendFileTechnidata2()} text="Récupérer fichier" styles="upload_btn_technidata" />
        </div>



        <div className = "technidata">
          <Interligne styles = "alignement"  label = "Technidata - gestion des doublons" value = "" />

          <input
            type="file"
            className = "input_import"
            onChange={this.fileChange}
            accept=".csv"
            ref = {this.filerefTech}
          />
          <NavButton isDefine={this.state.technidata}  onClick={() => this.sendFileTechnidata()} text="Modifier la base" styles="upload_btn_technidata" />
        </div>
             
        <div className="bouton_container_test">


          

        </div>

      </div>
    )
  }

}

export default DashAccountGestion
