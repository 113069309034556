import React from "react";
import './button.css';
import ReactTooltip from 'react-tooltip';


class NavButton extends React.Component {

  getStyle(){
    return this.props.styles !== undefined ? "button "+this.props.styles : "button"
  }


  render(){
    return(
      <React.Fragment>
        {this.props.isDefine && 
          <button className={this.getStyle()}  style = {this.props.style} onClick={this.props.onClick} data-tip data-for={this.props.tooltip !== undefined &&  this.props.tooltipText!=="" ? this.props.tooltip : "" } >{this.props.text}</button>
        }
        { this.props.tooltip !== undefined  && this.props.tooltipText!=="" &&
          <ReactTooltip id={this.props.tooltip !== undefined  && this.props.tooltipText!=="" ? this.props.tooltip : "" } type="info" effect="solid" >
            {this.props.tooltipText}
          </ReactTooltip>
        }
      </React.Fragment>
      
    )


  }
};

export default NavButton;
