
import './App.css';
import React from 'react'
import Modal from './stores/modal/clientcomm.js';
import MultiMap from "./stores/maps/multimap.js";
import EndForm from "./stores/end/EndForm.js";
import BootProgress from "./stores/bootprogress/bootprogress.js";
import MiddlePage from "./stores/middlepage/middlepage.js";
import ClientMap from "./stores/clientmap/clientmap.js"
import SearchLOINC from './stores/searchloinc/SearchLOINC.js'
import DuplicateValidation from './stores/duplicatevalidation/duplicatevalidation.js'
import MenuClient from './stores/FloatingMenu/MenuClient.js'
import Logo from "./img/logo_onaos.png";
import KeyBoard from './stores/keyboard/KeyBoard.js'

import {getAPI,putAPI} from './api/api.js';

import MainQuestion from './stores/questionnaire/main';

class Mapping extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        firstLoad : true,
        client:"",
        buttonDisabled:false,
        dataClient:[],
        reject:undefined,
        clickNumber:'',
        choice:'',
        end:false,
        eraseClick:false,
        showmodal:false,
        showBtnAgree:false,
        allcode:[],
        allcodeOrigin : [],
        codeclientselect:'',
        loading:false,
        firstConnexion:false,
        duplicatecard:"",
        showConfirmation:false,
        user:"",
        showSearch:false,
        isChangNav:false,
        searchResult:[],
        defaultAttributs:[],
        searchEpreuve:[],
        sameEntry:[],
        allChecked:true,
        showSameEntry:false,
        searchComposant:"",
        composantList:"",
        showConfirmationValidation:false,
        confirmationEntry:[],
        donottranscode:false,
        resultPageNumber:1,
        resultPageData:{},
        showAdd:false,
        notFind:false,
        notFindCode:"",
        exportComm:false,
        allrecommandations:[],
        allcommentaires:[],
        exportJournal:false,
        exportJournalExpert:false,
        exportExclu : false,
        exportDoublon:false,
        exportBis:false,
        duplicationState:[],
        findLOINCRoller : false,
        toEdit:false,
        dataexport : [],
        keyboard :false,
        questionnaireShow:false,
        question:{},
        responseQuestion : {},
        withbio:false,
        truefull:false,
        removeCodeFile:false,
        allCodePosition: [],
        dedalus : false,
        fileNameDate : "",
        allchapitres :{},
        chapterValue : "-",
        next : "",
        previous : "",
        endInfos : {}
      }
      this.inputClient = React.createRef();
      this.divMapper = React.createRef();

      this.hideModal = this.hideModal.bind(this);
      this.cancelEdit = this.cancelEdit.bind(this);
      this.onCardClick =  this.onCardClick.bind(this);
      this.navClientEnd =  this.navClientEnd.bind(this);
      this.doDisconnect =  this.doDisconnect.bind(this);
      this.exportCSV = this.exportCSV.bind(this);

      this.onCheck = this.onCheck.bind(this);
      this.onBtnClick = this.onBtnClick.bind(this);

      this.edit = this.edit.bind(this);
      this.findDuplicate = this.findDuplicate.bind(this)
      this.findNewLOINC = this.findNewLOINC.bind(this)
      this.duplicateCancel = this.duplicateCancel.bind(this)
      this.addAfterSearch = this.addAfterSearch.bind(this)
      this.addAfterMultiSearch = this.addAfterMultiSearch.bind(this)

      this.showSearch = this.showSearch.bind(this)
      this.findLOINC = this.findLOINC.bind(this)

      this.showDashboard = this.showDashboard.bind(this)

      this.onkeypress = this.onkeypress.bind(this)
      this.onPageChange = this.onPageChange.bind(this)
      this.showAdd = this.showAdd.bind(this)
      this.addLoincWithConf = this.addLoincWithConf.bind(this)
      this.sendCopy = this.sendCopy.bind(this)
      this.selectCommChange = this.selectCommChange.bind(this)
      this.clearSelect = this.clearSelect.bind(this)

      this.panelSearch = this.panelSearch.bind(this)

      this.duplicationCheck = this.duplicationCheck.bind(this)
      this.allCheck = this.allCheck.bind(this)
      this.deleteComm = this.deleteComm.bind(this)
      this.keyboard = this.keyboard.bind(this)

      this.questionValidation = this.questionValidation.bind(this)
      this.questionPrevious = this.questionPrevious.bind(this)
      this.questionStart = this.questionStart.bind(this)
      this.questionEnd = this.questionEnd.bind(this)

      this.onChapterChange = this.onChapterChange.bind(this)
      this.changListCode = this.changListCode.bind(this)

      
  }

  changListCode(value) {
    const newAllCode = (value === "all" || value === "-" ) ? this.state.allcodeOrigin :   this.state.allcodeOrigin.filter( v => v.chapter === value )
    let position = []
    newAllCode.forEach( (v,k)=> {
        position[v.value] = k
      }
    )
    this.setState({allcode:newAllCode, allCodePosition : position})
  }


  onChapterChange(e){
    this.setState({chapterValue:e.value}, () => console.log("set : "+this.state.chapterValue))
      const url = "/chapitrenav"
      const datas = {
        chapitre : e.value,
        client : this.state.id
      }

     
      this.apiPUT(url,datas)
      this.changListCode(e.value)
    

  }

  sendCopy(){
    let url="/result/sendunconsensual"
    this.apiGET(url,'nothing')
  }


  showDashboard(state){
    this.props.showDashboard(state)
  }

  componentDidUpdate(){
    this.divMapper.current.focus();
  }

  componentDidMount() {

    const historique =  JSON.parse(this.props.alldata.historique)
    const allcomm = this.props.alldata.commentaires !== undefined ? this.props.alldata.commentaires : [{label:"",value:""}]
    const allreco = this.props.alldata.recommandations !== undefined ? this.props.alldata.recommandations : [{label:"",value:""}]

    this.setState({

      composantList :  historique,
      allcommentaires : allcomm,
      allrecommandations : allreco,

    })
    
    window.dispatchEvent(new CustomEvent("navigationhandler"));

 
    this.takeUntreated()
    this.getAllCode()
    
    //this.takeAll()
    this.divMapper.current.focus()
  }

  takeAll(){
    this.apiGET("/takeall","allentryanduntreated")
  }

  apiPUT(url,datas,rep = "data"){
    putAPI(url,this.props.alldata.tokenrefresh,datas).then(data => this.dataGet(data,rep))
   }

   apiGET(url,type="data",reptype=null){
     getAPI(url,this.props.alldata.tokenrefresh,reptype).then(data => this.dataGet(data,type))
    }



  fetchData(data){
    if (data["nbrEntryFind"]!==undefined && data["nbrEntryFind"]>1) {
      this.setState({
        sameEntry:data,
        showSameEntry:true
      })
    return true
  }
  if (data["needconfirmation"]===true){
    this.setState({
      confirmationEntry:data,
      showConfirmationValidation:true})
      return true
  }

  if (data["notFind"]===true){
    this.setState({
      confirmationEntry:data,
      notFind:true,
      notFindCode:data.code
    })
    return true
  }


    if (data[0] !== undefined && data[0]["end"]!==undefined && data[0]["end"]==="end"){
      this.apiGET("/questionnaireend/all","questionnaire")

    } else {
      const  clientSelect = [
          { value: data[0]["client"], label:data[0]["client"] },
      ];
      // if (data[0]["next"]===""){
      //   data[0]["next"]=this.props.alldata.first ;
      // }
      // if (data[0]["prev"]===""){
      //   data[0]["prev"]=this.props.alldata.last;
      // }
      const donottranscode = (data[0]!==undefined && data[0]["Choix client"]!==undefined && data[0]["Choix client"][0]!==undefined &&  data[0]["Choix client"][0]["Code LOINC"]==="Ne pas transcoder") ? true : false;

      if (this.state.chapterValue !== data[0]["presetChapter"]) this.changListCode(data[0]["presetChapter"])
      this.setState({
        dataClient:data,
        codeclient:data[0]["client"],
        codeclientselect:clientSelect,
        next:data[0]["next"],
        previous:data[0]["prev"],
        id:data[0]["id"],
        duplicatecard:"",
        showConfirmation:false,
        showSameEntry:false,
        donottranscode:donottranscode,
        end:false,
        firstLoad:false,
        chapterValue : this.state.chapterValue !== "-" ? data[0]["presetChapter"] : "-"
      });
    }
  }

  // fetchAllEntry(data){
  //   const options =  data["allcode"].map((v,k) => ({
  //     label: v.label,
  //     value: v.value,
  //     consensus : v.idconsensus,
  //     technidata : v.technidata,
  //     tag : v.tag,
  //    
  //   }))
  //   let position = []
  //   data["allcode"].forEach( (v,k)=> {
  //     position[v.value] = k
  //   })


  //   this.setState({allcode:options, allCodePosition : position})

  // }

   dataGet(data,type){

     let nameFile=""
     let typeName=""
     let urld
     let link
     switch (type){
      // case 'allentryanduntreated':
      //     this.fetchAllEntry(data)
      //     this.fetchData(data)
      //   break


      case 'end':
        this.setState({
          user:data["infos"]["nomexpert"],
          reject:data["refuse"] !== undefined ? data["refuse"] : undefined,
          end:true,
          client:data["nomclient"],
          dataexport:data["export"],
          questionnaireShow:false,
          isdsi:data["dsi"] !== undefined ? data["dsi"] : undefined,
          endInfos:data["infos"]
        })
        break
      case 'questionnaire':
        if (data["result"] === undefined || data["result"] === "none" || data["result"] === "done") {
          this.apiGET("/end","end")
        } else {
          if (data["result"] === "done"){
            this.apiGET("/end","end")
          } else {
            this.setState({
              questionnaireShow : true,
              question : data["questionnaire"],
              responseQuestion : data["reponse"]
            })
          }
        }
        break
      case 'data':
        this.fetchData(data)

     


      break;
      case 'allcode':
        const options =  data["allcode"].map((v,k) => ({
          label: v.label,
          value: v.value,
          consensus : v.idconsensus,
          technidata : v.technidata,
          tag : v.tag,
          chapter : v.chapter
        }))
        let position = []
        data["allcode"].forEach( (v,k)=> {
          position[v.value] = k
        })
        let chapitres = {}
        if (data["chapitres"] !== undefined) {
          chapitres = data["chapitres"].map((v,k) => ({
            label : v.label,
            value : v.value
          }))

        } 
        


        this.setState({allcode:options,allcodeOrigin : options,  allCodePosition : position, allchapitres : chapitres })

       
      break;
      case 'audit':
        let auditData = {...this.state.dataClient}
        auditData[0]["difficulté"]=data["difficulté"]
        this.setState({
          dataClient:Object.values(auditData)
        })
      break
      case "choice":
        if (data["reset"]===true) {

          const url = "/cardwchapter"
          const datas = {
            id :  data["client"],
            chapter : this.state.chapterValue
          }
          this.apiPUT(url,datas)


          // this.apiGET('/card/'+data["client"]);
          break;
        }
        let all = {...this.state.dataClient}
        if (this.props.alldata.etat==="validation client") {
          all[0]["Propositions LOINC"].map( (v,k) =>(
            all[0]["Propositions LOINC"][k]["sélectionné"]=false
          ))
        }

        all[0]["Propositions LOINC"][data["card"]]["sélectionné"]=data["choice"]
        all[0]["oneclictag"]=data["oneclictag"]
        this.setState({
          dataClient:Object.values(all),
          duplicatecard:"",
          showConfirmation:true,
          isChangNav:false
        })
        break;
        /*
      case 'nocode':
        //let notranscodable = {...this.state.dataClient}
        //notranscodable[0]["transcodable"]=data["transcodable"];
        this.setState({
          //dataClient:Object.values(notranscodable),
          isChangNav:false
        })
        this.takeUntreated()
        break;
        */
      case 'duplicate':
        this.setState({
          duplicatecard:data,
          select:this.defineSelect(data)
        })
        break;
      case 'newloinc':
        let dd = {...this.state.dataClient}
        dd[0]["Propositions LOINC"][data["numero"]]=data[0]

        this.setState({
          duplicatecard:data,
          select:this.defineSelect(data),
          dataClient:Object.values(dd)
        })
        break

      case 'exportcommandreco':

        this.setState({
          loading:false,
        });
        urld = window.URL.createObjectURL(new Blob([data]));
        link = document.createElement('a');
        link.href = urld;
        link.title = "Onaos";
        nameFile="Recommendations_Commentaires" + this.getCurrentDate()+".csv";
        link.setAttribute('download', nameFile);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(urld);

        break

      case 'result':
        urld = window.URL.createObjectURL(new Blob([data]));
        link = document.createElement('a');
        link.href = urld;
        link.title = "Onaos";

        switch (this.props.alldata.typebase){
          case 'resultats':
            nameFile="LOINC_6"
            typeName=this.state.dedalus ? "resultats" : "résultats"
          break
          case 'demandes':
            nameFile="LOINC_5"
            typeName="demandes"
          break
          case 'chapitres':
            nameFile="LOINC_4"
            typeName="chapitres"
          break
          default:
          break
        }
        switch(this.props.alldata.etat){
          case 'audit':
            nameFile="Audit_" + typeName + "_" + this.getCurrentDate()+".csv";
          break
          default:
            const bis = this.state.exportBis ? "bis_" : "_"
            nameFile=nameFile+bis+"transcodage_" + typeName + "_" + this.getCurrentDate()+".csv";
          break
        }

        if (this.state.exportComm) nameFile="Recommandations_Commentaires_"+typeName + "_"+this.getCurrentDate()+".csv";
        if (this.state.exportJournal) nameFile="JournalClient_"+nameFile;
        if (this.state.exportJournalExpert) nameFile="JournalExpertOnaos_"+nameFile;
        if (this.state.exportExclu) nameFile="NonTranscodes_"+nameFile;
        if (this.state.exportDoublon) nameFile="Doublon_"+nameFile;
        if (this.state.withbio) nameFile = "Tracabilite_transcodage_"+typeName + "_" + this.getCurrentDate()+".csv";
        if (this.state.truefull) nameFile = "Export_complet_"+nameFile 

        if (this.state.removeCodeFile) nameFile = "TranscodageASupprimer_"+typeName + "_" + this.getCurrentDate()+".csv";
        


        link.setAttribute('download', nameFile);
        
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(urld);
        this.setState({
          loading:false,
          exportComm:false,
          exportJournal:false,
          exportExclu:false,
          exportDoublon:false,
          exportJournalExpert:false,
          exportBis:false,
          withbio : false,
          truefull:false,
          dedalus : false,  
          fileNameDate : "",
          removeCodeFile:false
        });
      break
      case 'endvalidation':
        this.setState({
          loading:false
        });
      break
      case 'searchattribut':
        this.setState({
          searchData:this.defineSelect(data),
          showSearch:true
        })
      break
      case 'searchresult':
      this.setState({findLOINCRoller:false})
      
      if (data["history"]!=="" && data["history"]!==null &&  ( this.state.composantList===null ||  this.state.composantList.indexOf(data["history"]) ===-1)){
        if (this.state.composantList===null){
          this.setState({
            searchResult:data,
            composantList:[ data["history"]],
            resultPageNumber:1,
            resultPageData:this.resultPageSelect(data["result"])
          } )
        } else {
          this.setState({
            searchResult:data,
            composantList:[...this.state.composantList, data["history"]],
            resultPageNumber:1,
            resultPageData:this.resultPageSelect(data["result"])
          } )
        }


      } else {
        this.setState({
          searchResult:data,
          resultPageNumber:1,
          resultPageData:this.resultPageSelect(data["result"])
        })
      }

      break


      case 'searchcomposant':
          this.setState({
            showSearch:true,
            searchComposant:data
          })

        break
      case 'deleteComm':
        this.setState({
          allcommentaires :data
        })
        break
      case 'deleteReco':
        this.setState({
          allrecommandations :data
        })
        break
      default:
      break
     }
   }


resultPageSelect(data){
  let result = [{
    value : 1,
    label : 1
  }]
  if (data !== undefined &&  data.length>0) {
    for (let i=0;i<=(data.length/100);i++){
      result[i]={
        label : i+1,
        value : i+1
      }
    }
  }
  return result
}

   defineSelect(data){
     let dataListing=[]

     dataListing["listingunit"] =  Object.entries(data["listingunit"]).map((v,k) => (
       {
       label: v[1],
       value: v[1]
     }));
     dataListing["listingmilieu"] =  Object.entries(data["listingmilieu"]).map((v,k) => (
       {
       label: v[1],
       value: v[1]
     }));
     dataListing["listingtime"] =  Object.entries(data["listingtime"]).map((v,k) => (
       {
       label: v[1],
       value: v[1]
     }));
     dataListing["listingechelle"] =  Object.entries(data["listingechelle"]).map((v,k) => (
       {
       label: v[1],
       value: v[1]
     }));
     dataListing["listingtechnique"] =  Object.entries(data["listingtechnique"]).map((v,k) => (v[1]==="" || v[1]===null )? (
       {
         label: "Non renseignée dans le LOINC",
         value: "Non renseignée dans le LOINC"

     }) : (
       {
         label: v[1],
         value: v[1]
       }
     ));

     return dataListing
   }



  getCurrentDate(separator=''){
    if (this.state.fileNameDate !== "") {
      return  this.state.fileNameDate
    }
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`
  }




   exportCSV(state = null){
     let url=""
     state = (state === null)  ? this.props.alldata.etat : state
     this.setState({loading:true});
     switch (state){
       case "clientcomm":
       case "expertcomm":
        url = '/result/'+state
        this.setState({exportComm:true})
        break
       case "audit":
        url = '/result/audit'
       break
       case "validation expert":
        url = '/result/expert'
        this.setState({exportBis: false })
       break
       case "expert onaos":
        url = '/result/expertonaos'
        this.setState({exportBis: false })
       break
       case 'journal':
        url = '/result/journal'
        this.setState({exportJournal:true})
       break
       case 'journalexpert':
        url = '/result/journalexpert'
        this.setState({exportJournalExpert:true})
       break
       
       case 'exclus':
         url = '/result/exclus'
         this.setState({exportExclu:true})
         break
        case "doublon":
          url = '/result/doublon'
         this.setState({exportDoublon:true})
         break
        case "clientwoconsensus":
          url = '/result/clientwoconsensus'
       break  
       case "withbio":
        url = '/result/withbio'
        this.setState({withbio:true})

        break  
      case  "dedalus":
        url = '/result/dedalus'
        this.setState({dedalus:true, exportBis:false})
        break

      case  "withbiocgm":
        url = '/result/withbiocgm'
        this.setState({dedalus:true})
        break

      case  "truefull":
        url = '/result/truefull'
        this.setState({truefull:true, exportBis:true})
        break   
      case "removeUpdating":
        url = '/result/removeUpdating'
        this.setState({removeCodeFile:true})
        break;
      
      default:
        url = '/result/client'
        this.setState({exportBis:true})
       break

     }
     getAPI("/startdate",this.props.alldata.tokenrefresh,null).then(data => {
        this.setState({fileNameDate :data.date}, ()=> this.apiGET(url,'result','blob'))
    })
    
   
   }



   takeUntreated(){
     const url = '/untreated'
     this.apiGET(url);
   }

   getAllCode(){
     const url = '/getallentries';
     this.apiGET(url,'allcode');
   }


   sendChoice(codeclient,statut){
     let params = {
          "client":codeclient,
          'treated':statut,
          'basestatut':this.props.alldata.etat,
          'basetype':this.props.alldata.typebase,
          'chapitre':this.state.chapterValue
        };
        this.apiPUT('/treated',params);
        const statutSwitch = statut === "refusé" || statut === "accepté" ? "traité" : statut
        this.setTexteStateEntry(statutSwitch)
   }

   sendChoiceWithConf(){
     let params = {
          "client":this.state.confirmationEntry["client"],
          'treated':this.state.confirmationEntry["treated"],
          'basestatut':this.props.alldata.etat,
          'msg' : this.state.confirmationEntry["message"],
          'confirmation':'done',
          'basetype':this.props.alldata.typebase,
          'chapitre':this.state.chapterValue
        };
        this.apiPUT('/treated',params)
        this.getAllCode()
   }

  doDisconnect(){
    localStorage.removeItem("token");
    this.props.disconnect();
  }


  next(){
    const url = '/cardpn'
    const data = {
      code : this.state.next,
      chapitre : this.state.chapterValue,
      type : 'next',
      actualid : this.state.id
    }
    this.apiPUT(url,data);
    this.setState({isChangNav:true})
    window.scrollTo(0, 0);
  }

  last(){
    this.takeUntreated();
    this.setState({isChangNav:false})
    window.scrollTo(0, 0);
  }

  prec(){
    const url = '/cardpn'
    const data = {
      code : this.state.previous,
      chapitre : this.state.chapterValue,
      type : 'prev',
      actualid : this.state.id
    }
    this.apiPUT(url,data);

    this.setState({isChangNav:true})
    window.scrollTo(0, 0);
  }




  validationAudit(){
    this.sendChoice(this.state.id,'audit');
    window.scrollTo(0, 0);
  }


  accepter(){
    this.sendChoice(this.state.id,'accepté');
    window.scrollTo(0, 0);
  }

  refuser(){
    this.setState({
      attribut:"commRe",
      commentaire:this.state.dataClient[0]["commentairereject"],
      title:"Explication rejet proposition : ",
      showmodal:true,
    },()=>this.divMapper.current.focus());
  }

  passer(){
    this.sendChoice(this.state.id,'passé');   
    window.scrollTo(0, 0);
  }

  sendAnomalie(action, commentaire = ""){
    let params = {
      "id":this.state.id,
      'action':action,
      "comm":commentaire
    };

    this.apiPUT('/modifanomalie',params,'nothing');

 
  }


  anomalie(){
    const switchState = this.state.dataClient[0]["anomalie"] !== undefined ? !this.state.dataClient[0]["anomalie"]["state"] : false
    if (switchState) {
      this.setState({
        attribut:"commAnomalie",
        commentaire:this.state.dataClient[0]["anomalie"]["comm"],
        title:"Information concernant l'anomalie : ",
        showmodal:true,
      },()=>this.divMapper.current.focus());
    } else {
      this.sendAnomalie("setIsNOTAnAno")
      this.modifCommLocal("commAnomalie","setIsNOTAnAno")
    }
  }

  modifCommLocal(attribut,commentaire){
    let result="";
    let all = {...this.state.dataClient}
    switch (attribut){
      case "commAu":
        all[0]["commentaireaudit"]=commentaire
        result = Object.values(all);
        this.setState({dataClient:result})
        return true
      case "commEx":
        all[0]["commentaireexpert"]=commentaire
        result = Object.values(all);
        this.setState({dataClient:result})
        return true
      case "reco":
        all[0]["recommandation"]=commentaire
        result = Object.values(all);
        this.setState({dataClient:result})
        return true
      case "commCl":
        all[0]["commentaireclient"]=commentaire
        result = Object.values(all);
        this.setState({dataClient:result})
        return true
      case "commRe":
        this.sendChoice(this.state.id,'refusé');
        window.scrollTo(0, 0);
        return true
      case "commAnomalie":
        all[0]["anomalie"]["comm"] = commentaire === "setIsNOTAnAno" ? "" : commentaire
        all[0]["anomalie"]["state"] = commentaire === "setIsNOTAnAno" ? false : true
        result = Object.values(all);
        this.setState({dataClient:result})
        return true
      case "commConsensus":
        all[0]["commentaireconsensus"]=commentaire
        result = Object.values(all);
        this.setState({dataClient:result})
        return true
      default:
        return true
    }
  }

  hideModal(why){
    if (this.state.attribut === "commAnomalie"){
      this.sendAnomalie("anomalieW/Comm",why)
    } else {
      const url = '/commentaires';
      let params = {
           "client":this.state.id,
           'type':this.state.attribut,
           'commentaire':why,
         };
      this.apiPUT(url,params,"nothing");
    }
    this.setState({"showmodal":false}, () => this.divMapper.current.focus());
    this.modifCommLocal(this.state.attribut,why)
    


  }

  edit(who,why,title,modif){
    if (modif === undefined) modif = false
      this.setState({
        attribut:who,
        commentaire:why,
        title:title,
        showmodal:true,
        toEdit: modif
      });
  }

  deleteComm(who){
      const url = '/commentaires';
      let params = {
        "client":this.state.id,
        'type':who,
        'commentaire':"",
      };
      this.apiPUT(url,params,"nothing");
      this.modifCommLocal(who,"")
  }

  cancelEdit(){
    this.setState({showmodal:false},()=>this.divMapper.current.focus()) ;
  }

  onCardClick(param,loinc="", onlyOneCard = false){
    let statut = "";
    if (onlyOneCard) {
      statut = this.state.dataClient[0]["Propositions LOINC"][param]["sélectionné"] === true || this.state.dataClient[0]["Propositions LOINC"][param]["sélectionné"] === false ? "" : true
    } else {
      statut = !this.state.dataClient[0]["Propositions LOINC"][param]["sélectionné"]
    }
   // statut = this.state.dataClient[0]["Propositions LOINC"][param]["sélectionné"] === "" ? false :  this.state.dataClient[0]["Propositions LOINC"][param]["sélectionné"]
    let params = {
         "client":this.state.id,
         'card':param,
         'selection':statut,
         'loinc':loinc,
         'attribut':this.state.dataClient[0]["Propositions LOINC"][param]["attribut"],
         'trueposition':this.state.dataClient[0]["Propositions LOINC"][param]["trueposition"]
       };
    const url = '/selectcard';
    this.setTexteStateEntry("Non traitée")
    this.apiPUT(url,params,"choice");
  }

  navClientEnd(selectedOption){
    this.setState({end:false});
    const url = '/card/'+selectedOption.value;
    this.apiGET(url);
    this.setState({isChangNav:true})
    window.scrollTo(0, 0);
  }

  navClient = selectedOption => {

    // const url = '/card/'+selectedOption.value;
    // this.apiGET(url);
    const url = "/cardwchapter"
    const data = {
      id :  selectedOption.value,
      chapter : this.state.chapterValue
    }
    this.apiPUT(url,data)
    this.setState({isChangNav:true})
    window.scrollTo(0, 0);
  }


   doNotTranscode(){
    //  const url = '/donottranscode/'+this.state.id;
    //  this.apiGET(url);
    const url = '/donottranscodewchapter'
    const data = {
      id : this.state.id,
      chapitre : this.state.chapterValue
    }
    this.apiPUT(url, data)
     this.setTexteStateEntry("traité")
    }


    setTexteStateEntry(state){
    const tmp = {...this.state.allcode}
    const position = this.state.allCodePosition[this.state.id]

    const tmp2 = {...this.state.dataClient}
    tmp2[0].state = state

    if (tmp[position] !== undefined && tmp[position].tag !== state) {
      tmp[position].tag = state
      this.setState({
        allcode : Object.values(tmp),
        dataClient : Object.values(tmp2),
      })

    }
    
  }


  onBtnClick(name){
    switch(name){
      case "removeEntry":
        window.confirm("Êtes-vous sur de vouloir supprimer cette entrée ? Attention aucun retour possible !") && getAPI("/removeentryveille/"+this.state.id,this.props.alldata.tokenrefresh).then(data => {
          //remove entry from all ID
          this.getAllCode()
          this.next()
        } )
        return true
      case 'confirmationNo':
        this.setState({showConfirmationValidation:false})
        return true
      case 'confirmationYes':
        this.setState({showConfirmationValidation:false})
        this.sendChoiceWithConf();
        return true
      case 'notfindYes':
        this.addLoincWithConf()
        return true
      case 'notfindNo':
      this.setState({
        notFind:false,
      })
        return true
      case "quit":
        this.doDisconnect()
        return true
      case "valider":
        if (this.state.donottranscode){
          this.doNotTranscode()
        } else {
          if (this.props.alldata.etat==="validation client" &&
            this.state.dataClient[0]["oneclictag"]["allreject"]===true &&
          Object.entries(this.state.dataClient[0]["Propositions LOINC"]).length>1) {
            this.refuser()
          } else {
            this.accepter()
          }
        }
        this.setState({isChangNav:false})
        return true
      case "anomalie":
        this.anomalie()
        return true
      case "passer":
        this.passer()
        this.setState({isChangNav:false})
        return true
      case "refuser":
        this.refuser()
        this.setState({isChangNav:false})
        return true
      case "prev":
        this.prec()
        return true
      case "next":
        this.next()
        return true
      case "last":
        this.last()
        return true
      case 'valideraudit':
        this.validationAudit()
        return true
      case 'untranscodable':
        this.setState({isChangNav:false})
        if (this.state.dataClient[0]["transcodable"]==="oui" || this.state.dataClient[0]["transcodable"]==="Absent du LOINC" ) {
          this.noCode("Non transcodable")
        } else {
          this.noCode("oui")
        }
        return true
      case 'outloinc':
        this.setState({isChangNav:false})
        if (this.state.dataClient[0]["transcodable"]==="oui" || this.state.dataClient[0]["transcodable"]==="Non transcodable") {
          this.noCode("Absent du LOINC")
        } else {
          this.noCode("oui")
        }
        return true
      case 'donottranscode':
        this.setState({isChangNav:false})
        this.setTexteStateEntry("Non traitée")
        this.setState({donottranscode:!this.state.donottranscode},()=>this.sendUntreated())
        
        return true
      case 'entryYes':
        this.setState({
          showSameEntry:false
        })
        this.duplicateEntry();
        return true
      case 'entryReset':
        this.setState({
          showSameEntry:false
        })
        this.duplicateEntry("reset");
        return true
      case 'entryNo':
        this.setState({
          showSameEntry:false
        })
        this.sendChoice(this.state.id,"force")
        return true
      case 'entryCancel':
        this.setState({
          showSameEntry:false,
        })
        return true

      case 'acceuil':
        this.props.showDashboard(true)
        return true

      default:
        return true
    }
  }

  sendUntreated(){
    this.apiGET("/setuntreated/"+this.state.id,'nothing')
    this.setTexteStateEntry("Non traitée")

  }

  duplicateEntry(action = "validation"){
    let params = {
         "data":this.state.sameEntry,
         action : action,
         chapitre:this.state.chapterValue
       };
       this.apiPUT('/duplicatevalidation',params);
  }

  noCode(value){
    let params = {
         "client":this.state.id,
         'basestatut':this.props.alldata.etat,
         'value':value,
         chapitre : this.state.chapterValue
       };
       this.apiPUT('/nocode',params);
  }


  onCheck(name,shortcut=false){
    const url = '/audit';
    let params = {
         "client":this.state.id,
         'notation':name,
         'shortcut':shortcut
       };
    this.apiPUT(url,params);

  }

  findNewLOINC(oldloinc,numero,attribut,newval){
    const url = '/newloinc';
    let params = {
      "oldloinc":oldloinc,
      "numero":numero,
      "attribut":attribut,
      'newval':newval
    }
    this.apiPUT(url,params,'newloinc');
  }


  findDuplicate(who,composant,loinc){
    const url = '/duplicate';
    let params = {
      "Composant":composant,
      'who':who,
      'loinc':loinc
    }
    this.apiPUT(url,params,'duplicate');
  }

  duplicateCancel(){
    const url = '/card/'+this.state.id;
    this.apiGET(url);
    window.scrollTo(0, 0);

  }

  addAfterSearch(data){
    const url = '/addloinc';
    let params = {
         "client":this.state.id,
         'newcode':data,
         chapitre:this.state.chapterValue
    };
    this.apiPUT(url,params,'data');
    window.scrollTo(0, 0);
  }

  addLoincWithConf(){
    const url = '/addloinc';
    let params = {
         client : this.state.id,
         newcode : this.state.notFindCode,
         reponse : true,
         chapitre:this.state.chapterValue
       };
    this.apiPUT(url,params,'data');
    this.setState({
      notFind:false
    })
    window.scrollTo(0, 0);
  }

  addAfterMultiSearch(data){
    const url = '/addloinc';
    let params = {
         "client":this.state.id,
         'allcode':data,
         'multi':"oui",
         chapitre:this.state.chapterValue
       };
    this.apiPUT(url,params,'data');

    this.setState({
      searchResult:[],
      donottranscode:false,
    })
  }


  showSearch(state){
    if (state){
      const url = '/findcomposant/'+this.state.id
      this.apiGET(url,'searchcomposant')

    } else {
      
      this.setState({
        showSearch:state,
        searchResult:[],
      },()=> this.divMapper.current.focus())
    }
  }


  panelSearch(loinc,type){

      this.setState({
        showSearch:true,
        searchComposant:{
          Composant : loinc,
          type : type
        }
      })


  }

  findLOINC(attributs=[]){
    //if (attributs["composant"]!==""){
      const url = '/findloinc'
      let params = {
           "data":attributs,
           "client":this.state.id,
         };
        this.setState({findLOINCRoller : true})
      this.apiPUT(url,params,'searchresult')
    //}
  }



  onkeypress = (e) => {


    if (this.state.notFind===false && !this.state.questionnaireShow && this.state.showConfirmationValidation===false && this.state.showAdd===false && this.state.showSameEntry===false && this.state.end===false && this.state.showmodal===false && this.state.showSearch===false ){
 

      if (document.activeElement !== this.inputClient.current.select.inputRef){
        if (this.props.alldata.etat==="audit"){
          if (e.key === '1'){
            this.onCheck("1",true)
          }
          if (e.key === '2'){
            this.onCheck("2",true)
          }
          if (e.key === '3'){
            this.onCheck("3",true)
          }
          if (e.key === '4'){
            this.onCheck("4",true)
          }
          if (e.key === '5'){
            this.onCheck("5",true)
          }
        } else {
          if (e.key === 'v'){
            this.onBtnClick("valider")
          }
          if (e.key === 'p'){
            this.onBtnClick("passer")
          }
          if (e.key === 'r' && this.props.alldata.etat==="validation client"){
            this.onBtnClick("refuser")
          }
        }
        if (e.key === 'ArrowLeft'){
          this.prec()
        }
        if (e.key === 'ArrowRight'){
          this.next()
        }


      }


    }
  }

  onPageChange(page){
    this.setState({resultPageNumber:page.value})
  }

  showAdd(state){
    this.setState({showAdd:state})
  }

  selectCommChange(valeur,tag,add){
    let allComm =  [...this.state.allcommentaires]
    let allReco = [...this.state.allrecommandations]
    if (add === true){
      if (tag === "commEx"){
        allComm.push({
          value : valeur,
          label : valeur
        })
      } else {
        allReco.push({
          value : valeur,
          label : valeur
        })
      }
    }

    this.setState({
      commentaire:valeur,
      allcommentaires:allComm,
      allrecommandations:allReco
    })
  }

  clearSelect(valeur,tag){
    const url = "/deletecommreco"
    const action = tag === "commEx" ? "deleteComm" : "deleteReco"
    const data={
      type: tag,
      valeur : valeur
    }

    this.apiPUT(url,data,action)
  }

  duplicationCheck(e,position){
    const tmp = {...this.state.sameEntry}

    tmp["entry"][position]["check"] = e.target.checked
    this.setState({sameEntry:tmp})
  }

  allCheck(e,position){
    const tmp = {...this.state.sameEntry}
    tmp["entry"].forEach((item, i) => {
      if (i!==position) item.check = e.target.checked
    });
    this.setState({sameEntry:tmp,allChecked:e.target.checked})
  }

  getTypeBaseText(typeB){
    if (typeB === "chapitres") return "Chapitres"
    if (typeB === "demandes") return "Demandes"
    if (typeB === "resultats") return "Résultats"

    
  }


  keyboard(state){
    this.setState({keyboard : state})
  }


  questionValidation(value,position,text){
    const data = {
      position:this.state.question.position,
      value:value,
      text:text,
      numeroreponse:position
    }
    this.apiPUT("/questionnaireresponse",data,"questionnaire")

  }

  questionPrevious(){

    const position = this.state.question !== undefined &&  Number(this.state.question.position)>0 ? Number(this.state.question.position) -1 : 1
    this.apiGET("/questionnaireend/"+position,"questionnaire")
  }

  questionStart(){
    this.apiGET("/questionnaireend/1","questionnaire")
  }

  questionEnd(){
    this.apiGET("/end","end")
  }

  render(){

    const typebase = this.getTypeBaseText(this.props.alldata.typebase) 

    const typeBaseText = "Dictionnaire de "+typebase
    return(
      <div onKeyDown={this.onkeypress} tabIndex={0} style = {{outline:"none"}} ref={this.divMapper} >
          {this.state.keyboard && 
            <KeyBoard baseStatut = {this.props.alldata.etat} close = {this.keyboard}/>
          }

          { this.state.showmodal===true   &&
            <Modal
              baseStatut={this.props.alldata.etat}
              cancel={this.cancelEdit}
              value={this.state.commentaire}
              show={this.state.showmodal}
              title={this.state.title}
              onRegister={this.hideModal}
              expertallcomm = {this.state.allcommentaires}
              expertallreco = {this.state.allrecommandations}
              type = {this.state.attribut}
              selectCommChange = {this.selectCommChange}
              alldata = {this.props.alldata}
              clearSelect = {this.clearSelect}
              toEdit = {this.state.toEdit}
              isVeille = {this.state.dataClient[0] !== undefined ? this.state.dataClient[0]["veille"] : false}
            />
          }

          {this.state.showSameEntry===true &&
            <DuplicateValidation type="duplication" data={this.state.sameEntry} onClick={this.onBtnClick} onCheck = {this.duplicationCheck} allCheck={this.allCheck} allChecked={this.state.allChecked} />
          }

          {this.state.showConfirmationValidation===true &&
            <DuplicateValidation type="confirmation" data={this.state.confirmationEntry} onClick={this.onBtnClick} />
          }

          {this.state.notFind===true &&
            <DuplicateValidation type="notfind" data={this.state.confirmationEntry} onClick={this.onBtnClick} />
          }

          { this.state.showSearch===true &&

            <SearchLOINC
              show={this.showSearch}
              dataClient={this.state.dataClient}
              findLOINC={this.findLOINC}
              state={this.state}
              searchEpreuve={this.state.searchEpreuve}
              validation={this.addAfterMultiSearch}
              searchComposant={this.state.searchComposant}
              composantList={this.state.composantList}
              basetype={this.props.alldata.typebase}
              baseStatut={this.props.alldata.etat}
              resultPageNumber={this.state.resultPageNumber}
              resultPageData={this.state.resultPageData}
              onPageChange={this.onPageChange}
              findLOINCRoller = {this.state.findLOINCRoller}
              />

          }


          { this.state.end===true &&
            <EndForm  onChange={this.navClientEnd}
                data={this.state.allcode}
                dataexport = {this.state.dataexport}
                reject={this.state.reject}
                onExport={this.exportCSV}
                disconnect={this.doDisconnect}
                showLoading={this.state.loading}
                statut={this.props.alldata.etat}
                basetype={this.props.alldata.typebase}
                client={this.state.client}
                user={this.state.user}
                downloadComm={this.exportComm}
                sendCopy={this.sendCopy}
                showDashboard={this.props.showDashboard}
                isVeille = {this.state.endInfos !== undefined ? this.state.endInfos["isInUpdate"] : false}
            />
          }

          { this.state.questionnaireShow &&
            <MainQuestion  
                data={this.state.question}
                questionValidation = {this.questionValidation}
                previous = {this.questionPrevious}
                start = {this.questionStart}
                end = {this.questionEnd}
                dataResponse={this.state.responseQuestion}
            />
          }

          { this.state.end!==true && !this.state.questionnaireShow && this.state.showSearch===false && this.state.dataClient[0]!==undefined &&
            <div className="background" >
              <div className="base_type">
                <p className="p_base_type">{typeBaseText}</p>
                <img className="logo_onaos_mapping" src={Logo} alt="Logo Onaos"/>

                {/*
                <div className = "home" onClick={()=>this.props.showDashboard(true)}></div>
                <p className="p_base_type">{typeBaseText}</p>
                {this.props.alldata.etat === "validation client" && 
                  <NavButton isDefine={true} tooltip="tuto" tooltipText = "Besoin d'aide ?" onClick={this.props.showTuto} text="Tutoriel" styles="btn_tuto" />
                }
                */}

                
              </div>
              <div className="container_onaos">
                <ClientMap
                  etat = {this.props.alldata.etat}
                  value = {this.state.dataClient[0]}
                  state = {this.state}
                  selectChange = {this.navClient}
                  onClick={this.onBtnClick}
                  donottranscode={this.state.donottranscode}
                  reference = {this.inputClient}
                  basestatut={this.props.alldata.etat}
                  typebase = {this.props.alldata.typebase}
                  chapterOnChange = {this.onChapterChange}

                />

                <MultiMap
                  number={Object.entries(this.state.dataClient[0]["Propositions LOINC"]).length}
                  loincData={this.state.dataClient[0]}
                  click={this.onCardClick}
                  basestatut={this.props.alldata.etat}
                  baseinfos={this.props.alldata}
                  duplicatecard={this.state.duplicatecard}
                  select={this.state.select}
                  findDuplicate={this.findDuplicate}
                  findNewLOINC={this.findNewLOINC}
                  duplicateCancel={this.duplicateCancel}
                  addAfterSearch={this.addAfterSearch}
                  showSearch={this.showSearch}
                  showDashboard={this.props.showDashboard}
                  donottranscode={this.state.donottranscode}
                  showAdd = {this.showAdd}
                  showAddVal = {this.state.showAdd}
                  panelSearch = {this.panelSearch}
                  showTuto = {this.props.showTuto}
                />

                <MiddlePage
                  data={this.state.dataClient[0]}
                  checkButton={this.onCheck}
                  onClick={this.onBtnClick}
                  state={this.state}
                  baseStatut={this.props.alldata.etat}
                  onEdit={this.edit}
                  showConfirmation={this.state.showConfirmation}
                  donottranscode={this.state.donottranscode}
                  deleteComm = {this.deleteComm}
                 />
                 <BootProgress
                   data = {this.state.dataClient[0]}
                   basetype={this.props.alldata.typebase}
                 />

                <MenuClient 
                  homeAction = {()=>this.props.showDashboard(true)}
                  tutoAction = {this.props.showTuto}
                  guideAction = {this.props.downloadPDF}
                  guidetranscodage = {this.props.downloadPDFGuide}
                  keyboard = {this.keyboard}
                  bioloinc = {this.props.bioloinc}
                />
              </div>

            </div>
          }
      
      </div>
    )
  }
}

export default Mapping;
