import React from 'react'
import "./invalid.css"

class Invalid extends React.Component  {

  componentDidMount(){
    document.body.classList.remove("body_back");
  }

  render(){
    return(
      <React.Fragment>
        <div className="container_invalid_page">
          <div>
            <img className="invalid_img" src="img_invalid_page.svg" alt="Lien" />
          </div>
          <div className="container_txt">
            <h1>Lien invalide</h1>
            <p>Merci de contacter notre service client si nécessaire</p>
            <div className="container_return_validation_invalid">
                <div className="return_button_validation" onClick = {() =>   {window.location.href = "/" }}></div>
                <p>Aller à la page d'accueil</p>
              </div>
          </div>
        </div>
      </React.Fragment>

    )
  }
}


export default Invalid;
