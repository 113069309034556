import React from 'react'
import './clientlisting.css'
import ReactTooltip from 'react-tooltip';




class Progressinfos extends React.Component  {

  getText(isReview){
    if (isReview === "boldreview")  return "Base en relecture"
    if (isReview === "redreview") return "Base livrable"
    if (isReview === "greenreview") return "Base livrée"

    return ""
  }

  render(){

    const number = this.props.number
    const client = this.props.client
    const data = this.props.data
    let isReview = this.props.isReview !== undefined && this.props.isReview ? "boldreview" :""
    isReview = this.props.isReview !== undefined && this.props.isReview && data.infoclient[client].progression[number] === 100 ? "redreview" : isReview
    isReview = isReview  !== "" && data.infoclient[client].statut[number] === "validation client" ? "greenreview": isReview
    const text = this.getText(isReview)

    return (
      <React.Fragment>
      {data.infoclient[client].progression[number]!=="-" &&
        <p className="interligne alignement" data-tip data-for={ "infos_"+client+number} >
          Base   <span className="characters">{data.infoclient[client].typebase[number]}</span>
          {" "}({data.infoclient[client].nombre[number]["nbrcarte"]}) : {" "}
          <span className="characters">{data.infoclient[client].statut[number]}</span>
          <span className={isReview}  > {" "+data.infoclient[client].progression[number]+"%"}</span>
          {text !== "" && 
            <ReactTooltip id={"infos_"+client+number} type="info" effect="float" >
              { text  }
            </ReactTooltip>
          }

        </p>
      }

      </React.Fragment>





    )
  }
}

export default Progressinfos
