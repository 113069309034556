import React from 'react';

class InputField extends React.Component {
  constructor(props){
      super(props);
      this.handleKeyPress = this.handleKeyPress.bind(this)
    }

    handleKeyPress = (e) => {
      if (e.key === 'Enter') {
        this.props.onKeyPress();
      }
    }

    getClass(){
      return this.props.styles !== undefined ? this.props.styles : "input"
    }

    render(){

        return(
            <div className="inputField">
                <input
                    className={this.getClass()}
                    onKeyPress={this.handleKeyPress}
                    type={this.props.type}
                    placeholder={this.props.placeholder}
                    defaultValue={this.props.value}
                    onChange={ (e) => this.props.onChange(e.target.value) }
                    disabled = {this.props.disabled!==undefined && this.props.disabled===true ? true : false}
                />
            </div>
        );
    }
}

export default InputField;
