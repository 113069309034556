import React from 'react';
import './page.css';



class Page extends React.Component {


  render(){
    const url = process.env.PUBLIC_URL + '/tuto/'+this.props.numero+'.png'
    const divStyle = {
         backgroundImage : "url("+url+" )",
         width:'100%',
         height:'100%',
         backgroundSize:'cover',
         backgroundRepeat: 'no-repeat',
         backgroundPosition: 'center',
     };

    return (
      <div className="page_anim" style={divStyle}></div>
    )
  }

}


export default Page
