import '../clientmap/clientmap.css';
import './SearchLOINC.css';
import React from 'react';
import SearchResult from "../searchresult/SearchResult.js";
import SearchBox from "../searchbox/searchbox.js";

import ClientEntry from "../cliententry/cliententry.js"
import SideBar from "../sidebar/sidebar.js"

import { Roller } from 'react-awesome-spinners'


class SearchLOINC extends React.Component {

  constructor(props) {
     super(props);
     this.state = {
       attributs : {
         order:{
           "Composant":true
         }
       },
       oldSearch:[],
       select: [],
       sidebarOpen:false,
       showlisthistory:false,
       redfilter:false,
       showBackPanel:false,
       autoFilterMedium:false,
       autoFilterGrandeur:false,


     }
     this.addFilter = this.addFilter.bind(this)
     this.onCardClick = this.onCardClick.bind(this)
     this.validation = this.validation.bind(this)
     this.closeList = this.closeList.bind(this)
     this.findPanel = this.findPanel.bind(this)
     this.backSearch = this.backSearch.bind(this)
     this.nabmClick = this.nabmClick.bind(this)
     this.findAssos = this.findAssos.bind(this)
     this.autoFilterChange = this.autoFilterChange.bind(this)
  }

  defineGrandeur() {
    let tmp = {...this.state.attributs}
    const grandeurDefine =  this.props.dataClient[0]!== undefined &&  this.props.dataClient[0]["onaos_search"]!==undefined &&  this.props.dataClient[0]["onaos_search"]["onaos_grandeur"] !== undefined && this.props.dataClient[0]["onaos_search"]["onaos_grandeur"].length>0 ? true : false
    if (grandeurDefine){
      tmp["Grandeur"]  = {}
      this.props.dataClient[0]["onaos_search"]["onaos_grandeur"].forEach((v, k)=>{
        tmp["Grandeur"][v]=true
      })
    }
    return tmp["Grandeur"] !==undefined ? tmp["Grandeur"] : {}
  }


  defineMilieu(){
    let tmp = {...this.state.attributs}
    const mediumDefine =  this.props.dataClient[0]!== undefined &&  this.props.dataClient[0]["onaos_search"]!==undefined && this.props.dataClient[0]["onaos_search"]["onaos_milieu"] !== undefined && this.props.dataClient[0]["onaos_search"]["onaos_milieu"].length>0 ? true:false
    if (mediumDefine){
      tmp["Milieu biologique"]  = {}
      this.props.dataClient[0]["onaos_search"]["onaos_milieu"].forEach((v, k)=>{
        tmp["Milieu biologique"][v]=true
      })
    }
    return tmp["Milieu biologique"]!==undefined ? tmp["Milieu biologique"] : {}
  }


  autoFilterChange(typeCheck){
    const valFilterMedium = typeCheck === "medium" ?  !this.state.autoFilterMedium : this.state.autoFilterMedium
    const valFilterGrandeur = typeCheck === "grandeur" ?  !this.state.autoFilterGrandeur : this.state.autoFilterGrandeur

    

    let tmp = {...this.state.attributs}
    let redfilter = this.state.redfilter
    
    if (valFilterGrandeur) {
      const grandeur = this.defineGrandeur()
      if (grandeur !== undefined && Object.values(grandeur).length>0) {
        tmp["Grandeur"] = grandeur
        redfilter = true
      }
    } else {
      tmp["Grandeur"] = {}
      redfilter = false
    }
    if (valFilterMedium) {
      const medium = this.defineMilieu()
      if (medium !== undefined && Object.values(medium).length>0) {
        tmp["Milieu biologique"] = medium
        redfilter = true
      }
    } else {
      tmp["Milieu biologique"] = {}
    }

    if (!valFilterGrandeur && !valFilterMedium){
      redfilter=false
      tmp["Grandeur"] = {}
      tmp["Milieu biologique"] = {}
    }

    this.setState({autoFilterMedium:valFilterMedium,autoFilterGrandeur:valFilterGrandeur, attributs:tmp, redfilter:redfilter},()=>this.reg())


  }

  componentDidMount() {

    let tmp = {...this.state.attributs}
    let redfilter = this.findSelect(this.state.attributs)
    let autoFilterGrandeur = this.state.autoFilterGrandeur
    let autoFilterMedium = this.state.autoFilterMedium


    if (this.props.searchComposant.Composant!==undefined && this.props.searchComposant.Composant!==""){
      tmp["composant"]=this.props.searchComposant.Composant
    }
    if (this.props.searchComposant.type!==undefined && this.props.searchComposant.type!==""){
      tmp["type"]=this.props.searchComposant.type
    }

    
    if (this.props.baseStatut==="validation client") {
      if (tmp["composant"]!==undefined && tmp["composant"]!==""  ){
        tmp.order={
          "Circuit de biologie":false
        }
        const objTrue = { "oui":true }
     //   if (this.props.basetype === "resultats") tmp["Circuit bio. résultats"] = objTrue
     //   if (this.props.basetype === "demandes") tmp["Circuit bio. demande"] = objTrue
     //   if (this.props.basetype === "chapitres") tmp["Circuit bio. chapitre"] = objTrue
     //   redfilter = true
      } else {
        redfilter = false
      }
    } /*else {
      const grandeur = this.defineGrandeur()
      if (grandeur !== undefined && Object.values(grandeur).length>0) {
        tmp["Grandeur"] = {}
        tmp["Grandeur"] = grandeur
        redfilter = true
        autoFilterGrandeur = true
      }
      const medium = this.defineMilieu()
      if (medium !== undefined && Object.values(medium).length>0) {
        tmp["Milieu biologique"] = {}
        tmp["Milieu biologique"] = medium
        redfilter = true
        autoFilterMedium = true
      }
    }*/
  

     this.setState({attributs:tmp,redfilter:redfilter, autoFilterMedium:autoFilterMedium, autoFilterGrandeur: autoFilterGrandeur},()=>this.autoReg())
   }

   autoReg(){
    if (this.props.searchComposant.Composant !== undefined &&this.props.searchComposant.Composant !== "") this.reg()
   }

  reg() {

  //  if (this.state.attributs!==undefined && this.state.attributs.composant!==undefined && this.state.attributs["composant"].length>0){
      this.props.findLOINC(this.state.attributs)
      let oldSearch = [...this.state.oldSearch]
      oldSearch.push(this.state.attributs)
      const backbtn = this.state.oldSearch.length>0 ? true : false //TODO reset uniquement quand fermé ?
      this.setState({
        select:[],
        sidebarOpen:false,
        oldSearch:oldSearch,
        showBackPanel:backbtn,
      })
    //}
  }


  closeList(close=true){
    this.setState({showlisthistory:!close})
  }


  findSelect(data){
    const filter = Object.entries(data).filter((v,k)=> v[0] !== "order" && v[0] !== "composant")
    const nbr = Object.values(filter).map ( (v,k) => Object.values(v[1]).filter((vv,kk) => vv === true  ) ).filter((v,k)=> v[0] === true)
    return nbr.length >0 ? true : false
  }

  findPanel(code){
    let tmp = {...this.state.attributs}
    tmp={}
    tmp["order"]={}
    tmp["order"]["Composant"]=true
    tmp["composant"] = code
    tmp["type"] = "panel"
    this.setState({
      attributs:tmp,
    },
      ()=> this.reg())
  }

  findAssos(code,type){
    if (this.props.basetype !== "chapitres"){
      let tmp = {...this.state.attributs}
      tmp={}
      tmp["order"]={}
      tmp["order"]["Composant"]=true
      tmp["composant"] = code
      const basetype = this.props.basetype === "resultats" ? "demande" : "resultat"
      tmp["type"] = type === "assos" ? "asso_"+basetype : type
      this.setState({
        attributs:tmp,
      },
        ()=> this.reg())
    }
  }

  nabmClick(nabm){
    let tmp = {...this.state.attributs}
    tmp={}
    tmp["order"]={}
    tmp["order"]["Composant"]=true
    tmp["composant"] = "NABM:"+nabm
    tmp["type"] = ""
    this.setState({
      attributs:tmp,
    },
      ()=> this.reg())
  }

  addFilter(what,data,autosearch){
    let tmp = {...this.state.attributs}
    if (data!==null){
      if (what!=="composant"){
        if (tmp[what]===undefined) tmp[what]={}
        const val=( tmp[what][data]===undefined) ? true : !tmp[what][data]

        if (what==="order") {
          tmp[what]={}
          tmp[what][data]=val
        } else {
          tmp[what][data]=val
        }

      } else {
        tmp[what]=data
        tmp["type"]=""
      }
      if (autosearch){
        this.setState({
          attributs:tmp
        },
          ()=> this.reg())
      } else {
        this.setState({attributs:tmp})
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    

    if (this.state.attributs!==prevState.attributs){
      const selectFil =  this.findSelect(this.state.attributs)
      this.setState({redfilter:selectFil})
    }
    /*
    if (this.props!==prevProps){
      if (this.props.state.searchResult["attribut"]===undefined || this.props.state.searchResult["attribut"].length===0){
        this.reset(false)
      }
    }*/
  }

  reset(reload=true){
    let tmp={}
    tmp["order"]={}

    if (this.props.baseStatut==="validation client") {
      tmp["order"]["Circuit de biologie"]=false
    } else {
      tmp["order"]["Composant"]=true
    }
    tmp["composant"]=this.state.attributs.composant
    if (tmp["composant"]!=="" && tmp["composant"]!==undefined){
      if (reload){
        this.setState({attributs:tmp,        
          autoFilterMedium:false,
          autoFilterGrandeur:false, 
          redfilter:false},()=> this.reg())
      } else {
        this.setState({attributs:tmp,       
          autoFilterMedium:false,
          autoFilterGrandeur:false,
          redfilter:false})
      }
    } else {
      this.setState({attributs:tmp,        
        autoFilterMedium:false,
        autoFilterGrandeur:false, 
        redfilter:false})
    }
  }

  cancel(){
    this.setState({select:[]})
    this.props.show(false);
  }

  handleKeyPress = (e) => {
    if (e.key === 'Escape' ) {
      if (this.state.sidebarOpen===true){
        this.setState({sidebarOpen:false})
      } else {
        this.cancel();
      }
    }
    if (e.key==="Enter" && this.getIfOneClick()===true){
      this.validation()
    }
  }

  onCardClick(number){
    let sel = {...this.state.select}
    if (sel[number] !== undefined && sel[number]==="select") {
      sel[number]=""
    }  else {
      sel[number]="select"
    }
    this.setState({select:sel})
  }

  validation(){
    this.props.validation(this.state.select)
    this.cancel()
  }

  getIfOneClick(){
    let result=false
    const dat = this.state.select
    Object.entries(dat).forEach((item, i) => {
      if (item[1]==="select" && result===false) {
        result=true
      }
    });
    return result
  }



  showFilters(){
    if (this.state.attributs ){//&&  this.state.attributs.composant){
      this.setState({sidebarOpen:!this.state.sidebarOpen})
    } else {
      this.setState({sidebarOpen:false})
    }
  }

  backSearch(){
    const oldSearch = [...this.state.oldSearch]

    let showBackPanel = true;
    if (oldSearch.length>1) {
      oldSearch.pop()
    }
    if (oldSearch.length === 1) {
      showBackPanel = false
    }



    this.setState({
      oldSearch:oldSearch,
      showBackPanel : showBackPanel,
      attributs:oldSearch[oldSearch.length-1]
    },()=>{this.props.findLOINC(oldSearch[oldSearch.length-1])})
  }


  render(){

    return (

        <div className="modal_main_search"  onKeyDown={this.handleKeyPress} tabIndex={0} >
        <h1 className="title_client_search result_center center">Recherche de codes LOINC</h1>
          <div className="container_search">
            <div className="container_title_search">

              <SearchBox
                state = {this.state}
                addFilter = {this.addFilter}
                search = {()=>this.reg()}
                cancel = {()=>this.cancel()}
                reset = {()=>this.reset()}
                validation = {this.validation}
                getIfOneClick = {this.getIfOneClick()}
                showFilters={()=>this.showFilters()}
                composantList={this.props.composantList}
                showlisthistory={this.state.showlisthistory}
                closeList={this.closeList}
                redfilter={this.state.redfilter}
                showBackPanel = {this.state.showBackPanel}
                backSearch = {this.backSearch}
                autoFilterChange = {this.autoFilterChange}
                autoFilterMedium = {this.state.autoFilterMedium}
                autoFilterGrandeur = {this.state.autoFilterGrandeur}
                showAutoFilter={this.props.baseStatut === "validation expert"}
              />
            </div>

            <SideBar
              sidebarOpen={this.state.sidebarOpen}
              data={this.props.state.searchResult["attribut"]}
              addFilter = {this.addFilter}
              checked = {this.state.attributs}
            />

            <div className="container_bloc_input">
              <ClientEntry
                data = {this.props.dataClient[0]["Entrée complète client"]}
                styles="container_client search_client"
                testaudit={this.props.dataClient[0]}
                stylesLine="sizing_entree_search"
                title="Locale"
                typebase = {this.props.basetype}
                nabmClick = {this.nabmClick}
              />

              {this.props.findLOINCRoller &&
                <div className="findloinc_roller">
                  <Roller color="#f9af2e" />
                </div>
              }
              <SearchResult
                data = {this.props.state.searchResult}
                onclick = {this.onCardClick}
                selectData = {this.state.select}
                basestatut = {this.props.basetype}
                attributs = {this.state.attributs}
                addFilter = {this.addFilter}
                dropdownOrder={this.state.attributs["order"]}
                onPageChange={this.props.onPageChange}
                resultPageNumber = {this.props.resultPageNumber}
                resultPageData = {this.props.resultPageData}
                validation = {this.validation}
                getIfOneClick = {this.getIfOneClick()}
                findPanel = {this.findPanel}
                findAssos = {this.findAssos}
                />
            </div>
            </div>
        </div>


    );
  }
};

export default SearchLOINC;
