import React from 'react'
import ReactTooltip from 'react-tooltip'

import InterligneSpan from '../interligne/interligneSpan.js'


export const LoincVeille = (props) => {

    const idTooltip = "veilleGold_" + Math.random().toString(36)

    const text = props.date ? "Code LOINC choisi  le " + props.date +  " lors de la <br/>primo-transcodification du laboratoire<br/>Non sélectionnable" : "Code LOINC choisi lors de la <br/>primo-transcodification du laboratoire<br/>Non sélectionnable"

    const cardStyle = {
        padding : "27px 1%",
        marginRight: "20px",
        marginBottom: "20px",
        width: "300px",
        backgroundColor:"rgba(206, 206, 196, 0.5)",
        borderRadius: "20px",
        boxShadow: "0px 2px 4px 0px rgba(0, 0, 0, 0.2)",
        display : "flex", 
        flexDirection : 'column',
        gap : "16px"
    }

    return(
        <div  data-tip data-for = {idTooltip} style = {cardStyle}>
        
            <InterligneSpan styles="center" label="" value = {props.data.Composant} />
            <InterligneSpan styles="center" label="" value = {props.data["Code LOINC"]} />
            <hr style = {{margin : "0 auto"}}></hr>
            <InterligneSpan 
              styles="alignement" 
              label="Grandeur" 
              value = {props.data.Grandeur} 
            />
            <InterligneSpan 
                styles="alignement" 
                label="Milieu" 
                value = {props.data['Milieu biologique']} 
            />
            <InterligneSpan 
                styles="alignement" 
                label="Temps" 
                value = {props.data.Temps} 
            />
            <InterligneSpan 
                styles="alignement" 
                label="Échelle" 
                value = {props.data.Echelle} 
            />
            <InterligneSpan 
                styles="alignement" 
                label="Technique" 
                value = {props.data.Technique === null ? "Non renseignée dans le LOINC" : props.data.Technique} 
            />

            <InterligneSpan 
                styles="alignement" 
                label="Type (international)" 
                value = {props.data.typeCode.international} 
            />

            <InterligneSpan 
                styles="alignement"
                label="Type (circuit bio.)" 
                value = {props.data.typeCode.restreint}  
                valueStyle = {props.data.typeCode.styleRestreint ? "restreint_green" : ""}
            />


            <ReactTooltip id = {idTooltip} type = "info" effect = "solid" html={true} place = "bottom">
                {text}
            </ReactTooltip>
        </div>
        
    )

}


