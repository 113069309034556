import React from 'react'
import "./checkbox.css"
import ReactTooltip from 'react-tooltip';
import {GrCircleInformation} from 'react-icons/gr'

class CheckBox extends React.Component  {

  getErrorCheckbox(){
    return this.props.error ? "account_checkbox_label account_checkbox_label_red" : "account_checkbox_label"
  }


  render(){

    return(
      <div className="account_checkbox_container">

        <label  className={this.getErrorCheckbox()} >
          <span>{this.props.label}<GrCircleInformation data-tip data-for={this.props.tooltip} style={{marginLeft : "10px"}}/></span>
          <input
            className="account_checkbox"
            type="checkbox"
            checked={this.props.value}
            onChange={this.props.onChange}
            disabled = {this.props.disabled}
          />
        </label>
        <ReactTooltip id={this.props.tooltip} type="info" effect="float" >
        <p className="account_checkbox_label white_size">Sélectionnez le ou les dictionnaires pris en charge par cet expert.</p>
        {this.props.label!=="Dictionnaire Chapitre"&& <p className="account_checkbox_label white_size">Pour les dictionnaires de demandes et de résultats, vous avez la possibilité d'indiquer le ou les chapitres qui seront attribués spécifiquement à cet expert.</p>}


        </ReactTooltip>


      </div>
    )

  }

}


export default CheckBox
