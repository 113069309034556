import React from 'react' 
import NavButton from '../../next'
import './QuestionnaireNav.css'



class QuestionnaireNav extends React.Component {

    render(){
        const nombre = this.props.position + " sur " + this.props.total
        return (
            <div className='questionnaire_container_btn'>
                <NavButton   
                    isDefine={this.props.position !== 1}
                    text="Précédent"
                    styles="btn_questionnaire_prev"
                    onClick={()=>this.props.previous()}

                />
                <p className='questionnaire_nombre'>{nombre}</p>
                <NavButton   
                    isDefine={true}
                    text="Valider"
                    styles="btn_questionnaire"
                    onClick={()=>this.props.validation()}
                />
            </div>
        )
    }

}



export default QuestionnaireNav