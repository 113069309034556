import React from "react";
import Select from 'react-select'
import NavButton from '../next.js'
import { Roller } from 'react-awesome-spinners'
import './EndForm.css'
import configData from "../../configdata.json";
import Logo from "../../img/logo_onaos.png";


class EndForm extends React.Component  {

  constructor(props) {
    super(props)
    this.state = {
      text : "",
      exportValue :""
    }
    this.onChangeExport = this.onChangeExport.bind(this)
    this.onChange = this.onChange.bind(this)

  }

  onChangeExport(select){
    const text = select.text !== undefined ? select.text : ""
    const exportValue = select.value !== undefined ? select.value : ""
    this.setState({
      text:text,
      exportValue:exportValue
    })
  }

  onChange(selectedOption) {
    this.props.onChange(selectedOption);
  }

 


  sendEmail()
  {
      const text = "Rendez-vous finalisation transcodage ("+this.props.client+" - "+this.props.basetype+")"
      const mailExpert = configData.MAILEXPERT;

      window.location.href = "mailto:"+mailExpert+"?subject="+text;
      this.props.sendCopy();
  }

  onExport(){
    if (this.state.exportValue !== "") this.props.onExport(this.state.exportValue)
  }

  onExportCLient(needConcensus){
    if (!needConcensus)  {
      this.props.onExport()
    } else {
      this.props.onExport("clientwoconsensus")
    }
  }


  render(){
    const customStyles = {
      container: provided => ({
        ...provided,
        width: '50%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom : '60px'
      })
    };

    const customStyles2 = {
      container: provided => ({
        ...provided,
        width: '80%',
        marginLeft: 'auto',
        marginRight: 'auto',
      })
    };



    const texteRDV = "Un ou plusieurs transcodages diffèrent de ceux proposés par notre expert, vous pouvez soit télécharger votre export en l'état, soit prendre contact avec lui (via un email à notre service client) pour établir un consensus sur ces transcodages."
    const baseT = this.props.basetype==="resultats" ? "résultats" : this.props.basetype
    const needConcensus = this.props.reject!==undefined &&  this.props.reject.length>0 ? true : false
    const titleForUpdate = this.props.isVeille ? " mis  à jour" : ""
    return (
        <div className="container_endform">
          <div className="base_type_end">
            <img className="logo_onaos_mapping" src={Logo} alt="Logo Onaos"/>
          </div>
          {this.props.showLoading===true &&
            <div className="endform_roller center">
              <p className="text_download">Veuillez patienter nous générons votre fichier (compter ~1min)</p>
              <Roller color="#fff" />
            </div>
          }
          {this.props.showLoading===false &&
          <div className = "container_end_withoutbtn">
            <div className = "container_end_line">

              <div className="endform_position_card endform_position_card_large endform_position_card_hight">
                <h4 className="title_end">Export du dictionnaire de {baseT} {titleForUpdate} transcodé LOINC</h4>
                <p className="export_center">Attention, cet export ne contient que les codes LOINC appartennant au jeu de valeurs "Circuit de la biologie" conformément aux exigences de l'ANS*.</p> 
                <p className="export_center">L'export correspond à l'ensemble des codes locaux avec les codes loinc validés <b>à ce jour</b> par l'ensemble de vos experts.</p>

                {(!needConcensus || this.props.isVeille ) && <p className="export_center">Il est possible de télécharger un fichier contenant l'ensemble des codes LOINC retenus en choisissant l'item "Export complet" dans l'encart "Autres exports" ci-dessous.</p>}
                <NavButton isDefine={true} onClick={()=> this.onExportCLient(needConcensus)} text="Télécharger" styles="button_end"/>
                { needConcensus && !this.props.isVeille &&
                  <React.Fragment>
                    <p className="end_interligne">{texteRDV}</p>
                    <p className="end_interligne">Ces entrées peuvent être retrouvées ci-après :</p>
                    <Select
                      styles={customStyles}
                      className="center" options={this.props.reject} value="" onChange={this.onChange}
                    />
                  </React.Fragment>
                }
              </div>
              
      

              <div className="endform_position_card">
              <h4 className="title_end">Retour sur une entrée transcodée :</h4>
                  <Select
                  styles={customStyles}
                  className="center" options={this.props.data} value="" onChange={this.onChange} />
              </div>
            </div>


            <div className = "container_end_line">

              <div className="endform_position_card endform_position_card_large">
                <h4 className="title_end">D'autres exports (<span style = {{textDecoration: "underline"}}>transcodage LOINC complet</span>, commentaires, journalisation, etc) sont également disponibles :</h4>
                  <Select
                    styles={customStyles2}
                    className="center" 
                    options={this.props.dataexport} 
                    value={this.props.dataexport.find( option => option.value === this.state.exportValue )} 
                    onChange={this.onChangeExport} 
                  />
                  <NavButton isDefine={true} onClick={()=> this.onExport()} text="Télécharger" styles="button_end" />

              </div>

              <div className="endform_position_card ">
                <h4 className="title_end">Informations sur l'export sélectionné :</h4>
                <p className="end_interligne">{this.state.text}
                </p>
              </div>
            </div>
           
          </div>
          }
          {this.props.showLoading===false &&
            <div className="container_btn_endform">
              <NavButton isDefine={true} onClick={this.props.disconnect} text="Quitter"  />
              <NavButton isDefine={true}  onClick={()=>this.props.showDashboard(true)} text={this.props.statut === "validation expert" ? "Dashboard" : "Changer de dictionnaire"}  />

            </div>
          }
        </div>
      )
    }
  };

export default EndForm;
