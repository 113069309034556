import React, { useState } from "react"
import SelectAndTxt from "../../SelectAndTxt/SelectAndTxt"
import NavButton from "../../next"
import { putAPI } from "../../../api/api"





export const AddCodes = (props)=> {

    const [selectToGenerate, setSelectToGenerate] = useState({})
    const [selectToTranscode, setSelectToTranscode] = useState({})
    const [selectAddingType, setSelectAddingType] = useState({})
    const [versionLOINC, setLOINC] = useState( {label:"V16",value:"V16"})

    
    const [apiInfos, setInfos] = useState({})
    const [generatedInfos, setGeneratedInfos] = useState({})


    const selectData = props.data.dico!==undefined ?  props.data.dico.map( (v,k)=>({
        label : v["nom"] + " (" + v["type"]+")",
        value : v["id"],
        type : v["type"] === "Résultat" ? "resultats" : "demandes"
      })) : [{label:"",value:""}]


      
    const selectAjout =  [{label:"Ajout sur contrat update",value:"ajout"}] //{label:"Ajout transcodage initial",value:"ajoutInitial"}]


    const loinc =  [{label:"V15",value:"V15"}, {label:"V16",value:"V16"}, {label:"V17",value:"V17"},  {label:"V18",value:"V18"}] 


    const sendToGenerate = () => {
        const datas = {
            idFile : selectToGenerate.value,
            client : props.client,
            typeFile : selectToGenerate.type
        }

        putAPI("/newCodesFromFile", props.tokenRefresh, datas).then(data => {
            // console.log(data)
            if (data) {
                const urld = window.URL.createObjectURL(new Blob([data]))
                const link = document.createElement('a')
                link.href = urld
                link.title = "New Code"
                link.setAttribute('download', "NouveauxCodes.csv")
                document.body.appendChild(link)
                link.click()
                link.remove()
                window.URL.revokeObjectURL(urld)
                //request to have info about new code
                const nData = {
                    client : props.client,
                    typeFile : selectToGenerate.type
                }
                putAPI("/addingCodeInfos", props.tokenRefresh, nData).then(data => {
                    setGeneratedInfos(data)
                })
            }
        })
    }


    const sendTotranscode = (mode) => {
        const datas = {
            id : selectToTranscode.value,
            client : props.client,
            typeFile : selectToTranscode.type,
            mode : mode,
            ajoutMode : selectAddingType.value

        }
        putAPI("/dotranscode", props.tokenRefresh, datas).then(data => {
            if (data.done) {
                setInfos(data)
            } else {
                setInfos("Erreur !")
            }
        })
    }

    const doVeille = (type) => {
        const datas = {
            type : type,
            client : props.client,
            version : versionLOINC.value
        }
        console.log(datas)

        switch (type){
            case 'rapport':
                putAPI("/doveille", props.tokenRefresh, datas).then(data => {
                    if (data.done) {
                        setInfos(data)
                    } else {
                        setInfos("Erreur !")
                    }
                })
                break
            case 'collections':
                window.confirm("Êtes-vous sur de vouloir générer les collections de veille pour ce client ?") && putAPI("/doveille", props.tokenRefresh, datas).then(data => {
                    if (data.done) {
                        setInfos(data)
                    } else {
                        setInfos("Erreur !")
                    }
                })
                break
            default:
                break
        }
    }



    return(
        <div style = {{display : "flex", flexDirection:'column', gap : '50px'}}>
            <div style = {{display : "flex", flexDirection:'column'}}>
            <h3 style = {{textAlign : "left", fontWeight : "bold", marginTop : "0px"}}>Veille : </h3>
                <SelectAndTxt
                    label = { {name : "Version LOINC pour comparaison" ,txt : ""} }
                    data  = {loinc}
                    selectChange = {(e) => setLOINC(e)}
                    selectdata = {versionLOINC.value}
                    index = {500}
                />
                <div style = {{display : "flex", justifyContent : 'center', alignItems:'center'}}>
                    <div>
                        <NavButton isDefine={versionLOINC.value !== undefined}  onClick={()=>doVeille("rapport")} text="Obtenir un rapport de Veille" styles="btn_modif_dico" />
                    </div>
                    <div>
                        <NavButton isDefine={versionLOINC.value !== undefined}  onClick={()=>doVeille("collections")} text="Générer des collections de Veille" styles="btn_modif_dico anomalieColor"  />
                    </div>
                </div>
            </div>
            <div style = {{display : "flex", flexDirection:'column'}}>
                <h3 style = {{textAlign : "left", fontWeight : "bold", marginTop : "0px"}}>Ajout de codes : </h3>
                <SelectAndTxt
                    label = { {name : "Fichier source" ,txt : ""} }
                    data  = {selectData}
                    selectChange = {(e) => setSelectToGenerate(e)}
                    selectdata = ""
                    index = {400}
                />
            
                <div style = {{display : "flex", flexDirection:'column', justifyContent:'center', margin : "20px", gap :"10px"}}>
                    {generatedInfos.infos !== undefined && 
                        <React.Fragment>
                            <span>Nombre de nouveaux codes ({selectToGenerate.type}) détectés : <span style = {{fontWeight : 'bold'}}>{generatedInfos.infos.new}</span> </span>
                            <span>Nombre de codes ({selectToGenerate.type}) autorisés : <span style = {{fontWeight : 'bold'}}>{generatedInfos.infos.allowed}</span></span>
                        </React.Fragment>
                    }
                    <div>
                        <NavButton isDefine={selectToGenerate.value !== undefined}  onClick={sendToGenerate} text="Générer" styles="btn_modif_dico" />
                    </div>
                </div>
           
            </div>

            <div style = {{display : "flex", flexDirection:'column'}}>
                <SelectAndTxt
                    label = { {name : "Fichier à transcoder" ,txt : ""} }
                    data  = {selectData}
                    selectChange = {(e) => setSelectToTranscode(e)}
                    selectdata = ""
                    index = {300}
                />

                <SelectAndTxt
                    label = { {name : "Type d'ajout" ,txt : ""} }
                    data  = {selectAjout}
                    selectChange = {(e) => setSelectAddingType(e)}
                    selectdata = ""
                    index = {200}
                />

                <div style = {{display : "flex", justifyContent: 'center', gap : "15px" }}>
                  
                    <NavButton isDefine={selectToTranscode.value !== undefined}  onClick={()=>sendTotranscode("debug")} text="Debug" styles="btn_modif_dico" />
                   
                    <NavButton isDefine={selectToTranscode.value !== undefined}  onClick={()=>sendTotranscode("transcode")} text="Transcoder" styles="btn_modif_dico anomalieColor" />
               
                    {apiInfos.msg !== undefined && <div>
                        {apiInfos.msg &&
                            <p className="interligne center">{apiInfos.msg}</p>
                        }
                        {apiInfos.debugmsg &&
                            <p className="interligne center">{apiInfos.debugmsg}</p>
                        }
                        </div>
                    }
                </div>
            </div>


        </div>
    )

}