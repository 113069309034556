import React from 'react'
import './addorremove.css'





class Addorremove extends React.Component  {


  render(){
    const actionIndex = this.props.actionIndex
    const actionKey = this.props.actionKey !== undefined ? this.props.actionKey : null

    return(
      <React.Fragment>
        {this.props.type === "delete" &&
        <div className="addorremove_supp_expert">
          <div className="addorremove_supp_button" onClick={()=>this.props.action(this.props.typebase,actionIndex,actionKey)}></div>
        </div>
        }
        {this.props.type === "add" &&
          <div className="addorremove_add_expert">
            <div className="addorremove_add_button" onClick={()=>this.props.action(actionIndex,actionKey)}></div>
          </div>
        }
      </React.Fragment>
    )
  }

}



export default Addorremove
