import React from 'react'
import "./main.css"
import {getAPI,putAPI} from '../../../api/api.js';
import LaboAccount from "../laboaccount/laboaccount.js"
import Invalid from "../invalid/invalid.js"


class Account extends React.Component  {
  constructor(props){
      super(props);
      this.state = {
        accountInfo:{
          valid:-1
        },
        inputValid:{

        },
        error:{
          msg:{
            base:false ,
            chapitre:false
          },
          labo:{
            state:false,
            error:{
              complement:[],
              facturationcomplement:[],
              facturation:[]
            }
          },
          expert:{
            state:false,
            error:[]
          }
        },
        blockCheckbox:{
          basechapitre: false,
          basedemande: false,
          baseresultat: false
        }
      }
      this.inputChang = this.inputChang.bind(this)
      this.checkChange = this.checkChange.bind(this)
      this.validation = this.validation.bind(this)
      this.addExpert = this.addExpert.bind(this)
      this.chapitreChange = this.chapitreChange.bind(this)
      this.chapitreDemandeChange = this.chapitreDemandeChange.bind(this)
      this.deleteExpert = this.deleteExpert.bind(this)
      this.onCreateOption = this.onCreateOption.bind(this)

    }

    onCreateOption(value,add = true){
      const tmp = {...this.state.accountInfo}
      const lbl = {label:value,value:value}
      tmp.client.allSIL.push(lbl)
      this.setState({accountInfo:tmp})
    }

    deleteExpert(){
      let tmp = {...this.state.accountInfo}

      const oldexpertChap = tmp.client.expert[tmp.client.expert.length -1].bases.chapitres_resultat
      const oldexpertChapDem = tmp.client.expert[tmp.client.expert.length -1].bases.chapitres_demande

      tmp.client.expert.pop()


      tmp.client.expert.forEach((item, i) => {
        item.allChapitreResultat.forEach((v, k) => {
          if (oldexpertChap.includes(v[0]) && v[1]===true){
            tmp.client.expert[i].allChapitreResultat[k]=v[0]
          }
        });
        item.allChapitreDemande.forEach((v, k) => {
          if (oldexpertChapDem.includes(v[0]) && v[1]===true){
            tmp.client.expert[i].allChapitreDemande[k]=v[0]
          }
        });

      });

      /*
        - passer en revue tout les  chapitres de l'expert del
          => mettre de côté ceux sél
        - pour chaque expert restant : si val dans le tableau côté : retirer le 'disabled true'

      */


      let checkboxState = {
        chapitre:[],
        demande:[],
      }
      tmp.client.expert.forEach((item, i) => {
        checkboxState.chapitre.push(item["bases"]["basechapitre"])
        checkboxState.demande.push(item["bases"]["basedemande"])
      });
      let blockCheckbox = {...this.state.blockCheckbox}
      blockCheckbox.basechapitre = checkboxState["chapitre"].filter(x => x===true).length === 1 ? true: false
      blockCheckbox.basedemande = checkboxState["demande"].filter(x => x===true).length === 1 ? true: false

      this.setState({
        accountInfo:tmp,
        blockCheckbox:blockCheckbox
      })
    }

    chapitreChange(expert,value){
      let tmp = {...this.state.accountInfo}
      let val = []
      value.forEach((item, i) => {
          val.push(item.value)
      });

      tmp.client.expert[expert].bases.chapitres_resultat=val

      let allExpertChapFull = []
      tmp.client.expert.forEach((item, i) => {
        item["bases"]["chapitres_resultat"].forEach((v, k) => {
          allExpertChapFull.push(v)
        });
      });
      const allExpertChap = [...new Set(allExpertChapFull)]


      tmp.client.expert.forEach((item, i) => {
        tmp.client.allchapitre.forEach((v, k) => {
          if (item.bases.chapitres_resultat.includes(v)===false && allExpertChap.includes(v)){
            tmp.client.expert[i].allChapitreResultat[k]=[v,true]
          } else {
            tmp.client.expert[i].allChapitreResultat[k]=v
          }
        });

      });



      this.setState({accountInfo:tmp})
    }


    chapitreDemandeChange(expert,value){
      let tmp = {...this.state.accountInfo}
      let val = []
      value.forEach((item, i) => {
          val.push(item.value)
      });

      tmp.client.expert[expert].bases.chapitres_demande=val

      let allExpertChapFull = []
      tmp.client.expert.forEach((item, i) => {
        item["bases"]["chapitres_demande"].forEach((v, k) => {
          allExpertChapFull.push(v)
        });
      });
      const allExpertChap = [...new Set(allExpertChapFull)]


      tmp.client.expert.forEach((item, i) => {
        tmp.client.allchapitre.forEach((v, k) => {
          if (item.bases.chapitres_demande.includes(v)===false && allExpertChap.includes(v)){
            tmp.client.expert[i].allChapitreDemande[k]=[v,true]
          } else {
            tmp.client.expert[i].allChapitreDemande[k]=v
          }
        });

      });



      this.setState({accountInfo:tmp})
    }

  addExpert(){
    let tmp = {...this.state.accountInfo}
    let expert = {
      infos:{
        "Nom":"",
        "Prénom":"",
        "Email":"",
        "Email confirmation":"",
        "Téléphone":""

      },
      bases:{
        chapitres_demande:[],
        chapitres_resultat:[],
        baseresultat:true,
        basedemande:true
      },
      allChapitreDemande:[],
      allChapitreResultat:[]
    }



    let allExpertChapFull = []
    let allExpertChapDemFull = []

    this.state.accountInfo.client.expert.forEach((item, i) => {
      item["bases"]["chapitres_resultat"].forEach((v, k) => {
        allExpertChapFull.push(v)
      });
      item["bases"]["chapitres_demande"].forEach((v, k) => {
        allExpertChapDemFull.push(v)
      });
    });


    let allExpertChap = [...new Set(allExpertChapFull)]
    let allExpertChapDem = [...new Set(allExpertChapDemFull)]

    this.state.accountInfo.client.allchapitre.forEach((item, i) => {
      if (allExpertChap.includes(item)===false){
        expert.bases.chapitres_resultat.push(item)
        expert.allChapitreResultat.push(item)
      } else {
        expert.allChapitreResultat.push([item,true])
      }

      if (allExpertChapDem.includes(item)===false){
        expert.bases.chapitres_demande.push(item)
        expert.allChapitreDemande.push(item)
      } else {
        expert.allChapitreDemande.push([item,true])
      }
    });

    this.state.accountInfo.client.expert.forEach((item, i) => {
      this.state.accountInfo.client.allchapitre.forEach((v, k) => {
        if (item.bases.chapitres_resultat.includes(v)===false){
          tmp.client.expert[i].allChapitreResultat[k]=[v,true]
        } else {
          tmp.client.expert[i].allChapitreResultat[k]=v
        }

        if (item.bases.chapitres_demande.includes(v)===false){
          tmp.client.expert[i].allChapitreDemande[k]=[v,true]
        } else {
          tmp.client.expert[i].allChapitreDemande[k]=v
        }

      });

    });


    if (expert.allChapitreDemande.length === this.state.accountInfo.client.allchapitre.length){
      expert.bases.basedemande = false;
    }

    if (expert.allChapitreResultat.length === this.state.accountInfo.client.allchapitre.length){
      expert.bases.baseresultat = false;
    }




    tmp.client.expert.push(expert)
    this.setState({accountInfo:tmp})
  }

  checkChange(tag,expert){
    let tmp = {...this.state.accountInfo}
    let blockCheckbox = {...this.state.blockCheckbox}
    tmp.client.expert[expert]["bases"][tag] = !this.state.accountInfo.client.expert[expert]["bases"][tag]

    blockCheckbox[tag]=tmp.client.expert[expert]["bases"][tag]


    if (tag === "baseresultat" && blockCheckbox[tag] ===false ){

      const oldexpertChap = tmp.client.expert[expert].bases.chapitres_resultat
      tmp.client.expert[expert].bases.chapitres_resultat=[]


      tmp.client.expert.forEach((item, i) => {
        item.allChapitreResultat.forEach((v, k) => {
          if (oldexpertChap.includes(v[0]) && v[1]===true){
            tmp.client.expert[i].allChapitreResultat[k]=v[0]
          }
        });

      });

    }

    if (tag === "basedemande" && blockCheckbox[tag] ===false ){

      const oldexpertChap = tmp.client.expert[expert].bases.chapitres_demande
      tmp.client.expert[expert].bases.chapitres_demande=[]


      tmp.client.expert.forEach((item, i) => {
        item.allChapitreDemande.forEach((v, k) => {
          if (oldexpertChap.includes(v[0]) && v[1]===true){
            tmp.client.expert[i].allChapitreDemande[k]=v[0]
          }
        });

      });

    }


    this.setState({
      accountInfo:tmp,
      blockCheckbox:blockCheckbox
    })

  }

  componentDidMount(){
    getAPI("/"+this.props.type+"account/"+this.props.token,"").then(data => {
      if (data["client"] !== undefined){
        data["client"]["expert"].forEach((v,k) =>{
          const tel = data["client"]["expert"][k]["infos"]["Téléphone"]
          delete data["client"]["expert"][k]["infos"]["Téléphone"]
          data["client"]["expert"][k]["infos"]["Email confirmation"] = ""
          data["client"]["expert"][k]["infos"]["Téléphone"] = tel

        } )
        }
        this.setState({accountInfo:data})
      })
  }




    inputChang(e,tag,tag2=null,numero=null){
      let tmp = {...this.state.accountInfo}
      numero = numero===null || numero===undefined ? 0 : numero


      switch(tag2){
        case null:
          tmp["client"]["labo"][tag] = e.target.value
          break
        case 'expert':
          tmp["client"]["expert"][numero]["infos"][tag] = e.target.value
          break
        case 'logiciel':
          tmp["client"]["labo"][tag2][tag] = tag === "S.I.L." ? e.value : e.target.value
          break
        default:
          tmp["client"]["labo"][tag2][tag] = e.target.value
          break


      }
      this.setState({accountInfo:tmp})


    }


    validation(){
   // let labo = this.state.accountInfo.client.labo

    const error =  {
              msg:{
                base:false ,
                chapitre:false
              },
              labo:{
                state:false,
                error:{
                  complement:[],
                  facturationcomplement:[],
                  facturation:[

                  ]
                }
              },
              expert:{
                state:false,
                error:[],
                msgState:""
              }
            }


/*
    error.labo.state = (
      ( labo.facturation.Adresse==="" && (labo.facturationcomplement["Code postal"]!=="" ||  labo.facturationcomplement["Pays"]!=="" || labo.facturationcomplement["Ville"]!=="" ))
      || ( labo.facturation.Adresse!=="" && (labo.facturationcomplement["Code postal"]==="" ||  labo.facturationcomplement["Pays"]==="" || labo.facturationcomplement["Ville"]==="" ))
      || labo.Adresse ===""  || labo.complement["Code postal"]==="" || labo.complement["Pays"]==="" || labo.complement["Ville"]==="" ) ? true : false


      error.labo.state = error.labo.state === false && (labo["Nom de la structure"]==="" || labo["Numéro SIRET"]==="" || labo["Numéro TVA intracommunautaire"]===""
        || labo["N° Finess Géographique bénéficiaire"]==="" ||  labo["N° Finess Juridique"]==="" )  ? true : error.labo.state



    if (error.labo.state) {
      error.labo.error["Nom de la structure"] = labo["Nom de la structure"]==="" ? true : false
      error.labo.error["Adresse"] = labo.Adresse==="" ? true : false
      error.labo.error["complement"]["Code postal"] = labo.complement["Code postal"] === "" ? true : false
      error.labo.error["complement"]["Ville"] = labo.complement["Ville"] === "" ? true : false
      error.labo.error["complement"]["Pays"] = labo.complement["Pays"] === "" ?true : false

      error.labo.error["facturationcomplement"]["Pays"] = labo.facturation.Adresse !== "" && labo.facturationcomplement["Pays"]==="" ? true : false
      error.labo.error["facturationcomplement"]["Ville"] = labo.facturation.Adresse !== "" && labo.facturationcomplement["Ville"]==="" ? true : false
      error.labo.error["facturationcomplement"]["Code postal"] = labo.facturation.Adresse !== "" && labo.facturationcomplement["Code postal"]==="" ? true : false

      error.labo.error["facturation"]["Adresse"] = labo.facturation.Adresse === "" && ( labo.facturationcomplement["Pays"]!=="" || labo.facturationcomplement["Code postal"]!=="" || labo.facturationcomplement["Ville"]!=="" )

      error.labo.error["Numéro SIRET"] =  labo["Numéro SIRET"]==="" ? true : false
      error.labo.error["Numéro TVA intracommunautaire"] =  labo["Numéro TVA intracommunautaire"]==="" ? true : false

      error.labo.error["N° Finess Géographique bénéficiaire"] =  labo["N° Finess Géographique bénéficiaire"]==="" ? true : false
      error.labo.error["N° Finess Juridique"] =  labo["N° Finess Juridique"]==="" ? true : false

      //TODO : ajouter en obligatoire le logiciel + version ?
    }*/
    let expert = this.state.accountInfo.client.expert
    let baseChapitre = []
    let baseDemande = []
    let baseResultat = []


    let allchapterResultat = []
    let allchapterDemande = []
    error.expert.error["basedemande"] = false
    error.expert.error["baseresultat"] = false

    let expertMail = []
    let expertMailError = false

    expert.forEach((item, i) => {

      expertMail.push(item["infos"]["Email"])
      if (expertMail.filter(value => value === item["infos"]["Email"] ).length >1 ) expertMailError = true

      const nom = item["infos"]["Nom"]==="" ? true: false
      const prenom = item["infos"]["Prénom"]==="" ? true: false
      const email  = item["infos"]["Email"]==="" || expertMailError ? true: false
      const emailconf  = item["infos"]["Email confirmation"]===""  ||  item["infos"]["Email confirmation"] !== item["infos"]["Email"] || !item["infos"]["Email confirmation"].match(/.+@.+/)? true: false

      baseChapitre.push(item["bases"]["basechapitre"])
      baseDemande.push(item["bases"]["basedemande"])
      baseResultat.push(item["bases"]["baseresultat"])
      error.expert.state = ( !expertMailError && !error.expert.state && !nom && !prenom && !email && !emailconf) ? false : true
      error.expert.msgState =  !expertMailError  ? error.expert.msgState : "mailerror"

      error.expert.error[i] = {
        "infos" : {
          Nom : nom,
          Prénom : prenom,
          Email : email,
          "Email confirmation":emailconf
        },
      }
      allchapterResultat = allchapterResultat.concat(item["bases"]["chapitres_resultat"])
      allchapterDemande = allchapterDemande.concat(item["bases"]["chapitres_demande"])
      error.expert.error["basedemande"] = item["bases"]["basedemande"] && item["bases"]["chapitres_demande"].length === 0 ? true : error.expert.error["basedemande"]
      error.expert.error["baseresultat"] = item["bases"]["baseresultat"] && item["bases"]["chapitres_resultat"].length === 0 ? true : error.expert.error["baseresultat"]

    });


    error.expert.error["basechapitre"] = baseChapitre.filter(x => x===true).length !== 1 ? true : false
    error.expert.error["basedemande"] = baseDemande.filter(x => x===true).length === 0 ? true : error.expert.error["basedemande"]
    error.expert.error["baseresultat"] = baseResultat.filter(x => x===true).length === 0 ? true : error.expert.error["baseresultat"]
    error.expert.error["chapitreResultat"] = false
    error.expert.error["chapitreDemande"] = false


    this.state.accountInfo.client.allchapitre.forEach((item, i) => {
      error.expert.error["chapitreResultat"] = (error.expert.error["chapitreResultat"]===false && allchapterResultat.includes(item)===true )? false :true
      error.expert.error["chapitreDemande"] = (error.expert.error["chapitreDemande"]===false && allchapterDemande.includes(item)===true )? false :true

    });

    error.expert.state = (! expertMailError && !error.expert.state && !error.expert.error["basedemande"] && !error.expert.error["baseresultat"] && !error.expert.error["basechapitre"]  && !error.expert.error["chapitreResultat"] && !error.expert.error["chapitreDemande"] ) ? false : true




    if (!error.expert.state && !error.labo.state ){
      putAPI("/updateclient/"+this.props.token,"",this.state.accountInfo).then(data => {
        if (data["done"]==="ok"){
          this.props.validationAccount(true)
        } else {
          window.alert("Une erreur inconnue s'est produite, merci de nous contacter")
          setTimeout(() => {  window.location.href="https://loinc-mapper.onaos.com" }, 1500);

        }
      })

    } else {
     
      this.setState({error:error})
      if (error.expert.msgState !==""){
        window.alert("Cet expert ne peut être renseigné plusieurs fois")
      } else {
        window.alert("Formulaire incomplet, merci de remplir les champs manquants")
      }
    }
  }

  render(){

    return (
      <div className = "container_account" >

      {this.state.accountInfo.valid===0 &&
        <Invalid
          type = {this.props.type}
        />
      }

      {this.state.accountInfo.valid===1 && this.props.type==="labo" &&
        <LaboAccount
          data={this.state.accountInfo}
          onChange={this.inputChang}
          checkChange = {this.checkChange}
          validation = {this.validation}
          addExpert = {this.addExpert}
          chapitreChange = {this.chapitreChange}
          chapitreDemandeChange = {this.chapitreDemandeChange}
          error  = {this.state.error}
          deleteExpert = {this.deleteExpert}
          disabled = {this.state.blockCheckbox}
          onCreateOption = {this.onCreateOption}
        />

      }




      </div>
    )
  }

}


export default Account;
