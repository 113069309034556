import React from 'react'
import './main.css'
import Invalid from "../account/invalid/invalid.js"
import PasswordSaisie from "./PasswordSaisie/PasswordSaisie.js"
import {getAPI,postAPI,putAPI} from '../../api/api.js';


class Password extends React.Component  {

  constructor(props){
      super(props);
      this.state = {
        accountInfo:{
          valid:-1
        },

      }
      this.createPassword = this.createPassword.bind(this)
    }

  componentDidMount(){
    getAPI("/"+this.props.type+"account/"+this.props.token,"").then(data => this.setState({accountInfo:data}))
  }

  createPassword(password){

    if (this.props.type==="expert"){
      let data = new FormData();
      data.append('password',password)
      data.append('token',this.props.token)



      postAPI("/password","",data).then(data => {
        if (data.state!=="ok"){
          window.alert("Une erreur inconnue s'est produite, merci de nous contacter")
        } else {
          window.alert("Compte crée avec succès, vous allez être redirigé vers la page de connexion")
        }
        setTimeout(() => {  window.location.href="/" }, 1500);
      })
    }
    if (this.props.type==="recup"){
      const data ={
        password:password,
        token : this.props.token,
        email: this.state.accountInfo["email"]
      }


      putAPI("/passwordrecup","",data).then(data => {
        if (data.state!=="ok"){
          window.alert("Une erreur inconnue s'est produite, merci de nous contacter")
        } else {
          window.alert("Mot de passe modifié avec succès, vous pouvez maintenant vous connecter à votre interface ")
        }
        setTimeout(() => {  window.location.href="/" }, 1500);
      })

    }


    if (this.props.type==="dsi"){
      let data = new FormData();
      data.append('password',password)
      data.append('token',this.props.token)



      postAPI("/dsipassword","",data).then(data => {
        if (data.state!=="ok"){
          window.alert("Une erreur inconnue s'est produite, merci de nous contacter")
        } else {
          window.alert("Compte crée avec succès, vous allez être redirigé vers la page de connexion")
        }
        setTimeout(() => {  window.location.href="/" }, 1500);
      })
    }


  }


  render(){
    return(
      <div className="container_password">
      {this.state.accountInfo.valid===0 &&
        <Invalid
          type = {this.props.type}
        />
      }

      {this.state.accountInfo.valid===1 && (this.props.type==="expert" || this.props.type==="dsi") &&
        <PasswordSaisie
          data={this.state.accountInfo.client[0]}
          createPassword={this.createPassword}
          type={this.props.type}
        />
      }

      {this.state.accountInfo.valid===1 && this.props.type==="recup" &&
        <PasswordSaisie
          data={this.state.accountInfo["email"]}
          createPassword={this.createPassword}
          type={this.props.type}
        />
      }
      </div>
    )
  }

}


export default Password
