import React from 'react'
import "./laboaccount.css"
import InputAccount from '../inputaccount/inputaccount.js'
import ExpertInfos from '../expertinfos/expertinfos.js'
import NavButton from "../../next"


class LaboAccount extends React.Component  {


  getError(data){
    if (this.props.error.labo.state===true){
      if (this.props.error.labo.error[data[0]]===true) return true

      if (data[0]==="complement" || data[0]==="facturationcomplement" ) return this.props.error

      if (data[0]==="facturation" && this.props.error.labo.error.facturation.Adresse===true) return true
    }



    return false
  }


  validation(){
    this.props.validation()
  }

  render(){
    return(
      <React.Fragment>
      <div className="labo_img_container">
        <img src="comptelabo.svg" alt="Compte" className="labo_img"/>
      </div>

      <div className="labo_account">
        <div className="container_title_onaos">
          <img src="logo_onaos.png" alt="Logo" className="title_onaos"/>
        </div>

        <div className="labo_account_main_container">
          <h1 className="account_title">Laboratoire</h1>
          {Object.entries(this.props.data.client.labo).map( (v,k)=>(
            <InputAccount
              data={v}
              onChange={this.props.onChange}
              error = {this.getError(v)}
              allSIL = {this.props.data.client.allSIL}
              onCreateOption = {this.props.onCreateOption}
            />
          ))}

          {this.props.data.client.expert!==undefined &&  Object.entries(this.props.data.client.expert).map( (v,k)=>(
            <ExpertInfos
              onChange={this.props.onChange}
              checkChange = {this.props.checkChange}
              data = {v[1]}
              expert = {k}
              allChapitreResultat = {v[1].allChapitreResultat}
              allChapitreDemande = {v[1].allChapitreDemande}
              chapitreChange = {this.props.chapitreChange}
              chapitreDemandeChange = {this.props.chapitreDemandeChange}

              error = {this.props.error}
              total = {this.props.data.client.expert.length}
              deleteExpert = {this.props.deleteExpert}
              disabled = {this.props.disabled}
            />


          ))}

          <div className="add_expert">
            <p>Ajouter un expert</p>
            <div className="add_button" onClick={()=>this.props.addExpert()}></div>
          </div>

          <NavButton
            isDefine={true}
            onClick={()=>this.validation()}
            text="Finaliser le compte"
            styles="labo_account_btn"
          />



        </div>
      </div>
      </React.Fragment>
    )
  }

}


export default LaboAccount;
