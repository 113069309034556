import React from 'react';
import Interligne from '../interligne/interligne.js'
import ReactTooltip from 'react-tooltip';
import "./loincinfo.css"
import {Collapse} from 'react-collapse';
import { GrFormDown,GrFormUp } from 'react-icons/gr';
import { CadreVeille } from './CadreVeille.js';


class LoincInfo extends React.Component {

  constructor(props){
    super(props);
    this.state={
      showInfo:false,
    }
  }


  collapse(e){
    e.stopPropagation()
    this.setState({showInfo:!this.state.showInfo})
  }

  getImg(){
    return (this.props.loinc==="Absent du LOINC") ? "croix.png":"croix_rouge.png"
  }

  getTexte(){
    return (this.props.loinc==="Absent du LOINC") ? "Si aucun code LOINC n'est proposé ou que vous estimez qu'il ne correspond pas à votre code local : vous pouvez sélectionner cette carte pour demander la création d'un code LOINC correspondant" : "Non Transcodable"
  }


  getIsRestreint(){
    let result=false
    if (!result && this.props.baseinfos.typebase==="resultats" && this.props.restreint["résultat inRestreint"]) result = true
    if (!result && this.props.baseinfos.typebase==="demandes" && this.props.restreint["demande inRestreint"]) result = true
    if (!result && this.props.baseinfos.typebase==="chapitres" && this.props.restreint["chap/souschap inRestreint"]) result = true

    return result
  }

  getTextIsRestreint(data){
    const enattente =  data !== undefined && data["enattente"] === true ? true : false
    let resultat = data["résultat inRestreint"] ? "Résultat" : ""
    let demande = data["demande inRestreint"] ? "Demande" : ""
    let texte = "code absent"
    if (this.props.baseinfos.typebase === "chapitres" && data["chap/souschap inRestreint"]) {
      texte = "Chapitre/Sous-chapitre"
    }  else {
      if (resultat !=="" && demande==="") texte = resultat
      if (demande !=="" && resultat==="") texte = demande
      if (demande !=="" && resultat!=="") texte = "Rés. + Dem."
    }
    


    return  enattente ? "En attente ("+texte+")" : texte

  }

  getStyleRestreint(data){
    let result = ""
    if (this.props.baseinfos.typebase === "chapitres" && data ===  "Chapitre/Sous-chapitre") return "restreint_green"
    if ((this.props.baseinfos.typebase === "demandes" || this.props.baseinfos.typebase === "resultats") && data ===  "Rés. + Dem.") return "restreint_green"
    if (this.props.baseinfos.typebase === "demandes" && data ===  "Demande") return "restreint_green"
    if (this.props.baseinfos.typebase === "resultats" && data ===  "Résultat") return "restreint_green"
    return result


  }

  getLOINCInternational(data){
    let result = "-"
    if (data==="Both"){
      result = "Rés. + Dem."
    }
    if (data==="Observation") {
      result = "Résultat"
    }

    if (data==="Order") {
      result = "Demande"
    }

    return result
  }
  onPanelClick(e,state,panelType){
    if (state){
      e.stopPropagation()
      this.props.onPanelClick(this.props.numero,this.props.loinc,panelType)
    }
  }

  getInfoBulle(){
    return (this.props.data["Echelle"]==="Résultat nominal" || this.props.data["Echelle"]==="Résultat textuel") ? true:false
  }


  needTooltipTechnique(data){
    let result = {
      need : false,
      text : ""
    }
    if (data === "Non-PCR amplification de cible") result = {need : true, text : "Cette méthode commence par l'amplification par PCR du matériel génétique du spécimen, mais au lieu d'utiliser des sondes pour identifier les cibles d'intérêt, d'autres techniques, telles que l'analyse de la courbe de fusion ou la turbidité, sont utilisées pour détecter et identifier les cibles"}
    if (data === "Microscopie HPF") result = {need : true, text : " Microscopie fort grossissement (Obj. &ge; X40"}
    if (data === "Microscopie LPF") result = {need : true, text : " Microscopie faible grossissement (Obj. < X40"}

    return result
     
  }

  render(){
    const inRestreint = this.getTextIsRestreint(this.props.restreint)
    const isPanel = this.props.data.panel !== undefined && this.props.data.panel.is === true ? "Oui" : "Non"
    const findInPanel = this.props.data.panel !== undefined && this.props.data.panel.associé === true ? "Oui" : "Non"
    const tooltipTechnique = this.props.data !== undefined ? this.needTooltipTechnique(this.props.data["Technique"]) : {need : false, text : ""}
    
    return(
      <div  className="commun_map"  onClick={ this.props.onClick } >

        <div className="container_info_div_LOINC">
            <div className={"container_icon_LOINC "+this.props.styles}></div>
            {this.props.basestatut==="validation expert" &&
              <div className="container_icon_LOINC_info" >
                <Interligne styles="center inside" label="" value = {"direct"} valueInfo = {this.props.data["mapper"] !== undefined ? this.props.data["mapper"].join() : ""} iconeType = "info"  icone = {true} tooltip={this.props.tooltip+"_info"}/>
              </div>
            }
            {/*this.props.basestatut==="validation client" && this.props.data["infos"]!=="" && 
              <div className="container_icon_LOINC_info" >
                <Interligne styles="center inside" label="" value = {"direct"} valueInfo = {this.props.data["infos"] } iconeType = "info"  icone = {true} tooltip={this.props.tooltip+"_infoloinc"}/>
              </div>
          */}

        </div>

        {this.props.loinc!=="Absent du LOINC" && this.props.loinc!=="Non transcodable" &&
        <React.Fragment>

          <CadreVeille renderVeille = {!this.props.blockShowNewAttribut && this.props.newAttributs !== undefined &&  this.props.newAttributs.includes("Composant") } >
            <Interligne styles="center" label="" value = {this.props.composant } />
          </CadreVeille>

          <Interligne styles="center" label="" value = {this.props.loinc } iconeType = "alert"  icone = {!this.getIsRestreint() || inRestreint.indexOf("En attente") !== -1  } tooltip={this.props.loinc+this.props.numero} waiting = {inRestreint.indexOf("En attente") !== -1 ? true : false }/>
          <hr></hr>
   
          <CadreVeille renderVeille = {!this.props.blockShowNewAttribut && this.props.newAttributs !== undefined &&  this.props.newAttributs.includes("Grandeur") } >
            <Interligne 
              styles="alignement" 
              label="Grandeur" 
              icone = {this.props.data['Grandeur'] !== undefined && this.props.data['Grandeur'].includes("Fraction") &&  this.props.data['Grandeur'].includes("(sans unité)")} 
              iconeType = "info" 
              tooltip = {this.props.tooltip+"_grandeur"}
              value = {this.props.data['Grandeur']}
              grandeurText = {"\"sans unité\" : rapport rendu en décimale et non en pourcentage" }
            />
          </CadreVeille>

          <CadreVeille renderVeille = {!this.props.blockShowNewAttribut && this.props.newAttributs !== undefined &&  this.props.newAttributs.includes("Milieu biologique") } >
            <Interligne styles="alignement" label="Milieu" value = {this.props.data['Milieu biologique']} />
          </CadreVeille>

          <CadreVeille renderVeille = {!this.props.blockShowNewAttribut && this.props.newAttributs !== undefined &&  this.props.newAttributs.includes("Temps") } >
            <Interligne styles="alignement" label="Temps" value = {this.props.data['Temps']} />
          </CadreVeille>

          <CadreVeille renderVeille = {!this.props.blockShowNewAttribut && this.props.newAttributs !== undefined &&  this.props.newAttributs.includes("Echelle") } >
            <Interligne styles="alignement" label="Échelle" value = {this.props.data["Echelle"]} iconeType = "info"  icone = {this.getInfoBulle()} tooltip={"echelle_"+this.props.loinc} />
          </CadreVeille>


          <CadreVeille renderVeille = {!this.props.blockShowNewAttribut && this.props.newAttributs !== undefined &&  this.props.newAttributs.includes("Technique") } >

            {tooltipTechnique.need && 
              <Interligne styles="alignement" label="Technique" value = {this.props.data['Technique']} iconeType = "info" trueIcon = {true}  tooltip = {tooltipTechnique.text}/>
            }
            {!tooltipTechnique.need  && 
              <Interligne styles="alignement" label="Technique" value = {this.props.data['Technique']===null ? "Non renseignée dans le LOINC" : this.props.data['Technique']} />
            }

          </CadreVeille>


          <Interligne styles="alignement" label="Type (international)" value = {this.getLOINCInternational(this.props.data["Type"])} />


          <CadreVeille renderVeille = {!this.props.blockShowNewAttribut && this.props.newAttributs !== undefined &&  this.props.newAttributs.includes("Circuit Bio") } >
            {this.props.basestatut!==undefined   &&
              <Interligne styles="alignement" label="Type (circuit bio.)" value = {inRestreint}  valueStyle = {this.getStyleRestreint(inRestreint)}/>
            }
          </CadreVeille>


            <p className = "interligne alignement" onClick={(e)=> this.collapse(e)}>Panel informations <span> {this.state.showRes ? <GrFormUp  /> : <GrFormDown  />}</span></p>
            <Collapse  isOpened={this.state.showInfo} >
              { this.props.baseinfos?.typebase!==undefined && this.props.baseinfos.typebase==="demandes" &&
              <span onClick = {(e)=> this.onPanelClick(e,isPanel==="Oui","panel")}>
                  <Interligne styles="alignement panel_decalage" label="Est un panel" value = {isPanel} valueStyle = {"panel_"+isPanel} />
              </span>
              }
              { this.props.baseinfos?.typebase!==undefined &&
                <span onClick = {(e)=> this.onPanelClick(e,findInPanel==="Oui","inpanel")}>
                  <Interligne styles="alignement panel_decalage" label="Appartient à un panel" value = {findInPanel} valueStyle = {"panel_"+findInPanel} />
                </span>
              }
            </Collapse>
  

          {  this.props.baseinfos?.typebase!==undefined && this.props.baseinfos.typebase==="demandes" &&
            <div data-tip data-for={this.props.tooltip}>
            <Interligne styles="center" label="" value ="NABM"  />
              <ReactTooltip id={this.props.tooltip} type="info" effect="float" >
                <div>
                {this.props.data["NABM"] !== undefined && this.props.data["NABM"]!==null && Object.values(this.props.data['NABM']).map( (vv,kk) => (
                  <Interligne styles="tooltip" label="" value = {vv["Libellé NABM"]+ " - " + vv["Code NABM"] }  />
                ))}

                {(this.props.data["NABM"]===undefined ||  this.props.data["NABM"]===null) &&
                  <Interligne styles="tooltip" label="" value = "N/A"  />
                }


                </div>
              </ReactTooltip>
            </div>
          }
        </React.Fragment>
        }

        {this.props.loinc==="Non transcodable" &&
          <div className="outloinc_card">
            <img class="croix" src={this.getImg()} alt="Croix" />
            <div className="nothing"></div>
            <Interligne styles="center nothing" label="" value={this.getTexte()} />
          </div>
        }


        {this.props.loinc==="Absent du LOINC"  &&
          <div className="outloinc_card outloinc_card_center">
            <div>
              <Interligne styles="center nothing" label="" value={this.getTexte()} />
            </div>
          </div>
        }

      </div>
    )
  }

}


export default LoincInfo;
