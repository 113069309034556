import React from "react";
import NavButton from "../next";
import Commentaire from '../commentaire/Commentaire.js';
import "./middlepage.css";


class MiddlePage extends React.Component {
  constructor(props){
    super(props);
    this.editComm = this.editComm.bind(this)
  }

  onCheck(value){
    this.props.checkButton(value)
  }

  onClick(name){
    this.props.onClick(name)
  }

  edit(){
    this.props.onEdit()
  }

  editComm(who,why,title,modif = false){
    this.props.onEdit(who,why,title,modif)
  }

  getStatut(){

    if (Object.entries(this.props.data["Propositions LOINC"]).length>1 && this.props.showConfirmation===false && this.props.data["oneclictag"]["btnstatut"]===false){
      return false
    } else {
      return true
    }
  }

  getStatutCard(){
      return   (this.props.data["oneclictag"]["btnstatut"]===true ) ? false : true

  }

  getReject(){
    let result=false
    if (Object.entries(this.props.data["Choix client"]).length===1 && this.props.data["Choix client"][0]["Code LOINC"]==="refusé"){
      result=true
    }

    return result
  }

  getTexte(){
    if (Object.entries(this.props.data["Propositions LOINC"]).length>1) {
    /*  if (this.props.data["transcodable"]!=="oui"){
        return "Accepter la proposition"
      } else {*/
        return "Confirmer mes choix"
    //  }
    } else {
      return "Accepter la proposition"
    }

  }

  getAuditColor(value){
    let color = ''
    if (value === this.props.data["difficulté"]){
      switch (value){
        case '1':
          color = 'auditGreen'
        break
        case '2':
          color = 'auditYellow'
        break
        case '3':
          color = 'auditOrange'
        break
        case '4':
          color = 'auditRed'
        break
        case '5':
          color = 'auditBlack'
        break
        default:
        break
      }
    }
    return color
  }

  getAnomalie(){
    return  (this.props.data.anomalie !== undefined && this.props.data.anomalie["state"] === true ) ? "anomalieColorOff anomalieColor" : "anomalieColorOff"
  }

  render(){
    const anomalieText = this.props.data.anomalie !== undefined && this.props.data.anomalie["state"] === true ? this.props.data.anomalie["comm"] : ""

    return (
      <div className="resize">
        <div className="container_final_middle">
          <div className="container_comm_btn">
            <div className="container_comm">
              <h1 className="title_client margin_title">Commentaires</h1>

              <div className="container_final_propositions">

                {this.props.data["veille"] && 
                  <Commentaire who={"commV"} isEditable={false} text={"Informations relatives à la mise à jour : "} data={this.props.data["commentaireveille"]} />                
                }

                {this.props.baseStatut==="audit" &&
                  <Commentaire who={"commAu"} isEditable={true} text={"Commentaire audit : "} data={this.props.data["commentaireaudit"]} onClick={this.editComm} deleteComm = {this.props.deleteComm}/>
                }
                {this.props.baseStatut==="validation expert" &&
                <div>
             
                  <Commentaire who={"commEx"} isEditable={true} text={"Commentaire expert : "} data={this.props.data["commentaireexpert"]} onClick={this.editComm} deleteComm = {this.props.deleteComm} />
                  <Commentaire who={"reco"} isEditable={true} text={"Recommandation expert : "} data={this.props.data["recommandation"]} onClick={this.editComm} deleteComm = {this.props.deleteComm} />
                  <Commentaire who={"commRe"} isEditable={false} text={"Explication rejet proposition : "} data={this.props.data["commentairereject"]} onClick={this.editComm} />
                  <Commentaire who={"commCl"} isEditable={false} text={"Commentaire client : "} data={this.props.data["commentaireclient"]} onClick={this.editComm} />
                  <Commentaire who={"commAu"} isEditable={false} text={"Commentaire audit : "} data={this.props.data["commentaireaudit"]} onClick={this.editComm} deleteComm = {this.props.deleteComm}/>

                </div>
                }

                {this.props.baseStatut==="validation client" &&
                  <div>
                    {this.props.data["commentaireexpert"]!=="" &&
                      <Commentaire who={"commEx"} isEditable={false} text={"Commentaire expert : "} data={this.props.data["commentaireexpert"]} onClick={this.editComm} />
                    }
                    {this.props.data["recommandation"]!=="" &&
                      <Commentaire who={"reco"} isEditable={false} text={"Recommandation expert : "} data={this.props.data["recommandation"]} onClick={this.editComm} />
                    }
                    <Commentaire who={"commCl"} isEditable={true} text={"Commentaire client : "} data={this.props.data["commentaireclient"]} onClick={this.editComm} deleteComm = {this.props.deleteComm} />
                    {this.getReject()===true &&
                      <Commentaire who={"commRe"} isEditable={true} text={"Explication rejet proposition : "} data={this.props.data["commentairereject"]} onClick={this.editComm} deleteComm = {this.props.deleteComm}/>
                    }

                    {this.props.data["commentaireconsensus"]!==undefined &&
                      <Commentaire who={"commConsensus"} isEditable={true} text={"Commentaire consensus : "} data={this.props.data["commentaireconsensus"]} onClick={this.editComm} deleteComm = {this.props.deleteComm}/>
                    }


                  </div>
                }
                </div>
                </div>

                {this.props.baseStatut==="audit" &&
                  <div className="audit_bloc">
                    <h1 className="title_client margin_title">Niveau de complexité</h1>
                    <div className="audit_containt">
                      <NavButton isDefine={true}  onClick={()=> this.onCheck("1")} text="1" styles={this.getAuditColor("1")} />
                      <NavButton isDefine={true}  onClick={()=> this.onCheck("2")} text="2" styles={this.getAuditColor("2")} />
                      <NavButton isDefine={true}  onClick={()=> this.onCheck("3")} text="3" styles={this.getAuditColor("3")} />
                      <NavButton isDefine={true}  onClick={()=> this.onCheck("4")} text="4" styles={this.getAuditColor("4")} />
                      <NavButton isDefine={true}  onClick={()=> this.onCheck("5")} text="5" styles={this.getAuditColor("5")} />
                    </div>
                  </div>
                }


                <div className="container_button_middle">




                  {this.props.baseStatut==="validation expert" &&
                    <div>
                      <NavButton isDefine={this.props.data["veille"] !== undefined}  onClick={()=>this.onClick("removeEntry")} text="Supprimer !" styles="auditRed"/> 
                      <NavButton isDefine={true}  tooltip="anomalie" tooltipText = {anomalieText} onClick={()=>this.onClick("anomalie")} text="Anomalie" styles={this.getAnomalie()}/> 
                      
                      <NavButton isDefine={true}  onClick={()=>this.onClick("valider")} text="Confirmer" styles="btn_margin"/>
                      <NavButton isDefine={true}  onClick={()=>this.onClick("passer")} text="Passer" styles="btn_margin"/>
                    </div>
                  }

                  {this.props.baseStatut==="validation client" && this.props.donottranscode===false &&
                    <div className="btn_actions">
                      <NavButton isDefine={this.getStatut() }  onClick={()=>this.onClick("valider")} text={this.getTexte()} styles="btn_margin" />
                      <NavButton isDefine={true}  onClick={()=>this.onClick("passer")} text="Passer" styles="btn_margin" />
                      <NavButton isDefine={this.getStatutCard()}  onClick={()=>this.onClick("refuser")} text="Refuser la proposition" styles="btn_margin" />
                    </div>
                  }

                  {this.props.baseStatut==="validation client" && this.props.donottranscode===true &&
                    <div className="btn_actions">
                      <NavButton isDefine={true}  onClick={()=>this.onClick("valider")} text="Confirmer" styles="btn_margin" />
                      <NavButton isDefine={true}  onClick={()=>this.onClick("passer")} text="Passer" styles="btn_margin" />
                    </div>
                  }

                </div>



              </div>

              {this.props.baseStatut==="audit" &&
                <div className="btn_validation">
                <NavButton isDefine={this.props.state.isChangNav}  onClick={()=>this.onClick("last")} text="Reprendre" />
                <NavButton isDefine={true}  onClick={()=>this.onClick("quit")} text="Quitter"/>
                <NavButton isDefine={true}  onClick={()=> this.onClick("passer")} text="Passer" styles="btn_margin"/>
                <NavButton isDefine={this.props.data["difficulté"] !== "" }  onClick={()=>this.onClick("valideraudit")} text="Confirmer" styles="btn_margin"/>
                </div>
              }
              {this.props.baseStatut!=="audit" &&
                <div className="btn_validation">
                <NavButton isDefine={this.props.state.isChangNav}  onClick={()=>this.onClick("last")} text="Reprendre" styles="validation_btn"/>
                <NavButton isDefine={true}  onClick={()=>this.onClick("acceuil")} text="Changer de dictionnaire" styles="changer_dico_btn"/>
                <NavButton isDefine={true}  onClick={()=>this.onClick("quit")} text="Quitter" styles="validation_btn"/>
                </div>
              }



          </div>

        </div>


    )


  }

}



export default MiddlePage;
