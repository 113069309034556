import React from "react";
import './duplicatevalidation.css'
import NavButton from "../next";
import Interligne from "../interligne/interligne.js"
import CheckAndTxt from "../CheckAndTxt/CheckAndTxt.js"

class DuplicateValidation extends React.Component  {

  constructor(props){
      super(props);
      this.state = {
        allcheck:true
      }
    }


    findId(){
      let result = null
      this.props.data.entry.forEach((item, i) => {
        if (this.props.data.id ===  item["id"])  result = i
      });
      return result
    }

render(){

  const texte = this.props.type==="duplication" ? this.props.data.nbrEntryFind+" entrées identiques ont été trouvées (voir ci-dessous), confirmer aussi pour ces entrées ?" : "Information concernant votre saisie :";
  const labelInfo = this.props.data.entrytype !== undefined && this.props.data.entrytype === "resultats" ? "Nom SGL - Nom SGL demande - Milieu SGL - Unité SGL - Echelle SGL"  : 
  "Nom SGL demande - Tube à prélever" 
    return (
      <div className="duplicationvalidation_main display-block" >
        <div className="duplicationvalidation_containier">
          <div className="duplicationvalidation_texte">
            <Interligne styles = "center"  label = "" value ={texte} />
              {this.props.type==="duplication" &&
                <React.Fragment>
                  <Interligne bold="" styles = "center"  label = "Code SGL" value = {labelInfo}  />
                  <CheckAndTxt
                    label =   {"Tout sélectionner ?"}
                    state = {this.props.allChecked }
                    position = {this.findId()}
                    onCheck = {this.props.allCheck}
                    disabled = {false}
                    styles = "allselect"
                  />
               </React.Fragment>
              }
              <div className="duplication_value">

            {this.props.type==="duplication" && this.props.data.entry.map( (val,key) => (
              <CheckAndTxt
                label =   {val["Code SA"]+" : "+val["Nom SA"]+" - "+val["Nom Analyse"]}
                state = {val["check"] }
                position = {key}
                onCheck = {this.props.onCheck}
                disabled = {this.props.data.id ===  val["id"] ? true : false}
              />

            ))}

            {this.props.type==="confirmation"  && this.props.data.infos.map( (val,key) => (
              <Interligne bold="" styles = "center"  label = {val["label"]} value ={val["value"]} />
            ))}

            {this.props.type==="notfind"  &&
              <Interligne bold="" styles = "center"  label = "" value ={this.props.data.texte} />
            }
            </div>
          </div>
          {this.props.type==="duplication" &&
            <div className="duplicatevalidation_btn">
              <NavButton isDefine={true}  text="Oui" onClick={() => this.props.onClick("entryYes")}/>
              <NavButton isDefine={true}  text="Non" onClick={() => this.props.onClick("entryNo")}/>
              <NavButton isDefine={true}  text="Annuler" onClick={() => this.props.onClick("entryCancel")}/>
              <NavButton isDefine={true}  text="Effacer (et valider)" onClick={() => this.props.onClick("entryReset")}/>
            </div>
          }

          {this.props.type==="confirmation" &&
            <div className="duplicatevalidation_btn">
              <NavButton isDefine={true}  text="Valider" onClick={() => this.props.onClick("confirmationYes")}/>
              <NavButton isDefine={true}  text="Retour" onClick={() => this.props.onClick("confirmationNo")}/>

            </div>
          }

          {this.props.type==="notfind" &&
            <div className="duplicatevalidation_btn">
              <NavButton isDefine={true}  text="Oui" onClick={() => this.props.onClick("notfindYes")}/>
              <NavButton isDefine={true}  text="Non" onClick={() => this.props.onClick("notfindNo")}/>


            </div>
          }

        </div>
      </div>
    )
}


}

export default DuplicateValidation;
