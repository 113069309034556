import React from 'react'


import SelectAndTxt from '../../SelectAndTxt/SelectAndTxt.js'
import DictionnaireTable from "../dictionnairetable/dictionnairetable.js"
import NavButton from "../../next.js"
import { Roller } from 'react-awesome-spinners'
import {Collapse} from 'react-collapse';
import { GrFormDown,GrFormUp } from 'react-icons/gr';
import Interligne from "../../interligne/interligne.js";


class JoinCol extends React.Component  {




  constructor(props){
    super(props);
    this.state = {
      selectChoice:{
        origin:[],
        jointure:[]
      },
      id:{
        origin: -1,
        jointure : -1
      },
      block:true,
      origin:"",
      jointure:"",
      programme:"",
      showBtn:false,
      type:"",
      showInfos:false,
      jointureTypeData : []
    }
    this.selectChange = this.selectChange.bind(this)
    this.onSelectOrigin = this.onSelectOrigin.bind(this)
    this.onSelectJointure = this.onSelectJointure.bind(this)
    this.selectProg = this.selectProg.bind(this)
  }

  selectChange(e,tag){
    let id = {...this.state.id}
    id[tag] = e.value
    const tmp = {...this.state.selectChoice}
    tmp[tag] = []

    this.setState({
      id:id,
      block:false,
      [tag]:e.value,
      selectChoice:tmp,
      showBtn:true,
      type: tag === "origin" ? e.type : this.state.type
    })
    this.props.readFile(e.value,tag)


  }

  validation(){

    if (this.props.fileInfoJointure.confirmFile) this.setState({block:true,showBtn:false})
    this.props.doJoin(this.state.origin,this.state.jointure,this.state.selectChoice,this.state.programme)
  }

  selectProg(e){
    this.setState({
      block:false,
      programme:e.value
    })
  }

  onSelectOrigin(event,key){
    let tmp = {...this.state.selectChoice}
    tmp.origin[key]=event.value
    this.setState({selectChoice:tmp})
  }

  onSelectJointure(event,key){
    let tmp = {...this.state.selectChoice}
    tmp.jointure[key]=event.value
    this.setState({selectChoice:tmp})
  }

    reset(){
      this.props.readFile(this.state.id.jointure,"jointure")
      this.props.readFile(this.state.id.origin,"origin")

    }

    getToCheck(type){
      if (type === "") return []

      return type === "resultats" ? [
        "Colonne 'Onaos_Libellé milieu SGL' renseignée ( = pré-traitement)",
        "Colonne 'Code chapitre SGL' (avec libellé) renseignée ?",
        "Colonne 'Code demande' renseignée ?",
        "Colonne 'Onaos_Libellé demande' renseignée ?",
        "Colonne 'Onaos_Tube à prélever' renseignée ?",
  
      ]: [
        "Colonne 'Onaos_Milieu SGL' renseignée ?",
       "Colonne 'Onaos_Libellé unité SGL' renseignée ?",
       "Colonne 'Code chapitre SGL' (avec libellé) renseignée ?",
       "Colonne 'Onaos_Libellé analyse' renseignée ?"
      ]
    }

  collapse(){
    this.setState({showInfos:!this.state.showInfos})
  }




  render(){

        const selectData = this.props.data.dico!==undefined ?  this.props.data.dico.map( (v,k)=>({
          label : v["nom"] + " (" + v["type"]+")",
          value : v["id"],
          type : v["type"] === "Résultat" ? "resultats" : "demandes"

        })) : {label:"",value:"",type:""}
/*
        let typeJoin = this.props.jointureTypeData != undefined ? 
        typeJoin.push({label:"Colonne identique",value:"identique"})
        typeJoin.push({label:"résultat[0-9] = demande",value:"result[]"})
        typeJoin.push({label:"demande = resultat[0-9]",value:"demande[]"})
        typeJoin.push({label:"demande w/ result assos",value:"demande w/res assoc"})
        typeJoin.push({label:"result w/ result assos",value:"resultat w/res assoc"})
        typeJoin.push({label:"demande w/ demande assos",value:"demande w/dem assoc"})
        typeJoin.push({label:"result w/ demande assos",value:"resultat w/dem assoc"})
        typeJoin.push({label:"demande w/ multi demande",value:"demande w/ multi demande"})
        typeJoin.push({label:"demande w/ demande assos + comma",value:"demande w/ dem assoc comma"})
*/

        const typeJoin = this.props.jointureTypeData.length>0 ? this.props.jointureTypeData : [{label:"", value:""}]

        const verif = this.getToCheck(this.state.type)

        return(
          <div className="container_modif">
            { this.props.fileRender &&
              <div className="container_render center">
                <p>Pre-Chargement en cours</p>
                <Roller color="#f9af2e" />
              </div>
            }


            

            <SelectAndTxt
              label = { {name : "Sélectionnez le dictionnaire à modifier" ,txt : ""} }
              data  = {selectData}
              selectChange = {this.selectChange}
              tag = "origin"
              selectData = {this.state.origin}
                index = {400}
            />
            
            {this.state.type !== ""  &&
              <p className = "sizing_entree alignement" onClick={()=> this.collapse()}>Information de jointure :<span> {this.state.showInfos ? <GrFormUp  /> : <GrFormDown  />}</span></p>
            }

            <Collapse  isOpened={this.state.showInfos} >
              <div className = "check_container">
                {this.state.type !== "chapitres" && verif.map((v,k)=> (
                  <Interligne styles = "alignement" bold="" label = "" value = {v} />
                ))}
              </div>
            </Collapse>

            {this.props.fileInfoJointure !== undefined && this.props.fileInfoJointure.showFileOrigine === true && !this.state.block && this.props.fileInfoJointure.origin.length>0 &&

                <DictionnaireTable
                  data = {this.props.fileInfoJointure.origin}
                  onSelect = {this.onSelectOrigin}
                  type = "Jointure_destination"
                  select = {this.state.selectChoice.origin}
                  confirmStatut = {this.props.fileInfoJointure.confirmFile}
                />


            }

            <SelectAndTxt
              label = { {name : "Sélectionnez la table de jointure" ,txt : ""} }
              data  = {selectData}
              selectChange = {this.selectChange}
              tag = "jointure"
              selectData = {this.state.jointure}
              index = {300}
            />


            {this.props.fileInfoJointure !== undefined &&  this.props.fileInfoJointure.showFileJointure === true && !this.state.block && this.props.fileInfoJointure.jointure.length>0 &&

                <DictionnaireTable
                  data = {this.props.fileInfoJointure.jointure}
                  onSelect = {this.onSelectJointure}
                  type = "Jointure_origine"
                  select = {this.state.selectChoice.jointure}
                  confirmStatut = {this.props.fileInfoJointure.confirmFile}
                />




            }


            <SelectAndTxt
              label = { {name : "Sélectionnez le type de jointure" ,txt : ""} }
              data  = {typeJoin}
              selectChange = {this.selectProg}
              tag = "programme"
              selectData = {this.state.programme}
              index = {200}
              width = {380}
            />

            <div className="dash_navigation">
              <NavButton isDefine={this.state.showBtn}  onClick={()=>this.validation()} text={this.props.fileInfoJointure.confirmFile ? "Confirmer" : "Valider"} styles="btn_modif_dico" />
              <NavButton isDefine={this.props.fileInfoJointure.confirmFile}  onClick={()=>this.reset()} text="Rétablir" styles="btn_modif_dico" />
            </div>

          </div>
        )
  }
}

export default JoinCol
