import './modal.css';
import React from 'react';
import CreatableSelect from 'react-select/creatable';



class Selectcomm extends  React.Component {

  constructor (props) {
		super(props);
    this.onKeyDown = this.onKeyDown.bind(this)
    this.selectState = React.createRef();
  }


  formatlabel = (inputValue) => {

    return this.props.type === "sil" ? "S.I.L. : "+inputValue : "Ajout de l'entrée "+inputValue ;
  };

  onKeyDown (event) {
    
    if (event.key === "Enter" && this.props.validation !== undefined){
      if (this.selectState.current !== undefined && !this.selectState.current.state.menuIsOpen) {
        this.props.validation()
        event.preventDefault();
        event.stopPropagation();
        

      } 
      this.selectState.current.focus()
    }
  }

  render (){
    const customStyles = {

      control:(styles) => ({ ...styles,
        // none of react-select's styles are passed to <Control />
        height:'40px'
      }),

    }

    const data = this.props.type === "commEx" ? this.props.expertallcomm : this.props.expertallreco
    const style = this.props.style !==undefined ? this.props.style : null
    const classname = this.props.classname !==undefined ? this.props.classname : "selectbox_comm"
    const placeholder = this.props.placeholder!==undefined ? this.props.placeholder : "Select"
    return(
      <div className={classname} style={style}>
        <CreatableSelect className="center"
          styles={customStyles}
          value =  {data.find(option => option.value === this.props.defaultValue)}
          options= {data}
          onChange = {this.props.onChange}
          onCreateOption={this.props.onCreateOption}
          placeholder={placeholder}
          formatCreateLabel={this.formatlabel}
          onKeyDown ={this.onKeyDown }
          ref = {this.selectState}
 
        />
      </div>

    )
  }

}


export default Selectcomm
