import React from 'react'
import './RadioQuestionnaire.css'




class RadioQuestionnaire extends React.Component{

    constructor(props) {
        super(props);

        this.onChangeValue = this.onChangeValue.bind(this);
      }
    
    
      onChangeValue(event) {
        const vals = event.target.value.split('|')
        this.props.onChangeValue(vals[0], vals[1])
      }

    render(){
        const rep = this.props.response["réponse"] !== undefined && this.props.valeur === "" ? this.props.response["réponse"] : this.props.valeur

        return(
            <div className='container_quesitonnaire_btn'>
                {this.props.textes.map((v,k)=>(
                    
                    <label className="label_radio_questionnaire"  >
                        <input 
                            className="radio_button_input"  
                            type="radio"  
                            checked = {v === rep} 
                            value={v+"|"+k} 
                            
                            name="questionnaire_radio" 
                            onChange={this.onChangeValue}  
                        />
                        <span className={this.props.error ? "radio_button_control error_questionaire": "radio_button_control"}>
                            {v}
                        </span>
                </label>  
                ))}
                     
            </div>
        )
    }

}


export default RadioQuestionnaire