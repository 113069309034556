/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import './Identification.css';
/*import Api from './api';*/
import LoginForm from "./stores/LoginForm";

class Identification extends React.Component{

    render(){
        return(
            <div className="Identification">
                <div className="login_page_leftside">
                    <img className="login_page_img" src="illustration_login_page.svg" alt="Illustration login page"/>
                </div>
                <div className="login_page_rightside">
                    <LoginForm afterlog={(data)=>this.props.afterlog(data)} />
                </div>
            </div>
        )
    }
}

export default Identification
