import React from 'react'
import Interligne from "../../interligne/interligne.js"
import Select from 'react-select'
import { GrEdit } from 'react-icons/gr';
import ReactTooltip from 'react-tooltip';
import NavButton from "../../next";



class ClientInfo extends React.Component  {
  constructor(props){
    super(props);
    this.state={
      baseSelected:null,
      changeStatut:false
    }
  }

  findBase(even) {
    this.setState({baseSelected:even.value})
    this.props.switchbase(even.value)
  }

  edit(){
    this.setState({changeStatut:true})
  }


  modifBase(even){
      this.props.modifBase(this.props.data.apiInfo.info.titrebase[this.state.baseSelected],even.value,this.state.baseSelected)
      this.setState({changeStatut:false})
  }

  catalogue(statutCatalogue) {
    if (statutCatalogue!==undefined && statutCatalogue!=="" ){
      const statut = ["validation expert","validation client"]
      const statutSelect = statut.map((v,k)=> ({
        label:v,
        value:v
      }))


      if (this.state.changeStatut===false) {
        return (
          <span>
            {statutCatalogue}
            {statutCatalogue !== "mapper" && statutCatalogue !== "pretraitement"  &&
              <GrEdit  className="icone_comm" onClick={()=>this.edit()} />
            }
          </span>
        )
      } else {
        return (
          <Select className="center"
          value = {statutSelect.find(option => option.value === statutCatalogue )}
            onChange={(e)=>this.modifBase(e)}
            options={statutSelect}
          />
        )
      }
    }
  }


  getLabelReview(collectionName, statut, position){
    let result = ""
    if (collectionName.indexOf("review_") === 0 || (this.props.data.apiInfo.info.isVeille !== undefined && this.props.data.apiInfo.info.isVeille[position])){
      if (statut === "validation client") result = "review_client_label"
      if (statut === "validation expert") result = "review_label"
    }
    return result
  }

  getReviewLabel(collectionName,statut, position){
    let result = ""

    if (collectionName.indexOf("review_") === 0 || (this.props.data.apiInfo.info.isVeille !== undefined && this.props.data.apiInfo.info.isVeille[position])){
      if (statut === "validation client") result = "(Client) - "
      if (statut === "validation expert") result = "(Review) - "
    }
    return result
  }

  listBaseText(){

    const base  = this.props.data.apiInfo.info.titrebase!== undefined && this.props.data.apiInfo.info.titrebase.length>0 ? this.props.data.apiInfo.info.titrebase.map( (v,k)=> ({
      label : (
        <div>
          <div className = {this.getLabelReview(v,this.props.data.apiInfo.info.statut[k], k) } data-tip data-for={this.props.data.apiInfo.info.chapitres[k] !== undefined && this.props.data.apiInfo.info.chapitres[k] !== ""  ? "chap_"+k : "none"}  >
            {  this.props.data.apiInfo.info.typebase[k] +  " - "+this.props.data.apiInfo.info.progression[k] +"% / "+ this.props.data.apiInfo.info.nombre[k].nbrcarte+ (this.props.data.apiInfo.info.needconsensus[k] ? " !":"") + " "+this.props.data.apiInfo.info.nomexpert[k].split("@")[0]} 
          </div>
          <ReactTooltip className='chapitre_tooltip' id = {"chap_"+k} type = "error" effect = "float" >{(this.getReviewLabel(v,this.props.data.apiInfo.info.statut[k], k))+this.props.data.apiInfo.info.nomexpert[k] +" - Chapitres : "+this.props.data.apiInfo.info.chapitres[k]}</ReactTooltip>
        </div>

      ),
      value : k,
      tag : v,
    })).filter(e => e.tag !=="") : []
    return base
  }



 

  render(){

    const listbase =  this.listBaseText()
    const typeCatalogue = (this.state.baseSelected===null) ? "" : this.props.data.apiInfo.info.typebase[this.state.baseSelected]
    const statutCatalogue = (this.state.baseSelected===null ) ? "" : this.props.data.apiInfo.info.statut[this.state.baseSelected]
    const subTypeCatalogue = (this.state.baseSelected===null) ? "" : this.props.data.apiInfo.info.soustype[this.state.baseSelected]
    const statutConsensus = (this.state.baseSelected===null) ? "" : this.props.data.apiInfo.info.isconsensus[this.state.baseSelected]
    const allChapitres = (this.state.baseSelected===null || this.props.data.apiInfo.info.chapitres[this.state.baseSelected] === undefined) ? "" : this.props.data.apiInfo.info.chapitres[this.state.baseSelected]
    const nbrCarte = (this.state.baseSelected===null) ? "" : this.props.data.apiInfo.info.nombre[this.state.baseSelected].nbrcarte
    const expertMail = (this.state.baseSelected===null) ? "" : this.props.data.apiInfo.info.nomexpert[this.state.baseSelected]
    const chapitres = allChapitres !== "" ? allChapitres.substring(0,30)+"; ..." : ""
    const isReview = this.state.baseSelected !== '' && this.props.data.apiInfo.info.titrebase[this.state.baseSelected] !== undefined &&  (this.props.data.apiInfo.info.titrebase[this.state.baseSelected].indexOf("review_") === 0 ||  this.props.data.apiInfo.info.isVeille[this.state.baseSelected] ) && statutCatalogue === "validation expert" && this.props.data.apiInfo.info.progression[this.state.baseSelected] === 100 ? true : false
    const titrebase =  (this.state.baseSelected===null) ? "" : this.props.data.apiInfo.info.titrebase[this.state.baseSelected]
    const needConsensus = (this.state.baseSelected===null) ? "" : (this.props.data.apiInfo.info.needconsensus[this.state.baseSelected] ? "oui" : "non")
    return (

        <div className = "dash_container_client_info">

          <div className="dash_client_info">
            <Interligne styles = "center"  label = "Informations" value ="" />
            <Interligne styles = "alignement"  label = "Client" value ={this.props.data.dashboardData.dashClient} />
            {expertMail !== "" && 
              <Interligne styles = "alignement"  label = "Expert" value ={expertMail} />
            }

            {listbase.length>0 &&  
              <React.Fragment>
                <Interligne styles = "alignement"  label = "Catalogues téléversés" value ={
                  <Select className="center"
                    value = {listbase.find(option => option.value === "" )}
                    onChange={(e)=>this.findBase(e)}
                    options={listbase}   
                  /> } 
                />
                <Interligne styles = "alignement"  label = "Nom de la collection" value ={titrebase} />

                <Interligne styles = "alignement"  label = "Type de catalogue" value ={typeCatalogue} />
                { subTypeCatalogue !== "" && subTypeCatalogue !== "Générique" &&
                  <Interligne styles = "alignement"  label = "Sous-type du catalogue" value ={subTypeCatalogue} />
                }
                {chapitres !== "" &&
                  <Interligne styles = "alignement"  label = "Chapitres" value ={chapitres} />
                }
                <Interligne styles = "alignement"  label = "Nombre d'entrée" value ={nbrCarte} />

                <Interligne styles = "alignement"  label = "Base en consensus" value ={statutConsensus} />
                <Interligne styles = "alignement"  label = "Besoin de consensus" value ={needConsensus} />

                {  this.props.expertAllowed!==undefined &&  !this.props.expertAllowed &&
                  <Interligne styles = "alignement"  label = "Statut du catalogue" value ={this.catalogue(statutCatalogue)} />
                }
              </React.Fragment>
            }

            <NavButton
              isDefine={this.state.baseSelected !== null && this.props.expertAllowed!==undefined &&  !this.props.expertAllowed}
              onClick={() =>
                this.props.doCheck(this.props.data.dashboardData.dashClient, this.props.data.apiInfo.info.titrebase[this.state.baseSelected])
              }
              text="Vérification"
              styles="auditRed dashbutton dashclientbutton"
            />

            <NavButton
              isDefine={isReview && this.props.expertAllowed!==undefined &&  !this.props.expertAllowed}
              onClick={() =>
                window.confirm("!!! Ce script va passer la base en mode 'validation client', remettre à 0 le compteur de transcodage et surtout envoyer un email au client. Confirmer ?") &&
                this.props.doEverything(this.props.data.apiInfo.info.titrebase[this.state.baseSelected],this.state.baseSelected)
              }
              text="Transcodage client"
              styles="auditRed dashbutton dashclientbutton"
            />



            <NavButton 
              isDefine = {this.props.expertAllowed!==undefined &&  !this.props.expertAllowed && statutCatalogue === "validation client" }
              onClick = {()=> 
                this.props.downloadJournal(this.props.data.apiInfo.info.titrebase[this.state.baseSelected])
              }
              text="Journal client"
              styles="dashbutton dashclientbutton"

            />
          </div>
        </div>

    )
  }
}


export default ClientInfo
