import React from "react";
import Select from 'react-select'
import './choix.css';
import {getAPI} from '../../api/api.js';
import NavButton from "../next"
import Logo from "../../img/logo_onaos.png";
import MenuClient from '../FloatingMenu/MenuClient.js'


class Choix extends React.Component  {
  constructor(props){
    super(props);
    this.expertChoice = this.expertChoice.bind(this)
  }

  expertChoice(selectedOption){
    getAPI("/dataexpert/"+selectedOption.value,this.props.alldata.tokenrefresh).then(data =>{
      if (data.status===undefined) {
        this.props.dataGet(data)
      }
    })
  }





  render(){
    const customStyles = {
      container: provided => ({
        ...provided,
        width: 450,
      })
    };
    let options = []
    if (this.props.alldata.total>0){
      options =  this.props.alldata.client.typebase.map((v,k) => ({
        label: v,
        value: this.props.alldata.client.titrebase[k]
      }));
    }

      return (
        <div>
          <div className="background" >
            <div className="base_type base_type_choice">
              <img className="logo_onaos_mapping" src={Logo} alt="Logo Onaos"/>
            </div>
            <div className="choixform">
                <div className="choixform_position">
                { this.props.alldata.total>0 &&
                  <div className="choixform_container">
                    <p className="choixform_disposition bold">Bienvenue sur votre interface de validation du transcodage de vos dictionnaires.</p>
                    <p className="choixform_disposition">Un ou plusieurs dictionnaires sont actuellement disponibles pour validation, merci de sélectionner ci-après le dictionnaires que vous souhaitez valider</p>
                    <p className="choixform_disposition"> Lorsque vous aurez terminé le processus de vérification du transcodage LOINC de ce même dictionnaire, vous pourrez télécharger le fichier du transcodage LOINC du dictionnaire sélectionné</p>
                    <div className="select_style">
                      <Select
                        styles={customStyles}
                        className="centerselect"
                        onChange={this.expertChoice}
                        options={options}
                      />
                    </div>
                  </div>
                }
                {this.props.alldata.total===0 && 
                  <div>
                  <p className="disposition bold">Vos dictionnaires sont actuellement en phase de transcodage LOINC par nos experts, un e-mail vous sera envoyé lorsqu'ils seront disponibles pour vérification.</p>
                  <p className="disposition">En attendant vous pouvez accéder, via le menu situé en bas à gauche de l'interface, à notre tutoriel ainsi qu'à un guide rapide d'utilisation et à un guide complet sur les bonnes pratiques du transcodage LOINC.</p>
                  </div>
                }
 
                <NavButton isDefine={true}  onClick={()=>this.props.disconnect()} text="Quitter" />
                 

                </div>
 
                {this.props.alldata.contact !== undefined && this.props.alldata.contact && this.props.alldata.total>0 &&
                  <div className="choixform_position" style = {{marginTop : "1%"}}>
                    <div className="choixform_container">
                        <p className="choixform_disposition">En tant que contact DSI de votre laboratoire, vous pouvez également accéder directement à la page d'export de l'ensemble des livrables de votre laboratoire :</p> 
                        <NavButton isDefine={true}  onClick={()=>this.props.goDSIPage()} text="Accès" />
                    </div>
                  </div>
                  
                  }

            </div>
            <MenuClient 
                tutoAction = {this.props.showTuto}
                guideAction = {this.props.downloadPDF}
                guidetranscodage = {this.props.downloadPDFGuide}
                bioloinc = {this.props.bioloinc}
                />
          </div>
        </div>
    )
  }
};

export default Choix;
