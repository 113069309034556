import React from 'react'
import './dictionnaires.css'
import NavButton from "../../next.js"
import DictionnaireTable from "../dictionnairetable/dictionnairetable.js"




class Dictionnaires extends React.Component  {
  constructor(props){
    super(props);
     this.state = {
       selectChoice:[]
     }
     this.onSelect=this.onSelect.bind(this)
  }

  onSelect(event,key){
    let tmp = {...this.state.selectChoice}
    tmp[key]=event.value
    this.setState({selectChoice:tmp})
  }

  validation(){
    this.props.onValid(this.state.selectChoice)
  }

  render(){
    const showHideClassName = this.props.show ? "dico_modal" : "dico_modal display-none"

    return(
      <div className={showHideClassName}>
        <DictionnaireTable
          data = {this.props.data}
          onSelect = {this.onSelect}
          type = "chapitre"
          select = {this.state.selectChoice}
          confirmStatut = {this.props.confirmFile}
        />
        <div className="dico_nav">
          <NavButton isDefine={true}  onClick={()=>this.validation()} text={this.props.confirmFile ? "Confirmer" : "Valider"} />
          <NavButton isDefine={true}  onClick={this.props.onQuit} text="Quitter" />
        </div>
      </div>
    )
  }

}


export default Dictionnaires
