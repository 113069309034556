import './interligne.css';
import React from "react";
import {GrAlert,GrCircleInformation} from 'react-icons/gr'
import ReactTooltip from 'react-tooltip';


class InterligneSpan extends React.Component {




  render(){

    const style = (this.props.styles!==undefined) ? "interligne "+this.props.styles : "interligne"
    const label = (this.props.label!=="") ? this.props.label + " : " : ""
    let characters = (this.props.bold!==undefined) ? this.props.bold:'characters'
    characters = (this.props.valueStyle!==undefined) ? characters + " "+this.props.valueStyle: characters

    const textOutLoinc = this.props.waiting ? "Code en cours de référencement <br />par Bioloinc.fr <br />dans la base de donnée <br />\"Circuit de la biologie\"" : "Le code n'est pas disponible <br />dans la base de donnée <br />\"Circuit de Biologie\", il ne peut <br />donc pas être utilisé.<br />Toutefois une demande <br />d'intégration du code<br /> dans le \"Circuit de la Biologie\"<br /> sera faite à l'ANS <br />par nos experts."
    let textEchelle = (this.props.value==="Résultat nominal") ? "Résultat sous forme de liste pré-définie" : "Résultat sous forme de texte libre"
    textEchelle  =  (this.props.value==="direct") ? this.props.valueInfo : textEchelle
    const text = (this.props.value === "direct") ? "" : this.props.value
    const styleIcon =  (this.props.value === "direct") ? "" : "icone_caution_map"
    textEchelle = this.props.grandeurText !== undefined ? this.props.grandeurText : textEchelle




    return (
      <React.Fragment>
      {(this.props.icone===undefined || this.props.icone===false) && this.props.trueIcon === undefined && 
        <span className={style}>{label}
        <span data-tip data-for ={this.props.tooltip !== undefined ? this.props.tooltip : ""} className={characters}>{this.props.value}
        {this.props.tooltip !== "" && this.props.tooltipText !== undefined &&
          < ReactTooltip id={this.props.tooltip}  type="info" effect="solid"  html={true}>
            {this.props.tooltipText}
          </ReactTooltip>
        }

        </span></span>
      }

      {this.props.icone!==undefined && this.props.icone===true && this.props.iconeType==="alert" && this.props.trueIcon === undefined &&
        <span className={style}>{label}
          <span className={characters}>{text}
          <GrAlert data-for={this.props.tooltip} data-tip className={styleIcon} /></span>
          {this.props.tooltip !== "" && 
            <ReactTooltip id={this.props.tooltip}  type="info" effect="solid" place="bottom" html={true}>
              {textOutLoinc}
            </ReactTooltip>
          }
        </span>
      }

      {this.props.icone!==undefined && this.props.icone===true && this.props.iconeType==="info" && this.props.trueIcon === undefined &&
        <span className={style}>{label}
          <span className={characters}>{text}
          <GrCircleInformation data-for={this.props.tooltip} data-tip className={styleIcon} /></span>
          <ReactTooltip id={this.props.tooltip} className="info_tooltip" type="info" effect="solid" place="bottom" html={true}>
            {textEchelle}
          </ReactTooltip>
        </span>
      }

      {this.props.iconeType!==undefined && this.props.trueIcon===true && this.props.iconeType==="info" &&
        <span className={style}>{label}
          <span className={characters}>{text}
          <GrCircleInformation data-for={this.props.tooltip} data-tip className={styleIcon} /></span>
          <ReactTooltip id={this.props.tooltip} className="info_tooltip" type="info" effect="solid" place="bottom" html={true}>
            {this.props.tooltip}
          </ReactTooltip>
        </span>
      }

      </React.Fragment>

    )
  }

}

export default InterligneSpan;
