import React from 'react'
import { Fab, Action } from 'react-tiny-fab';
import {GrAdd,GrHome ,GrHelp ,GrPlayFill,GrArticle, GrKeyboard , GrDocumentZip} from 'react-icons/gr'


class MenuClient extends React.Component{


  constructor(props){
    super(props);
    this.state = {
        isOpen: false,
        rerender : false
    }
    this.handleResize = this.handleResize.bind(this)
  }

  handleResize(){
    this.setState({rerender : true})
  }

  componentDidMount(){
    window.addEventListener('resize', this.handleResize)
  }

  getMediaType(){
    return window.matchMedia('(min-device-height: 1440px)').matches;
  }

  render(){
    const style = this.getMediaType() ?  {
      bottom : 90,
      left : 0,
    } : {
      bottom : 60,
      left : 0,
    }

    const actionButtonStyles = {
      backgroundColor: '#fff',
    }

    const mainButtonStyles = {
      backgroundColor: '#fff',
    }
    return(
          <Fab
            mainButtonStyles={mainButtonStyles}
            style={style}
            icon={<GrAdd />}
            alwaysShowTitle={false}
            event="hover"
          >
            {this.props.keyboard !== undefined && 
              <Action
                style={actionButtonStyles}
                text="Raccourcis clavier"
                children={<GrKeyboard />}
                onClick={()=>this.props.keyboard(true)}
              />
            }

            <Action
              style={actionButtonStyles}
              text="Télécharger les tables de BioLOINC"
              children={<GrDocumentZip />}
              onClick={this.props.bioloinc}
            />

            <Action
              style={actionButtonStyles}
              text="LOINC-Mapper Guide utilisateur"
              children={<GrHelp />}
              onClick={this.props.guideAction}
            />

            

            <Action
              style={actionButtonStyles}
              text="Tutoriel"
              children={<GrPlayFill />}
              onClick={this.props.tutoAction}
            />

            <Action
              style={actionButtonStyles}
              text="Transcodage LOINC : Règles de Bonnes Pratiques"
              children={<GrArticle />}
              onClick={this.props.guidetranscodage}
            />

            {this.props.homeAction !== undefined &&
            <Action
              style={actionButtonStyles}
              text="Changer de dictionnaire"
              children={<GrHome />}
              onClick={this.props.homeAction}
            />
            }
            

            

   

          </Fab>
    )
  }


}



export default MenuClient
