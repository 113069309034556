// import system
import React from 'react';
import './App.css';
import Identification from './identification';
import Mapping from "./mapping";
import Choix from "./stores/choix/Choix.js";
import Dashboard from "./stores/dashboard/main/dashboard.js";
import Account from "./stores/account/main/main.js"
import Password from "./stores/password/main.js"
import Recup from "./stores/recupemail/recupemail.js"
import AccountValidation from "./stores/AccountValidation/AccountValidation.js"
import Tuto from  "./stores/tuto/main/main.js"
import Maintenance from  "./stores/maintenance/maintenance.js"
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import {apiCPS,getAPI, putAPI} from './api/api.js';
import { DSIExports } from './stores/dsiexports/DSIExports';




class App extends React.Component {
  constructor(props){
      super(props);
      this.state = {
        log:false,
        alldata:[],
        olddata:[],
        oldApiInfo:[],
        olddashData:[],
        showDashboard:true,
        dashboardData:[],
        showAccount:false,
        account:"",
        tokenAccount:"",
        showAccountOk:false,
        showPassword:false,
        typePassword : "",
        showRecupEmail:false,
        hasError:false,
        tutoState : false,
        oldPrev:{
          api: [],
          dash: []
        },
        maintenanceMsg:"",
        dsiPage : false
      }
      this.isLog = this.isLog.bind(this);
      this.unlog = this.unlog.bind(this);
      this.showDashboard = this.showDashboard.bind(this);
      this.dataGet = this.dataGet.bind(this);
      this.validationAccount = this.validationAccount.bind(this);
      this.endTuto = this.endTuto.bind(this)
      this.showMenu = this.showMenu.bind(this)
      this.showTuto = this.showTuto.bind(this)
      this.downloadPDF = this.downloadPDF.bind(this)
      this.downloadPDFGuide = this.downloadPDFGuide.bind(this)
      this.downloadConsensual = this.downloadConsensual.bind(this)

      this.downloadClientExport = this.downloadClientExport.bind(this)
      this.downloadClientFullExport = this.downloadClientFullExport.bind(this)
      this.downloadUntreated = this.downloadUntreated.bind(this)
      this.downloadJournal = this.downloadJournal.bind(this)
      this.goDSIPage = this.goDSIPage.bind(this)
      this.gobackDSIPage = this.gobackDSIPage.bind(this)
      this.bioloinc = this.bioloinc.bind(this)

      
    }


    endTuto(){
      const url ="/firstconnexion"

      let data = {...this.state.alldata}
      data.premiereconnexion = false;
      this.setState({
        alldata : data,
        olddata : data
      })
      getAPI(url,this.state.alldata.tokenrefresh,null)
    }

 

    validationAccount(state){
      this.setState({
        showAccount:false,
        showAccountOk:state,
      })
    }

    componentDidUpdate() {
      document.body.classList.add('body_back');

    }

    downloadPDF(){
     // getAPI("/guide",this.state.alldata.tokenrefresh,'blob').then(data => {
        const urld = "/docs/guide.pdf"

      //  const urld = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a');
        link.href = urld;
        link.title = "Interface";
        link.setAttribute('download', "LOINC-Mapper - Guide d'utilisation (Onaos).pdf");
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(urld);

        //localStorage.setItem("uuidf1",urld.split('/')[3])

        //window.open("/guide")
  //    })
  
    }

    downloadPDFGuide(){

     /* getAPI("/guidetranscodage",this.state.alldata.tokenrefresh,'blob').then(data => {*/
        const urld = "/docs/support.pdf"
        //const urld = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a');
        link.href = urld;
        link.title = "Guide";
        link.setAttribute('download', "Transcodage LOINC - Guide de bonnes pratiques (Onaos).pdf");
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(urld);

        //localStorage.setItem("uuidf2",urld.split('/')[3])
        //window.open("/guidetranscodage")
    //  })
    }


    bioloinc(){
     getAPI("/bioloinc",this.state.alldata.tokenrefresh,'blob').then(data => {

        const urld = window.URL.createObjectURL(new Blob([data]))
        const link = document.createElement('a');
        link.href = urld;
        link.title = "BioLOINC";
        link.setAttribute('download', "Fichiers sources BioLOINC.zip");
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(urld);
     })
    }


    downloadConsensual(collection){
      const data = {
        collection : collection,
        action : "sendunconsensual"
      }
      putAPI("/expertexport",this.state.alldata.tokenrefresh,data,null)
    }

    downloadClientExport(collection){
      const data = {
        collection : collection,
        action : "clientwoconsensus",
      }
      putAPI("/expertexport",this.state.alldata.tokenrefresh,data,null)
    }


    downloadJournal(collection){
      const data = {
        collection : collection,
        action : "journal"

      }
      putAPI("/expertexport",this.state.alldata.tokenrefresh,data,null)
    }

    


    
    downloadClientFullExport(collection, addComm = false){
      const data = {
        collection : collection,
        action:"withbio",
        addcomm : addComm

      }
      putAPI("/expertexport",this.state.alldata.tokenrefresh,data,null)
    }

    downloadUntreated(collection, type){
      const action = type === "" ? "ExportExpertUnvalidate" : "ExportMapperUnvalidate"
      const data = {
        collection : collection,
        action: action,
        addcomm : false

      }
      putAPI("/expertexport",this.state.alldata.tokenrefresh,data,null)
    }


    componentDidMount(){
      const queryParams = new URLSearchParams(window.location.search);
      const code = queryParams.get('code');
      const state = queryParams.get('state');
      const labo = queryParams.get('labo');
      const expert = queryParams.get('expert');
      const recup = queryParams.get('recup');
      const dsi = queryParams.get('dsi');


      const maintenance = queryParams.get('maintenance');

      if (maintenance !== undefined && maintenance !== null){
        this.setState({
          maintenance:true,
        })
      }



      if (code!==undefined && code!==null && state===localStorage.getItem("state")  && localStorage.getItem('token')==="")
        {
        const datas = {
          secret : code,
          nonce : localStorage.getItem("nonce")
        }
        const url="/cpstoken"
        apiCPS(url,datas).then(data => {
          this.isLog(data)
        })
      }
      if (labo!==undefined && labo!==null && labo!==""){
        this.setState({
          showAccount:true,
          account : "labo",
          tokenAccount : labo
        })
      }

      if (expert !== undefined && expert !== null && expert !== ""){
        this.setState({
          showPassword:true,
          account : "expert",
          tokenAccount : expert
        })
      }
      if (recup!==undefined && recup === "email"){
        this.setState({
          showRecupEmail:true,
          account:"email",
          tokenAccount:recup
        })
      }
      if (recup!==undefined && recup !== null && recup !== "email"){
        this.setState({
          showPassword:true,
          account:"recup",
          tokenAccount:recup
        })
      }

      if (dsi!==undefined && dsi !== null && dsi !== "email"){
        this.setState({
          showPassword:true,
          account:"dsi",
          tokenAccount:dsi
        })
      }


    }

    isLog(data,user){
      if (data[0] !== undefined &&  data[0] === "maintenance") {
        this.setState({
          maintenance:true,
          maintenanceMsg:data[1]
        })
      } else {
        localStorage.setItem('token', data.token)
        this.setState({
          alldata:data,
          log:true,
          showDashboard:true,
          olddata : data,
          hasError:false
        })
      }
    }

    unlog(){
//      this.setState({log : false})
      localStorage.clear()
      window.location.reload()
    }


   dataGet(data, oldApiInfo = null, olddashData = null, oldPrev = null){
     localStorage.setItem('token', data.token)
     this.setState({
       alldata:data,
       showDashboard:false,
       oldApiInfo:oldApiInfo,
       olddashData:olddashData,
      oldPrev:oldPrev
     })
   }

   showDashboard(state){
     if (state===false){
       this.setState({
         showDashboard:state,
         dashboardData:[]
       })
     } else {
       if (this.state.alldata.access === "expert"){
         this.setState({showDashboard:state})
       } else {
         this.showMenu()
       }
    }
   }


   showMenu(){
     this.setState({alldata:this.state.olddata})
   }

   static getDerivedStateFromError(error) {
     localStorage.clear()
     window.alert("Une erreur inconnue s'est produite")
     window.location.reload()
     return {log:false}
   }

   showTuto(){

    if (!this.state.tutoState){
      const newVar = {...this.state.alldata }
      newVar.titrebase = undefined
      newVar.premiereconnexion = true
      newVar.total = 1
      this.setState({
        olddata:this.state.alldata,
        alldata:newVar,
        tutoState:true
      })

    } else {
      this.setState({
        alldata:this.state.olddata,
        tutoState:false
      })
    }
 
  }

  goDSIPage(){
    this.setState({dsiPage:true})
  }

  
  gobackDSIPage(){
    this.setState({dsiPage:false})
  }



  render(){
    return(
      <div className = "App">
<BrowserRouter>    
    <Routes>
      {/*
      <Route path="/guide" element={<Guide maxpage = {21} link = {"uuidf1"}  title = "Guide d'utilisation - Loinc Mapper" />} />
      <Route path="/guidetranscodage" element={<Guide maxpage = {70} link = {"uuidf2"}  title = "Guide du transcodage - Loinc Mapper"  />} />
      */
      }
      


      {this.state.maintenance && <Route  path="/" element={  <Maintenance msg = {this.state.maintenanceMsg} /> } /> } 



      {this.state.log===false  &&  this.state.showAccount && 
        <Route  path="/" element={ 
          <Account
            token = {this.state.tokenAccount}
            type = {this.state.account}
            validationAccount = {this.validationAccount}
          />
           } 
        />
      }

      {this.state.log===false  && !this.state.showAccount &&  this.state.showAccountOk && 
        <Route  path="/" element={ 
          <AccountValidation />
            } 
        />
      }    

      {this.state.log===false  && !this.state.showAccount &&  !this.state.showAccountOk && this.state.showPassword && 
        <Route  path="/" element={ 
          <Password
            afterlog={this.isLog}
            type = {this.state.account}
            token = {this.state.tokenAccount}
          />
            } 
        />
      }   

      {this.state.log===false  && !this.state.showAccount &&  !this.state.showAccountOk && this.state.showRecupEmail && 
        <Route  path="/" element={ 
          <Recup
            type = {this.state.account}
            token = {this.state.tokenAccount}
          />
            } 
        />
      }   


      {this.state.log===true && 
        this.state.alldata.access === "expert" && 
        this.state.showDashboard === true && 
        <Route  path="/" element={
          <Dashboard
            showDashboard={this.showDashboard}
            state={this.state}
            access="expert"
            dashboardData={this.state.dashboardData}
            baseAccess={this.dataGet}
            disconnect={this.unlog}
            oldApiInfo = {this.state.oldApiInfo}
            oldDashData  = {this.state.olddashData}
            oldPrev = {this.state.oldPrev}
            downloadConsensual = {this.downloadConsensual}
            downloadClientExport = {this.downloadClientExport}
            downloadClientFullExport = {this.downloadClientFullExport}
            downloadUntreated = {this.downloadUntreated}
            expertrestreint =  {this.state.alldata.expertrestreint}
            downloadJournal = {this.downloadJournal}

          />
          }
        /> 
      }

      {this.state.log===true && 
        this.state.alldata.access === "expert" && 
        this.state.showDashboard === false &&  !this.state.tutoState &&
        <Route  path="/" element={
          <Mapping 
            disconnect={this.unlog} 
            alldata={this.state.alldata} 
            showDashboard={this.showDashboard} 
            downloadPDF = {this.downloadPDF}
            downloadPDFGuide = {this.downloadPDFGuide}
            showTuto = {this.showTuto}
            bioloinc = {this.bioloinc}

          />
          }
        /> 
      }

      
      {this.state.log===true && 
        this.state.alldata.access === "user" && 
        this.state.alldata?.titrebase!==undefined && 
        <Route  path="/" element={
          <Mapping 
            disconnect={this.unlog} 
            alldata={this.state.alldata} 
            showDashboard={this.showDashboard} 
            showTuto = {this.showTuto}
            downloadPDF = {this.downloadPDF}
            downloadPDFGuide = {this.downloadPDFGuide}
            bioloinc = {this.bioloinc}

          />
          }
        /> 
      }

      {this.state.log===true && 
       
        this.state.alldata?.titrebase===undefined && 
        this.state.alldata.total !== 0 && 
        this.state.alldata.premiereconnexion !== undefined &&  
        this.state.alldata.premiereconnexion === true && 
        <Route  path="/" element={
          <Tuto  
            toEnd = {this.endTuto} 
            tutostate = {this.state.tutoState} 
            showTuto = {this.showTuto} />
          }
        /> 
      }

      {this.state.log===true && 
        this.state.alldata.access === "user" && 
        this.state.alldata?.titrebase===undefined && (this.state.alldata?.dsi === undefined || this.state.alldata?.dsi === false) && !this.state.dsiPage &&
        <Route  path="/" element={
          <Choix 
            alldata={this.state.alldata} 
            dataGet={this.dataGet} 
            disconnect={this.unlog} 
            downloadPDF = {this.downloadPDF}
            downloadPDFGuide = {this.downloadPDFGuide}
            showTuto = {this.showTuto}
            goDSIPage = {this.goDSIPage}
            gobackDSIPage = {this.gobackDSIPage}
            bioloinc = {this.bioloinc}

          />
          }
        /> 
      }

      {this.state.log===true && (
        (this.state.alldata.access === "user" && 
        this.state.alldata?.titrebase===undefined && this.state.alldata.dsi === true) || this.state.dsiPage) && 
        <Route  path="/" element={
          <DSIExports
            disconnect={this.unlog} 
            alldata={this.state.alldata}  
            gobackDSIPage = {this.gobackDSIPage}              
          />
          }
        /> 
      }

      

      {this.state.log===false  && 
        <Route  path="*" element={ <Identification afterlog={this.isLog}/> } />
      }

    </Routes>
  </BrowserRouter>
      </div>
    
  )


  }
}

export default App;
